import React from 'react';
import { Text } from '@react-pdf/renderer';

import Session from '../../components/Session';
import Section from '../../components/Section';
import SessionSummary from '../../components/SessionSummary';
import MultiselectDisplay from '../../components/MultiselectDisplay';
import LikertPairDisplay from '../../components/LikertPairDisplay';
import RelaxationHub from '../../components/RelaxationHub';
import SayItLoud from '../../components/SayItLoud';

import SESSION_FOUR_QUESTIONS from 'pages/SessionFour/data';

const SessionFour = ({ data }) => {
  const {
    healing,
    motivation,
    confidence,
    savings,
    useMoney,
    rewards,
    escapePlan,
    exercise
  } = data;
  return (
    <Session>
      <SessionSummary
        header="Session 4: Gearing Up for Quit Day"
        bullets={[
          'Understanding the physical, psychological and social benefits of quitting',
          'Dealing with urges and cravings',
          'Making a personal commitment to quitting'
        ]}
      />
      <Section header="Explore More">
        <Text style={{ fontWeight: 'bold' }}>Signs of Healing</Text>
        <Text>
          Here are some of the things I can do to get relief from my recovery
          symptoms:
        </Text>
        {SESSION_FOUR_QUESTIONS.healing.answers.map((question, i) => (
          <React.Fragment key={i}>
            <Text>{question.header}</Text>
            <MultiselectDisplay
              question={question}
              data={healing?.[question.refKey]}
            />
          </React.Fragment>
        ))}
      </Section>
      <Section>
        <Text style={{ fontWeight: 'bold' }}>Not Smoking Makes Cents</Text>
        {savings?.isCompleted && (
          <>
            <Text>Here's how much I will save from not using tobacco:</Text>
            <Text>{savings.value}</Text>
          </>
        )}
        {useMoney?.isCompleted && (
          <>
            <Text>Here's what I plan to do with the money that I'll save:</Text>
            <Text>{useMoney.value}</Text>
          </>
        )}
      </Section>
      <Section header="Committed to Quit">
        <LikertPairDisplay motivation={motivation} confidence={confidence} />
      </Section>
      <Section header="My Goals">
        {rewards?.isCompleted && (
          <>
            <Text>
              1. Quit Day is almost here. I plan to reward myself with:
            </Text>
            <Text>{rewards.value}</Text>
          </>
        )}
        {escapePlan?.isCompleted && (
          <>
            <Text>2. Here is my escape plan:</Text>
            <Text>{escapePlan.value}</Text>
          </>
        )}
        {exercise?.isCompleted && (
          <>
            <Text>
              3. I will do at least 15 minutes of phsyical activity each day
              this week (next 7 days) by doing this:
            </Text>
            <Text>{exercise.value}</Text>
          </>
        )}
      </Section>
      <Section header="My Reminders">
        <Text>Remember to track my tobacco use with Nic Check.</Text>
        <RelaxationHub
          bullets={[
            'My Signs of Healing',
            'Making Cents Cost Calculator',
            'My Quit Plan Checklist'
          ]}
        />
      </Section>
      <SayItLoud phrase="I am ready to quit tobacco!" />
    </Session>
  );
};

export default SessionFour;
