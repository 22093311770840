import styles from './VideoEmbed.module.css';

const VideoEmbed = ({ videoId, title }) => (
  <div className={styles['wrapper']}>
    <div className={styles['page-video']}>
      <iframe
        className={styles['page-video-embed']}
        src={`https://www.youtube.com/embed/${encodeURIComponent(
          videoId
        )}?autoplay=1&rel=0&modestbranding=1&playsinline=1`}
        frameBorder="0"
        allow="autoplay; encrypted-media;"
        title={title || 'Embedded YouTube Video'}
        allowFullScreen
      />
    </div>
  </div>
);

export default VideoEmbed;
