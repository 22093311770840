import Button from 'components/Button';

import styles from './TrueFalse.module.css';

const Statement = ({
  statement,
  disableOptions,
  explanation,
  chosenAnswer,
  correctAnswer,
  showExplanation,
  update,
  lastUnselected
}) => (
  <div className={styles['wrapper']}>
    <div className={styles['statement']}>{statement}</div>
    <div className={styles['options']}>
      <Button
        className={[
          chosenAnswer === 'true' ? styles['chosen'] : '',
          correctAnswer === 'true' && disableOptions ? styles['correct'] : '',
          styles['button'],
          styles['trueButton']
        ].join(' ')}
        disabled={disableOptions}
        onClick={() => (disableOptions ? null : update('true', lastUnselected))}
      >
        True
      </Button>
      <Button
        className={[
          chosenAnswer === 'false' ? styles['chosen'] : '',
          correctAnswer === 'false' && disableOptions ? styles['correct'] : '',
          styles['button'],
          styles['falseButton']
        ].join(' ')}
        disabled={disableOptions}
        onClick={() =>
          disableOptions ? null : update('false', lastUnselected)
        }
      >
        False
      </Button>
    </div>
    <div
      className={[
        showExplanation ? styles['shown'] : styles['hidden'],
        styles['explanation']
      ].join(' ')}
    >
      {showExplanation ? explanation : ''}
    </div>
  </div>
);

const TrueFalse = ({
  questionData: { statements, required = true },
  answerData,
  baseUpdate
}) => {
  const remainingUnselected = statements.filter(
    (statement) => !answerData[statement.key]
  ).length;
  return (
    <div>
      {statements.map(({ key, statement, correct, explanation }) => (
        <Statement
          key={key}
          statement={statement}
          disableOptions={!!(answerData && answerData[key])}
          chosenAnswer={answerData[key]}
          correctAnswer={correct}
          explanation={explanation}
          showExplanation={!!(answerData && answerData[key])}
          update={baseUpdate(`${key}`)}
          lastUnselected={required && remainingUnselected === 1}
        />
      ))}
    </div>
  );
};

export default TrueFalse;
