// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'sessionTwoInteractive';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  trueFalse: {
    questionType: 'trueFalse',
    question: undefined,
    statements: [
      {
        key: 'morningCravings',
        statement:
          'When a teen is addicted to nicotine, early morning cravings are strong.',
        correct: 'true',
        explanation: 'TRUE.'
      },
      {
        key: 'longerUse',
        statement:
          'The longer a teen uses tobacco, the harder it is to get addicted.',
        correct: 'false',
        explanation:
          'FALSE. The younger a person is when they start using, the stronger their nicotine dependence will be.'
      },
      {
        key: 'experiment',
        statement:
          'Teens can experiment with different types of tobacco and not worry about getting hooked.',
        correct: 'false',
        explanation:
          'FALSE. Many teens try different tobacco products, like cigarettes or electronic cigarettes, without planning to become regular users. Nicotine dependence sneaks up on teens.'
      },
      {
        key: 'withdrawalHours',
        statement:
          'Nicotine withdrawal sets in if a teen doesn’t use tobacco for a few hours.',
        correct: 'true',
        explanation: 'TRUE.'
      },
      {
        key: 'withdrawalEmotions',
        statement:
          'Withdrawal from nicotine can make teens feel emotional, like grumpy, anxious, sad.',
        correct: 'true',
        explanation: 'TRUE.'
      },
      {
        key: 'easyDelivery',
        statement:
          'Tobacco products, especially cigarettes and e-cigarettes, are designed to ensure easy nicotine delivery, assuring that users stay addicted.',
        correct: 'true',
        explanation: 'TRUE.'
      },
      {
        key: 'wantToQuit',
        statement: 'Most teens who smoke or vape don’t want to quit.',
        correct: 'false',
        explanation:
          'FALSE. Most teens who smoke say they want to quit, and that they would not start using tobacco if they could do it all over.'
      },
      {
        key: 'easierAddiction',
        statement:
          'Using nicotine during youth can rewire the brain in ways that make it easier to get addicted to other drugs.',
        correct: 'true',
        explanation: 'TRUE.'
      }
    ]
  },
  triggers: {
    questionType: 'multipleSelect',
    style: 'text',
    question: 'Select which of the following common triggers you relate to...',
    answers: [
      {
        label: 'Eating or trying not to eat',
        // tooltipText:
        //   'You want to use tobacco after you eat, or instead of eating to avoid gaining weight.',
        value: 'eating'
      },
      {
        label: 'Feeling sad or depressed',
        // tooltipText:
        //   'You use more when you are feeling tense, worried, depressed or frustrated, or to improve your mood.',
        value: 'sad'
      },
      {
        label: 'Feeling happy or excited',
        // tooltipText: 'Using tobacco makes your good mood even better.',
        value: 'happy'
      },
      {
        label: 'Feeling angry or stressed',
        // tooltipText:
        //   'You use tobacco more when you are angry or stressed to calm your nerves.',
        value: 'angry'
      },
      {
        label: 'Feeling bored',
        // tooltipText: 'Using tobacco gives you something to do.',
        value: 'bored'
      },
      {
        label: 'Hanging out with friends at parties and around new people',
        // tooltipText:
        //   'You use it in social settings to feel more comfortable and more self-confident.',
        value: 'friendsAndNewPeople'
      },
      {
        label: 'Drinking caffeinated beverages such as coffee or soda',
        // tooltipText:
        //   'You want tobacco more when you are drinking these beverages.',
        value: 'caffeinatedBeverages'
      },
      {
        label: 'See someone or smell someone using tobacco',
        // tooltipText:
        //   'You see a friend vaping or smell the vapor from an e-cigarette.',
        value: 'seeOrSmell'
      },
      {
        label: 'Studying with friends',
        // tooltipText: 'You vape when studying so you can concentrate better.',
        value: 'studying'
      },
      {
        label: 'Social media',
        // tooltipText:
        //   'You see a post on social media of someone smoking or vaping.',
        value: 'socialMedia'
      },
      {
        label: 'Other',
        // tooltipText: 'You have another trigger.',
        value: 'other'
      },
      {
        label: `I don't have any triggers.`,
        // tooltipText: `You don't have any triggers.`,
        value: 'none'
      }
    ]
  },
  motivation: {
    questionType: 'motivationLikert',
    question:
      'How would you rate your motivation to stop using tobacco products right now?'
  },
  confidence: {
    questionType: 'confidenceLikert',
    question:
      'How would you rate your confidence in stopping use of tobacco products right now?'
  },
  trigger: {
    questionType: 'shortText',
    question:
      'Review your tobacco use and tobacco triggers with your Nic-Check. Name one trigger that you will work on to resist in the upcoming week.'
  },
  physical: {
    questionType: 'shortText',
    question:
      'Do at least 10 minutes of physical activity each day this week (next 7 days). What type of physical activity will you try?'
  },
  quitDateConfirm: {
    questionType: 'dateSelect',
    minDate: () => new Date(),
    maxDate: () => new Date(Date.now() + 3 * 7 * 24 * 60 * 60 * 1000), // 3 weeks
    question:
      'It’s time to lock in your quit date. You’ll have two more sessions to complete before then, so choose a date in the next two to three weeks.  Mark it on your calendar.  It’s your big day!'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
