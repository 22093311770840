import { hashFromString } from 'utils/hash';

import high from 'assets/icons/high.png';
import medium from 'assets/icons/medium.png';
import none from 'assets/icons/none.png';
import sad from 'assets/icons/sad.png';
import very from 'assets/icons/very.png';

import styles from './Likert.module.css';

const ICONS = [none, sad, medium, high, very];

const DEFAULT_MOTIVATION = [
  {
    label: 'No motivation',
    value: 'no'
  },
  {
    label: 'Low motivation',
    value: 'low'
  },
  {
    label: 'Medium motivation',
    value: 'medium'
  },
  {
    label: 'High motivation',
    value: 'high'
  },
  {
    label: 'Very high motivation',
    value: 'veryHigh'
  }
];

const DEFAULT_CONFIDENCE = [
  {
    label: 'No confidence',
    value: 'no'
  },
  {
    label: 'Low confidence',
    value: 'low'
  },
  {
    label: 'Medium confidence',
    value: 'medium'
  },
  {
    label: 'High confidence',
    value: 'high'
  },
  {
    label: 'Very high confidence',
    value: 'veryHigh'
  }
];

const Likert = ({
  questionData: { question, answers },
  answerData: { value: selectedValue },
  baseUpdate
}) => {
  const update = baseUpdate(`value`);
  const selectedIndex = answers.findIndex(
    (answer) => answer.value === selectedValue
  );

  return (
    <>
      <div className={styles['sliding-scale-prompt']}>{question}</div>

      <div className={styles['sliding-scale-wrapper']}>
        {answers.map(({ value, label }, i) => (
          <div key={`input-${value}`} className={styles['sliding-scale-item']}>
            <input
              className={styles['sliding-scale-question']}
              type="radio"
              name={`sliding-scale-${hashFromString(question + label)}`}
              value={`${value}`}
              id={`sliding-scale-${value + 1}-${hashFromString(
                question + label
              )}`}
              onChange={() => update(value, true)}
              checked={selectedValue === value}
            />
            <label
              className={styles['sliding-scale-label']}
              htmlFor={`sliding-scale-${value + 1}-${hashFromString(
                question + label
              )}`}
            >
              <div className={styles['sliding-scale-image']}>
                <img src={ICONS[i]} alt={`Face expressing ${label}`} />
              </div>
              <div className={styles['sliding-scale-text']}>{label}</div>
            </label>
          </div>
        ))}
        <div
          className={[
            styles['sliding-scale-unified-label'],
            selectedValue
              ? styles[`sliding-scale-unified-label-${selectedIndex}`]
              : styles['sliding-scale-unified-label-unselected']
          ].join(' ')}
        >
          {selectedValue
            ? answers.find((answer) => answer.value === selectedValue).label
            : 'Please select a response.'}
        </div>
      </div>
    </>
  );
};

const MotivationLikert = ({
  questionData: { question },
  answerData,
  baseUpdate
}) => (
  <Likert
    questionData={{ question, answers: DEFAULT_MOTIVATION }}
    answerData={answerData}
    baseUpdate={baseUpdate}
  />
);

const ConfidenceLikert = ({
  questionData: { question },
  answerData,
  baseUpdate
}) => (
  <Likert
    questionData={{ question, answers: DEFAULT_CONFIDENCE }}
    answerData={answerData}
    baseUpdate={baseUpdate}
  />
);

export {
  Likert as default,
  MotivationLikert,
  DEFAULT_MOTIVATION,
  ConfidenceLikert,
  DEFAULT_CONFIDENCE
};
