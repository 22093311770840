// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'sessionSixInteractive';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  reasonForQuitting: {
    questionType: 'shortText',
    question: 'Write down your reasons for quitting today:'
  },
  tellYourselfToRemind: {
    questionType: 'shortText',
    question: 'What can you tell yourself to remind you why you want to quit?'
  },
  friendOffersTobacco: {
    questionType: 'communication',
    question:
      'You’re hanging out with some friends, and someone offers you tobacco. How will you respond?'
  },
  someoneVapesInCar: {
    required: false,
    questionType: 'communication',
    question:
      'You are in a car with someone who starts to vape. How will you respond?'
  },
  motivation: {
    questionType: 'motivationLikert',
    question:
      'How would you rate your motivation to stop using tobacco products right now?'
  },
  confidence: {
    questionType: 'confidenceLikert',
    question:
      'How would you rate your confidence in stopping use of tobacco products right now?'
  },
  tellPeople: {
    questionType: 'shortText',
    question:
      'One goal this week is to tell at least 2 people how you are feeling about your quit journey. Name one feeling you have about your quit journey right now?'
  },
  temptingSituation: {
    questionType: 'shortText',
    question:
      'This week,  make a “Top 10” list of ways to refuse the temptation to smoke, chew, vape, or use other tobacco products.  What is one of your most tempting situations?'
  },
  barrierToExercise: {
    questionType: 'shortText',
    question:
      'One of your goals is to do at least 20 minutes of exercise or physical activity 4 out of 7 days this week.  What is one of your biggest barriers to  exercise right now?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
