import { View, Text } from '@react-pdf/renderer';

const Replacement = ({ answer }) => (
  <>
    {answer?.isCompleted && (
      <View style={{ marginTop: '5px', marginBottom: '5px' }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ fontWeight: 'bold' }}>Instead of:</Text>
          <Text style={{ marginLeft: '3px' }}>{answer.original}</Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ fontWeight: 'bold' }}>I can:</Text>
          <Text style={{ marginLeft: '3px' }}>{answer.replacement}</Text>
        </View>
      </View>
    )}
  </>
);

export default Replacement;
