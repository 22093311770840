import { Text } from '@react-pdf/renderer';

import Session from '../../components/Session';
import Section from '../../components/Section';
import SessionSummary from '../../components/SessionSummary';
import LikertPairDisplay from '../../components/LikertPairDisplay';
import SayItLoud from '../../components/SayItLoud';

// import SESSION_SEVEN_QUESTIONS, {
//   REF_KEY_BASE as SESSION_SEVEN_REF_KEY
// } from 'pages/SessionSeven/data';

const SessionSeven = ({ data }) => {
  const {
    digitalAdvocateText,
    digitalAdvocateWhere,
    voiceImpact,
    motivation,
    confidence,
    goalNice,
    goalTellAboutJourney,
    goalTellThemWhat,
    goalTellNumberOfDays,
    goalExercise
  } = data;
  return (
    <Session>
      <SessionSummary
        header="Session 7: Helping Others Along the Way"
        bullets={[
          'Understanding how tobacco and e-cigarette advertising affects teens’ thoughts, feelings, and behaviors',
          'Finding out how to be a change force to reduce tobacco use in your school or community'
        ]}
      />
      <Section header="Explore More">
        {digitalAdvocateText?.isCompleted && digitalAdvocateWhere?.isCompleted && (
          <>
            <Text style={{ fontWeight: 'bold' }}>Becoming an Advocate</Text>
            <Text>My practice post is:</Text>
            <Text>{digitalAdvocateText.value}</Text>
            <Text>I plan to post it on:</Text>
            <Text>{digitalAdvocateWhere.value}</Text>
          </>
        )}
        {voiceImpact?.isCompleted && (
          <>
            <Text>
              I plan to participate in the following activity to make better
              decisions about tobacco use:
            </Text>
            <Text>{voiceImpact.value}</Text>
          </>
        )}
      </Section>
      <Section header="Committed to Quit">
        <LikertPairDisplay motivation={motivation} confidence={confidence} />
      </Section>
      <Section header="My Goals">
        {goalNice?.isCompleted && (
          <>
            <Text>Here's the nice thing I plan to do during the week:</Text>
            <Text>{goalNice.value}</Text>
          </>
        )}
        {goalTellAboutJourney?.isCompleted && (
          <>
            <Text>Here's who I will tell about my quit journey:</Text>
            <Text>{goalTellAboutJourney.value}</Text>
          </>
        )}
        {goalTellThemWhat?.isCompleted && (
          <>
            <Text>Here's what I'll tell them:</Text>
            <Text>{goalTellThemWhat.value}</Text>
          </>
        )}
        {goalTellNumberOfDays?.isCompleted && (
          <>
            <Text>
              Here's how many days it had been since I'd quit using tobacco:
            </Text>
            <Text>{goalTellNumberOfDays.value}</Text>
          </>
        )}
        {goalExercise?.isCompleted && (
          <>
            <Text>
              Here's the exercise I'll do for at least 20 minutes per day for 4
              of the next 7 days:
            </Text>
            <Text>{goalExercise.value}</Text>
          </>
        )}
      </Section>
      <Section header="My Reminders">
        <Text>
          Remember to track my tobacco use with Nic Check if I slip. Check out
          the Relaxation Center and additional resources on the Info Hub.
        </Text>
      </Section>
      <SayItLoud phrase="I do my part to create a healthier world." />
    </Session>
  );
};

export default SessionSeven;
