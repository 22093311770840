// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'sessionThreeInteractive';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  impactConcerns: {
    questionType: 'multipleSelect',
    question: undefined,
    answers: [
      {
        label: 'Breathing problems',
        value: 'breathingProblems'
      },
      {
        label: 'Increased coughing',
        value: 'increasedCoughing'
      },
      {
        label: 'Feel addicted to nicotine',
        value: 'feelAddicted'
      },
      {
        label: 'Decrease in smell and taste',
        value: 'decreasedSmell'
      },
      {
        label: 'Heart racing/jittery',
        value: 'heartRacing'
      },
      {
        label: 'Bad breath/tooth decay',
        value: 'badBreath'
      },
      {
        label: 'Smelly clothes and hair',
        value: 'smellyClothes'
      },
      {
        label: 'Feel tired or have difficulty focusing',
        value: 'feelTired'
      },
      {
        label: 'Skin changes',
        value: 'skinChanges'
      },
      {
        label: 'Change in sport performance',
        value: 'sportPerformance'
      },
      {
        label: 'Concerns about getting caught/anxiety',
        value: 'anxiety'
      },
      {
        label: 'Headaches',
        value: 'headaches'
      }
    ]
  },
  replacement1: {
    questionType: 'replacement',
    question: undefined
  },
  replacement2: {
    questionType: 'replacement',
    question: undefined,
    required: false
  },
  replacement3: {
    questionType: 'replacement',
    question: undefined,
    required: false
  },
  replacement4: {
    questionType: 'replacement',
    question: undefined,
    required: false
  },
  simpleItems: {
    questionType: 'multipleSelect',
    question: undefined,
    // question: 'A few simple actions can make quitting easier! Check a few you might add to your quit plan.',
    answers: [
      {
        label: 'Make your bedroom and/or personal spaces a “tobacco-free” area',
        value: 'tobaccoFree'
      },
      {
        label:
          'Rearrange your room or other places where you like to use tobacco at home',
        value: 'rearrangeRoom'
      },
      {
        label: 'Clean out your car',
        value: 'cleanCar'
      },
      {
        label:
          'Get rid of your tobacco paraphernalia, lighters and ashtrays, vape pens, pods, and cartridges',
        value: 'getRidOfParaphernalia'
      },
      {
        label: 'Get rid of or donate all tobacco-related clothing and gadgets',
        value: 'donateClothing'
      },
      {
        label:
          'Find some things you know will keep your hands busy ~ games or phone apps, stress balls, or other things that can replace holding a tobacco product',
        value: 'keepHandsBusy'
      }
    ]
  },
  motivation: {
    questionType: 'motivationLikert',
    question:
      'How would you rate your motivation to stop using tobacco products right now?'
  },
  confidence: {
    questionType: 'confidenceLikert',
    question:
      'How would you rate your confidence in stopping use of tobacco products right now?'
  },
  supportPerson: {
    questionType: 'shortText',
    question:
      'Tell someone how they can help support you in quitting. Who will you tell?'
  },
  supportTellThem: {
    questionType: 'shortText',
    question: 'What will you tell them?'
  },
  remove: {
    questionType: 'shortText',
    question:
      'Get rid of things in your space that tempt you to use tobacco. What 2 things will you remove or get rid of this week to make quitting easier?'
  },
  physical: {
    questionType: 'shortText',
    question:
      'Exercise at least 10 minutes each day this week (next 7 days). What physical activity will you do this week?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
