import Layout, { NextPageButton } from 'components/Layout';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Section from 'components/Section';

import IntroBackground from 'components/Layout/components/IntroBackground';
import Button from 'components/Button';
import CertificatePDF from './components/CertificatePDF';
import { useAuthenticationData } from 'components/AuthenticationContext';

const CompletionCertificate = () => {
  const { data } = useAuthenticationData();

  const savePDF = async (name) => {
    const blob = await pdf(<CertificatePDF name={name} />).toBlob();
    saveAs(blob, 'Certificate of Completion.pdf');
  };

  return (
    <Layout
      nextPageButton={
        <NextPageButton url={`/landing`} text={`Return to Journey Map`} />
      }
      title="Certificate of Completion"
      subTitle="Congratulations!"
      Background={IntroBackground}
      showChildrenBackground={false}
    >
      <Section>
        <p>Congratulations, you have completed the NOT for Me program!</p>
        <p>
          Please press the button below to download your certificate of
          completion.
        </p>
        <Button onClick={() => savePDF(data?.userProfile?.firstName?.value)}>
          Download Certificate
        </Button>
      </Section>
    </Layout>
  );
};

export default CompletionCertificate;
