import Layout, { NextPageButton } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';

const Video = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-two-interactive" />}
    title="Session 2: Seeing a Tobacco-free View"
    subTitle="Video"
  >
    <VideoEmbed videoId="JzhrlI2L_NE" title="Session Two Video" />
  </Layout>
);

export default Video;
