import { Text } from '@react-pdf/renderer';

const colors = {
  primary: '#0064ff',
  secondary: '#002158'
};

const Header = ({ children }) => (
  <Text
    style={{
      fontFamily: 'Cabin',
      fontSize: '16px',
      fontWeight: 'bold',
      color: colors.primary,
      // marginBottom: '5px',
      fontStyle: 'italic'
    }}
  >
    {children}
  </Text>
);

export default Header;
