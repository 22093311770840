import Section from 'components/Section';
import Layout, { NextPageButton } from 'components/Layout';
import InfoHubLink from 'components/InfoHubLink';

import JournalLink from 'components/JournalLink';

import { isSectionAnswered } from 'utils/progressCalculator';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const Interactive = () => {
  const { data } = useAuthenticationData();
  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          text="Submit Session"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Session 7: Helping Others Along the Way"
      subTitle="Explore More"
      Background={null}
    >
      <Section
        title="Be a Digital Advocate"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/digitalAdvocateText`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/digitalAdvocateWhere`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/voiceImpact`, data)
        }
      >
        <p>
          Are you active on Facebook, Twitter, Instagram or other social media?
          To help raise awareness and help others, share your quit journey on
          social media, or tell others about the Big Tobacco manipulation, or
          the dangers of second-hand smoke. Be sure to posts pics with hashtags
          such as: #LUNGFORCE #CleanAir4Kids #MakeTobaccoHistory #lunghealth
          #quitsmoking
        </p>
        <AbstractQuestion refKey={'digitalAdvocateText'} />
        <AbstractQuestion refKey={'digitalAdvocateWhere'} />
        <AbstractQuestion refKey={'voiceImpact'} />
      </Section>

      <Section title="Reflect/Relax">
        <p>
          Remember, relaxation is especially important as your body is going
          through the quitting journey. You can return to the{' '}
          <InfoHubLink section="relaxation">Relaxation hub</InfoHubLink>,
          anytime to practice these exercises.
        </p>
      </Section>

      <Section
        title="Committed to Quit"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/motivation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/confidence`, data)
        }
      >
        <p>
          Mapping readiness and commitment to quit is proven to help teens quit
          tobacco use. Let’s take a closer look at where you are right now. Your
          answers will be saved in your <JournalLink /> to help you track your
          progress.
        </p>
        <AbstractQuestion refKey={'motivation'} />
        <AbstractQuestion refKey={'confidence'} />
      </Section>

      <Section
        title="Goal Setting"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/goalNice`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalTellAboutJourney`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalTellThemWhat`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalTellNumberOfDays`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalExercise`, data)
        }
      >
        <AbstractQuestion refKey={'goalNice'} />
        <AbstractQuestion refKey={'goalTellAboutJourney'} />
        <AbstractQuestion refKey={'goalTellThemWhat'} />
        <AbstractQuestion refKey={'goalTellNumberOfDays'} />
        <AbstractQuestion refKey={'goalExercise'} />
      </Section>

      <Section title="Info Hub">
        <p>
          There are no resources in the Info Hub for Session 7. Feel free to
          review previous session documents, however!
        </p>
      </Section>

      <Section title="Journey Journal">
        <p>
          <b>You have completed this session,</b> come back next week for your
          next session. You’ll receive a reminder when it’s time to log back in.
        </p>
        <p>
          All of your responses from this session will be saved in your Journey
          Journal. <JournalLink>Click here to review your Journal.</JournalLink>
        </p>
      </Section>
    </Layout>
  );
};

export { Interactive as default, INTERACTIVE_QUESTIONS };
