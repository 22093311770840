import { Path, Svg } from '@react-pdf/renderer';

const colors = {
  primary: '#0064ff',
  secondary: '#002158'
};

const CheckboxEmpty = () => (
  <Svg
    style={{ marginTop: '4px' }}
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill={colors.secondary}
  >
    <Path d="M18 0H4C1.8 0 0 1.8 0 4v14c0 2.2 1.8 4 4 4h14c2.2 0 4-1.8 4-4V4C22 1.8 20.2 0 18 0zM20 18c0 1.1-0.9 2-2 2H4c-1.1 0-2-0.9-2-2V4c0-1.1 0.9-2 2-2h14c1.1 0 2 0.9 2 2V18z" />
  </Svg>
);

const CheckboxChecked = () => (
  <Svg
    style={{ marginTop: '4px' }}
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill={colors.secondary}
  >
    <Path d="M23.4 0.6c-0.8-0.8-2-0.8-2.8 0L11 10.2 7.4 6.6c-0.8-0.8-2-0.8-2.8 0 -0.8 0.8-0.8 2 0 2.8l5 5C10 14.8 10.5 15 11 15s1-0.2 1.4-0.6l11-11C24.2 2.6 24.2 1.4 23.4 0.6z" />
    <Path d="M21 10c-0.6 0-1 0.4-1 1v7c0 1.1-0.9 2-2 2H4c-1.1 0-2-0.9-2-2V4c0-1.1 0.9-2 2-2h11c0.6 0 1-0.4 1-1s-0.4-1-1-1H4C1.8 0 0 1.8 0 4v14c0 2.2 1.8 4 4 4h14c2.2 0 4-1.8 4-4v-7C22 10.4 21.6 10 21 10z" />
  </Svg>
);

const Checkbox = ({ checked }) =>
  checked ? <CheckboxChecked /> : <CheckboxEmpty />;

export default Checkbox;
