
// Builds a hash code from a string. Adapted from https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
const hashFromString = (str) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) - hash) + str.charCodeAt(i);
    hash |= 0;
  }
  return hash;
};

export { hashFromString };