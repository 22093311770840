import { useEffect, useState } from 'react';

import { useAuthenticationData } from 'components/AuthenticationContext';
import styles from './Map.module.css';

import Flag from './components/Flag';

import PathSegment from './components/PathSegment';

// TODO - put all map objects into a single array with a layer value so that it can be iterated for rendering
import { ReactComponent as Layer1 } from 'assets/images/map/layers/map_layer_1_optimized.svg';
// import { ReactComponent as Layer2 } from 'assets/images/map/layers/map_layer_2_optimized.svg';
import { ReactComponent as Layer3 } from 'assets/images/map/layers/map_layer_3_optimized.svg';
// import { ReactComponent as Layer4 } from 'assets/images/map/layers/map_layer_4_optimized.svg';
import { ReactComponent as Layer5 } from 'assets/images/map/layers/map_layer_5_optimized.svg';

import { ReactComponent as Overlay } from 'assets/images/map/map_overlay.svg';

import SESSION_DATA from 'utils/sessionData';

import helpOverlayImg from 'assets/icons/question_overlay.png';

import {
  getSessionUnlockProgress,
  getCurrentUserSession
} from 'utils/progressCalculator';

const flagData = SESSION_DATA.filter((session) => !!session.map?.flag);
const segmentData = SESSION_DATA.filter((session) => !!session.map?.segment);

const CurrentSegment = ({ Segment, length, data, sessionKey }) => {
  const [sessionUnlockProgress, setSessionUnlockProgress] = useState(
    getSessionUnlockProgress(data, sessionKey, Date.now())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimestamp = Date.now();
      setSessionUnlockProgress(
        getSessionUnlockProgress(data, sessionKey, currentTimestamp)
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <PathSegment
      segment={Segment}
      length={length}
      percentageComplete={sessionUnlockProgress}
    />
  );
};

const CurrentFlag = ({ text, x, y, url, data, sessionKey }) => {
  const [raised, setRaised] = useState(
    getSessionUnlockProgress(data, sessionKey, Date.now()) < 1 ? false : true
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRaised(
        getSessionUnlockProgress(data, sessionKey, Date.now()) < 1
          ? false
          : true
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return <Flag raised={raised} text={text} x={x} y={y} url={url} />;
};

const Map = ({ showMapHelp, setShowMapHelp }) => {
  const { data } = useAuthenticationData();
  const [showOverlay, setShowOverlay] = useState(showMapHelp);
  const currentTimestamp = Date.now();

  const { key: currentSessionKey } = getCurrentUserSession(data) || {};

  return (
    <div className={styles['map-container']}>
      <div className={styles['background']} />

      <div className={styles['aspect-ratio']}>
        <div className={styles['content']}>
          <Layer5 className={styles['layer']} />
          {/* <Layer4 className={styles['layer']} /> */}
          {segmentData
            .filter((session) => session.map.segment?.layer === 4)
            .map(({ key, map: { segment: { Segment, length } } }) =>
              currentSessionKey === key ? (
                <CurrentSegment
                  key={key}
                  Segment={Segment}
                  data={data}
                  length={length}
                  sessionKey={key}
                />
              ) : (
                <PathSegment
                  key={key}
                  segment={Segment}
                  length={length}
                  percentageComplete={getSessionUnlockProgress(
                    data,
                    key,
                    currentTimestamp
                  )}
                />
              )
            )}
          <Layer3 className={styles['layer']} />
          {segmentData
            .filter((session) => session.map.segment?.layer === 2)
            .map(({ key, map: { segment: { Segment, length } } }) =>
              currentSessionKey === key ? (
                <CurrentSegment
                  key={key}
                  Segment={Segment}
                  data={data}
                  length={length}
                  sessionKey={key}
                />
              ) : (
                <PathSegment
                  key={key}
                  segment={Segment}
                  length={length}
                  percentageComplete={getSessionUnlockProgress(
                    data,
                    key,
                    currentTimestamp
                  )}
                />
              )
            )}
          <Layer1 className={styles['layer']} />
          {flagData.map(({ key, url, map: { flag: { x, y } } }, i) =>
            currentSessionKey === key ? (
              <CurrentFlag
                key={i}
                text={i + 1}
                x={x}
                y={y}
                url={url}
                data={data}
                sessionKey={key}
              />
            ) : (
              <Flag
                key={i}
                raised={
                  getSessionUnlockProgress(data, key, currentTimestamp) < 1
                    ? false
                    : true
                }
                text={i + 1}
                x={x}
                y={y}
                url={url}
              />
            )
          )}
          <div
            className={styles['overlay-button']}
            onClick={() => {
              setShowOverlay(!showOverlay);
              setShowMapHelp(false);
            }}
          >
            <img
              className={styles['overlay-button-icon']}
              src={helpOverlayImg}
              alt="Help Overlay"
            />
          </div>
          {showOverlay && (
            <div
              className={styles['overlay']}
              onClick={() => {
                setShowOverlay(false);
                setShowMapHelp(false);
              }}
            >
              <Overlay className={[styles['layer'], styles['overlay-svg']]} />
              <div className={styles['text-1']}>
                <p>Each flag is a session on your journey to quit tobacco.</p>
                <p>Raised blue flag = session available.</p>
                <p>
                  Return to these sessions at any time by clicking on the flag.
                </p>
              </div>
              <div className={styles['text-2']}>
                <p>Lowered gray flag = session is not yet available.</p>
                <p>
                  Sessions will unlock once you complete the previous sessions
                  and the next session becomes available to view.
                </p>
              </div>
              <div className={styles['text-3']}>
                <p>
                  This bar will take you to your most recent session or show you
                  when your next session is available.
                </p>
                <p>Click on the blue flag to go to that session.</p>
              </div>
              <div className={styles['text-4']}>
                <p>
                  Click these icons for User Profile, Info Hub, Nic Check,
                  Journey Journal and Home Screen
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showOverlay && <div className={styles['fill']} />}
    </div>
  );
};

export default Map;
