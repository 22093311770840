import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import IntroBackground from 'components/Layout/components/IntroBackground';

import NicCheckLink from 'components/NicCheckLink';
import JournalLink from 'components/JournalLink';

const Intro = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-five-video" />}
    title="Session 5: Gaining Momentum"
    subTitle="Session Introduction"
    Background={IntroBackground}
    showChildrenBackground={false}
  >
    <Section>
      <p>Welcome back! Today’s the big day. Are you ready to quit for good?</p>
      <p>
        Today you will reflect on your work thus far and take action for your
        future. Think about how far you’ve already come. Take a look at your
        Quit Plan and goals in your <JournalLink /> and <NicCheckLink />.
      </p>
      <p>
        Congratulations! You’re on your way. We have more tips and tools to help
        you stay on course and celebrate your quit plan!
      </p>
      <p>Here’s what we will cover this session:</p>
      <ul>
        <li>Reflecting on your experiences of becoming tobacco-free</li>
        <li>Avoiding slips and risky situations</li>
        <li>Rewarding yourself</li>
      </ul>
    </Section>
  </Layout>
);

export default Intro;
