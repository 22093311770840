import Layout, { NextPageButton } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';

const Video = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-eight-interactive" />}
    title="Session 8: Staying on Course"
    subTitle="Video"
  >
    <VideoEmbed videoId="SujSoqzDpfM" title="Session Eight Video" />
  </Layout>
);

export default Video;
