import React, { useState } from 'react';

// For reference: https://kentcdodds.com/blog/how-to-use-react-context-effectively

const NicCheckContext = React.createContext(undefined);

const NicCheckProvider = ({ children }) => {
  const [showNicCheck, setShowNicCheck] = useState(false);
  return (
    <NicCheckContext.Provider value={{ showNicCheck, setShowNicCheck }}>
      {children}
    </NicCheckContext.Provider>
  );
};

const useNicCheck = () => {
  const nicCheck = React.useContext(NicCheckContext);
  if (nicCheck === undefined) {
    throw new Error(`useNicCheck must be used within a NicCheckProvider`);
  }
  return nicCheck;
};

export { NicCheckProvider, useNicCheck };
