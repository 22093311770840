import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import firebase from 'utils/fire';
import VideoEmbed from 'components/VideoEmbed';
import Layout from 'components/Layout';
import { useIsAuthenticated } from 'components/AuthenticationContext';

import styles from './Home.module.css';

const Home = () => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  const signOut = () => {
    firebase.auth().signOut();
  };

  // return (<Layout user={user} data={data} dataRef={dataRef}>
  //   <div className={styles['wrapper']}>
  //     <div className={styles['test']}/>
  //     </div>
  //     </Layout>)

  return (
    <Layout
      nextPageButton={
        <div className={styles['buttons']}>
          {isAuthenticated ? (
            <>
              <Button onClick={() => signOut()}>Logout</Button>
            </>
          ) : (
            <>
              <Button onClick={() => history.push(`/login`)}>Login</Button>
              <Button onClick={() => history.push(`/create-account`)}>
                Create account
              </Button>
            </>
          )}
        </div>
      }
    >
      <div className={styles['wrapper']}>
        <VideoEmbed videoId="SwlHppdHF60" title="Introductory Video" />
      </div>
    </Layout>
  );
};

export default Home;
