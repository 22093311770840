import { REF_KEY_BASE as SESSION_ONE_REF_KEY_BASE } from 'pages/SessionOne/data';
import { REF_KEY_BASE as SESSION_TWO_REF_KEY_BASE } from 'pages/SessionTwo/data';
import { REF_KEY_BASE as SESSION_THREE_REF_KEY_BASE } from 'pages/SessionThree/data';
import { REF_KEY_BASE as SESSION_FOUR_REF_KEY_BASE } from 'pages/SessionFour/data';
import { REF_KEY_BASE as SESSION_FIVE_REF_KEY_BASE } from 'pages/SessionFive/data';
import { REF_KEY_BASE as SESSION_SIX_REF_KEY_BASE } from 'pages/SessionSix/data';
import { REF_KEY_BASE as SESSION_SEVEN_REF_KEY_BASE } from 'pages/SessionSeven/data';
import { REF_KEY_BASE as SESSION_EIGHT_REF_KEY_BASE } from 'pages/SessionEight/data';

import { ReactComponent as SegmentOne } from 'assets/images/map/path/path_segment_1.svg';
import { ReactComponent as SegmentTwo } from 'assets/images/map/path/path_segment_2.svg';
import { ReactComponent as SegmentThree } from 'assets/images/map/path/path_segment_3.svg';
import { ReactComponent as SegmentFour } from 'assets/images/map/path/path_segment_4.svg';
import { ReactComponent as SegmentFive } from 'assets/images/map/path/path_segment_5.svg';
import { ReactComponent as SegmentSix } from 'assets/images/map/path/path_segment_6.svg';
import { ReactComponent as SegmentSeven } from 'assets/images/map/path/path_segment_7.svg';
import { ReactComponent as SegmentEight } from 'assets/images/map/path/path_segment_8.svg';
import { ReactComponent as SegmentNine } from 'assets/images/map/path/path_segment_9.svg';

import { ReactComponent as SessionOneBackground } from 'assets/images/map/mobile/mobile_session_1.svg';
import { ReactComponent as SessionTwoBackground } from 'assets/images/map/mobile/mobile_session_2.svg';
import { ReactComponent as SessionThreeBackground } from 'assets/images/map/mobile/mobile_session_3.svg';
import { ReactComponent as SessionFourBackground } from 'assets/images/map/mobile/mobile_session_4.svg';
import { ReactComponent as SessionFiveBackground } from 'assets/images/map/mobile/mobile_session_5.svg';
import { ReactComponent as SessionSixBackground } from 'assets/images/map/mobile/mobile_session_6.svg';
import { ReactComponent as SessionSevenBackground } from 'assets/images/map/mobile/mobile_session_7.svg';
import { ReactComponent as SessionEightBackground } from 'assets/images/map/mobile/mobile_session_8.svg';

const SESSION_DATA = [{
  key: SESSION_ONE_REF_KEY_BASE,
  name: `Session 1`,
  title: `Getting The Facts`,
  url: `/session-one`,
  delay: {
    time: process.env.REACT_APP_SESSION_ONE_UNLOCK_TIME,
    text: process.env.REACT_APP_SESSION_ONE_UNLOCK_TEXT
  },
  map: {
    segment: {
      Segment: SegmentOne,
      layer: 2,
      length: 609.1115112304688
    },
    flag: {
      x: 383.66,
      y: 688
    }
  },
  MobileButtonBackground: SessionOneBackground
}, {
  key: SESSION_TWO_REF_KEY_BASE,
  name: `Session 2`,
  title: `Seeing a Tobacco-free View`,
  url: `/session-two`,
  delay: {
    time: process.env.REACT_APP_SESSION_TWO_UNLOCK_TIME,
    text: process.env.REACT_APP_SESSION_TWO_UNLOCK_TEXT
  },
  map: {
    segment: {
      Segment: SegmentTwo,
      layer: 2,
      length: 608.630859375
    },
    flag: {
      x: 790.66,
      y: 536
    }
  },
  MobileButtonBackground: SessionTwoBackground
}, {
  key: SESSION_THREE_REF_KEY_BASE,
  name: `Session 3`,
  title: `Getting Ready`,
  url: `/session-three`,
  delay: {
    time: process.env.REACT_APP_SESSION_THREE_UNLOCK_TIME,
    text: process.env.REACT_APP_SESSION_THREE_UNLOCK_TEXT
  },
  map: {
    segment: {
      Segment: SegmentThree,
      layer: 2,
      length: 610.01123046875
    },
    flag: {
      x: 1257.66,
      y: 828
    }
  },
  MobileButtonBackground: SessionThreeBackground
}, {
  key: SESSION_FOUR_REF_KEY_BASE,
  name: `Session 4`,
  title: `Gearing Up for Quit Day`,
  url: `/session-four`,
  delay: {
    time: process.env.REACT_APP_SESSION_FOUR_UNLOCK_TIME,
    text: process.env.REACT_APP_SESSION_FOUR_UNLOCK_TEXT
  },
  map: {
    segment: {
      Segment: SegmentFour,
      layer: 2,
      length: 660.18408203125
    },
    flag: {
      x: 1682.66,
      y: 460
    }
  },
  MobileButtonBackground: SessionFourBackground
}, {
  key: SESSION_FIVE_REF_KEY_BASE,
  name: `Session 5`,
  title: `Gaining Momentum`,
  url: `/session-five`,
  delay: {
    time: process.env.REACT_APP_SESSION_FIVE_UNLOCK_TIME,
    text: process.env.REACT_APP_SESSION_FIVE_UNLOCK_TEXT
  },
  map: {
    segment: {
      Segment: SegmentFive,  // path behind mountains (layer 4)
      layer: 4,
      length: 574.0574340820312
    },
    flag: {
      x: 1377.66,
      y: 274
    }
  },
  MobileButtonBackground: SessionFiveBackground
}, {
  key: SESSION_SIX_REF_KEY_BASE,
  name: `Session 6`,
  title: `Getting Needs Met`,
  url: `/session-six`,
  delay: {
    time: process.env.REACT_APP_SESSION_SIX_UNLOCK_TIME,
    text: process.env.REACT_APP_SESSION_SIX_UNLOCK_TEXT
  },
  map: {
    segment: {
      Segment: SegmentSix,
      layer: 2,
      length: 684.8067016601562
    },
    flag: {
      x: 960.66,
      y: 437
    }
  },
  MobileButtonBackground: SessionSixBackground
}, {
  key: SESSION_SEVEN_REF_KEY_BASE,
  name: `Session 7`,
  title: `Helping Others Along the Way`,
  url: `/session-seven`,
  delay: {
    time: process.env.REACT_APP_SESSION_SEVEN_UNLOCK_TIME,
    text: process.env.REACT_APP_SESSION_SEVEN_UNLOCK_TEXT
  },
  map: {
    segment: {
      Segment: SegmentSeven,
      layer: 2,
      length: 609.0040893554688
    },
    flag: {
      x: 317.66,
      y: 228
    }
  },
  MobileButtonBackground: SessionSevenBackground
}, {
  key: SESSION_EIGHT_REF_KEY_BASE,
  name: `Session 8`,
  title: `Staying on Course`,
  url: `/session-eight`,
  delay: {
    time: process.env.REACT_APP_SESSION_EIGHT_UNLOCK_TIME,
    text: process.env.REACT_APP_SESSION_EIGHT_UNLOCK_TEXT
  },
  map: {
    segment: {
      Segment: SegmentEight,
      layer: 2,
      length: 653.8657836914062
    },
    flag: {
      x: 885.66,
      y: 114
    }
  },
  MobileButtonBackground: SessionEightBackground
}, {
  key: 'postEval',
  name: `Post-Program Survey`,
  title: `Post-Program Survey`,
  url: `/post-program-survey`,
  delay: {  // TODO fix this
    time: 0,
    text: ''
  },
  map: {
    segment: {
      Segment: SegmentNine,
      layer: 2,
      length: 496.5018310546875
    }
  }
}, {
  key: 'certificate',
  name: 'Completion Certificate',
  title: 'Completion Certificate',
  url: '/certificate',
  delay: {
    time: 0,
    text: ''
  }
}];

export default SESSION_DATA;
