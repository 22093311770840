import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './ShortTextValidation.module.css';

const validators = {
  positiveInteger: {
    isValid: (value) => /^-?\d+$/.test(value),
    errorText: 'Response must be a whole number'
  },
  zipCode: {
    isValid: (value) => /^-?\d+$/.test(value) && value.length === 5,
    errorText: 'Response must be a valid zip code'
  },
  default: {
    isValid: (value) => !!(value && value.length),
    errorText: 'Response required'
  }
};

const getValidator = (validationType = 'default') => validators[validationType];

const ShortTextValidation = ({
  questionData: { question, validationType, required = true },
  answerData: { value = '' },
  baseUpdate
}) => {
  // TODO use required value to show or hide 'Response required'
  const [id] = useState(uuid());
  const update = baseUpdate(`value`);
  const { isValid, errorText } = getValidator(validationType);
  return (
    <div className={styles['wrapper']}>
      {question && <label htmlFor={id}>{question}</label>}
      <input
        id={id}
        // isInvalid={!isValid(value)}
        value={value}
        type="text"
        onChange={(e) =>
          update(
            e.target.value,
            (!required && value.length === 0) || isValid(e.target.value)
          )
        }
      />
      <span className={styles.error}>
        {(!required && value.length === 0) || isValid(value) ? ' ' : errorText}
      </span>
    </div>
  );
};

export default ShortTextValidation;
