// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'sessionFiveInteractive';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  cravingAlternatives: {
    questionType: 'multipleSelect',
    question: undefined,
    answers: [
      {
        label: 'Take a few deep breaths',
        value: 'takeBreaths'
      },
      {
        label: 'Remind yourself why you want to quit using tobacco',
        value: 'remindSelf'
      },
      {
        label: 'Plan rewards for yourself when you quit',
        value: 'planRewards'
      },
      {
        label: 'Think about or do something else',
        value: 'thinkAboutSomethingElse'
      },
      {
        label: 'Use positive self-talk',
        value: 'positiveSelfTalk'
      },
      {
        label: 'Chew on a straw or sugarless gum, or hard candy',
        value: 'chewGum'
      },
      {
        label:
          'Keep your hands busy by doodling, writing a note, or playing a game on your phone',
        value: 'keepHandsBusy'
      },
      {
        label: 'Drink water',
        value: 'drinkWater'
      },
      {
        label: 'Text, call a friend',
        value: 'textFriend'
      }
    ]
  },
  rewards: {
    questionType: 'multipleSelect',
    question: undefined,
    answers: [
      {
        label: 'Go to a concert',
        value: 'concert'
      },
      {
        label: 'Stream music or a show',
        value: 'streamMusic'
      },
      {
        label: 'Buy a new video game',
        value: 'buyGame'
      },
      {
        label: 'Download a relaxation app',
        value: 'relaxationApp'
      },
      {
        label: 'Join a gym or fitness class',
        value: 'joinGym'
      },
      {
        label: 'Sign up to run or walk in a race with a friend',
        value: 'signUpForRace'
      },
      {
        label: 'Get a massage or pedicure',
        value: 'getMassage'
      },
      {
        label: 'Buy a new book',
        value: 'buyBook'
      },
      {
        label: 'Stream or go to the movies with your friends',
        value: 'goToMovie'
      },
      {
        label: 'Go to your favorite coffee shop',
        value: 'getCoffee'
      },
      {
        label: 'Buy a new piece of clothing or shoes',
        value: 'buyClothing'
      },
      {
        label: 'Buy a flower or plant to take care of',
        value: 'buyPlant'
      },
      {
        label: 'Treat a friend or your support person to lunch or coffee',
        value: 'treatFriend'
      },
      {
        label: 'Buy new headphones to listen to your music',
        value: 'buyHeadphones'
      },
      {
        label: 'Spend time alone (me-time!)',
        value: 'meTime'
      },
      {
        label: 'Draw or journal',
        value: 'drawOrJournal'
      },
      {
        label: 'Buy a gift for someone else',
        value: 'buyGift'
      },
      {
        label: 'Open a savings account',
        value: 'savingsAccount'
      },
      {
        label: 'Do something nice for someone else',
        value: 'somethingNice'
      }
    ]
  },
  motivation: {
    questionType: 'motivationLikert',
    question:
      'How would you rate your motivation to stop using tobacco products right now?'
  },
  confidence: {
    questionType: 'confidenceLikert',
    question:
      'How would you rate your confidence in stopping use of tobacco products right now?'
  },
  goalChampions: {
    questionType: 'shortText',
    question:
      'Write down names of 2 of your biggest champions as you quit tobacco.'
  },
  goalPepTalk: {
    questionType: 'shortText',
    question:
      'Create a “pep talk” phrase (positive self-talk, like “I’ve got this”); use it once a day over the next 7 days. What is that phrase for you?'
  },
  goalExercise: {
    questionType: 'shortText',
    question:
      'Exercise at least 15 minutes 6 days this week; 20 minutes on 1 day. What physical activity will you do this week?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
