// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'sessionOneInteractive';

const USAGE_REASONS = [
  {
    label: 'Bored',
    value: 'bored',
    icon: 'bored'
  },
  {
    label: 'Adventure/risk',
    value: 'adventure',
    icon: 'adventure'
  },
  {
    label: 'To be different',
    value: 'different',
    icon: 'different'
  },
  {
    label: 'To calm down/relax',
    value: 'relax',
    icon: 'relaxed'
  },
  {
    label: 'To be cool',
    value: 'cool',
    icon: 'cool'
  },
  {
    label: 'My friends and/or family use tobacco',
    value: 'friendsUse',
    icon: 'friends'
  },
  {
    label: 'Ads on social media',
    value: 'socialMedia',
    icon: 'ads'
  },
  {
    label: 'Other',
    value: 'other',
    icon: 'other'
  }
];

const INTERACTIVE_QUESTIONS: QuestionSet = {
  reasonsIUse: {
    questionType: 'multipleSelect',
    style: 'icon',
    question: 'What are some of your reasons for vaping/smoking/chewing?',
    answers: USAGE_REASONS
  },
  reason1: {
    questionType: 'shortText',
    question:
      'Think about three reasons you want to quit. Write your reasons in a “positive affirmation” statement that you will say out loud daily. For example, instead of “I want more money.” write and say “I will have more money to do what I want to do.”'
  },
  reason2: {
    required: false,
    questionType: 'shortText',
    question: undefined
  },
  reason3: {
    required: false,
    questionType: 'shortText',
    question: undefined
  },
  namePerson: {
    questionType: 'shortText',
    question:
      'Name the person you will tell about your plan to quit using tobacco:'
  },
  quitDate: {
    questionType: 'shortText',
    question:
      'Think about the quit date you will set next week. Review your calendar – look forward about 3 weeks from now. Think about stressors or deadlines. Is there a day of the week that might be best for you to set your quit date?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE, USAGE_REASONS };
