import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import IntroBackground from 'components/Layout/components/IntroBackground';

const Intro = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-six-video" />}
    title="Session 6: Getting Needs Met"
    subTitle="Session Introduction"
    Background={IntroBackground}
    showChildrenBackground={false}
  >
    <Section>
      <p>Welcome back!</p>
      <p>
        The closer you get to your journey goals, the stronger you are. Stay on
        your path.
      </p>
      <p>Here’s what we will explore in this session:</p>
      <ul>
        <li>Identifying ways to express your needs to friends and family</li>
        <li>
          Getting familiar with challenges to expect during the quitting process
        </li>
        <li>Planning for overcoming obstacles to quitting</li>
      </ul>
    </Section>
  </Layout>
);

export default Intro;
