import deepmerge from 'deepmerge';
import MultipleSelect from 'components/MultipleSelect';
import RadioSelect from 'components/RadioSelect';
import ShortTextValidation from 'components/ShortTextValidation';
import Likert, { MotivationLikert, ConfidenceLikert } from 'components/Likert';
import DateSelector from 'components/DateSelector';
import StateSelector from 'components/StateSelector';
import TrueFalse from 'components/TrueFalse';
import PostItMultipleSelect from 'components/PostItMultipleSelect';
import Replacement from 'components/Replacement';
import QuestionInstructions from 'components/QuestionInstructions';
import Communication from 'components/Communication';
import { getAnswerData } from 'utils/data';
import {
  useAuthenticationData,
  useIsAuthenticated
} from 'components/AuthenticationContext';

const componentFormatter = {
  multipleSelect: MultipleSelect,
  radioSelect: RadioSelect,
  shortText: ShortTextValidation,
  likert: Likert,
  motivationLikert: MotivationLikert,
  confidenceLikert: ConfidenceLikert,
  dateSelect: DateSelector,
  stateSelect: StateSelector,
  trueFalse: TrueFalse,
  postItMultipleSelect: PostItMultipleSelect,
  replacement: Replacement,
  text: QuestionInstructions,
  communication: Communication
};

const buildRequiredSessionQuestionKeys = (questionData) => (
  sessionData = {}
) => {
  return Object.entries(questionData)
    .filter(([key, { required = true }]) => {
      if (typeof required === 'function') {
        return required(sessionData);
      }
      return required;
    })
    .map(([key, value]) => key);
};

// TODO this has gotten pretty convoluted and could use a refactor
/** Modifies the given updateObject to include a session isCompleted and completedTimestamp if all
 *  required questions (from requiredSessionQuestionKeys and the question in updateObject) are completed.
 */
const buildUpdateSessionCompletion = (
  sessionRefKey,
  baseBuildRequiredSessionQuestionKeys,
  excludedQuestionKey
) => (data) => (updateObject) => {
  // if (!dataRef) return;

  // TODO - could optimize a bit by first seeing if there are any function values for questionData required
  // properties and then only do the lookahead + merge if there are, since otherwise the required values are static
  const incomingChanges = Object.entries(updateObject).reduce(
    (acc, [key, value]) => {
      // the first layer is always the session key, so we can ignore it
      // this converts the string refKey (eg. { preEval/tobaccoTypes/other/selected: true } to an array,
      // then to a nested object, eg. { preEval: { tobaccoTypes: { other: { selected: true }}}})
      const layers = key
        .split('/')
        .slice(1)
        .reduceRight((acc, key) => ({ [key]: acc }), value);
      // then it merges it with all the other incoming string refKey -> nested objects
      return deepmerge(acc, layers);
    },
    {}
  );
  // merge the converted incoming changes in with the existing data
  // NOTE - will likely need to update this to work with arrays properly (replace instead of concat)
  const mergedData = deepmerge(data?.[sessionRefKey], incomingChanges);

  const requiredSessionQuestionKeys = baseBuildRequiredSessionQuestionKeys(
    mergedData
  );

  const hasPreviousTimestamp = data?.[sessionRefKey]?.isCompleted !== undefined;
  const isCompleted =
    requiredSessionQuestionKeys
      .filter((questionKey) => questionKey !== excludedQuestionKey)
      .every(
        (questionKey) => !!data?.[sessionRefKey]?.[questionKey]?.isCompleted
      ) &&
    updateObject[`${sessionRefKey}/${excludedQuestionKey}/isCompleted`] ===
      true;

  // if it's not completed and has never been completed, don't update the session-level value
  if (!isCompleted && !hasPreviousTimestamp) return updateObject;

  updateObject[`${sessionRefKey}/isCompleted`] = isCompleted;
  if (isCompleted === true && !hasPreviousTimestamp) {
    updateObject[`${sessionRefKey}/completedTimestamp`] = Date.now();
  }

  return updateObject;
};

const buildUpdate = (
  updateSessionCompletion,
  hasPreviousTimestamp,
  dataRef,
  baseRefKey
) => (addedRefKey = '') => (value, isCompleted) => {
  if (!dataRef) return;
  let updateObject = {
    [`${baseRefKey}${addedRefKey ? `/${addedRefKey}` : ``}`]: value
  };
  if (isCompleted !== undefined) {
    updateObject[`${baseRefKey}/isCompleted`] = isCompleted;
  }
  if (isCompleted === true && !hasPreviousTimestamp) {
    // Don't update the timestamp if there previously was one - otherwise, it might reset the timer
    updateObject[`${baseRefKey}/completedTimestamp`] = Date.now();
  }
  updateObject = updateSessionCompletion(updateObject);

  dataRef.update(updateObject);
};

const VerifiedAbstractQuestion = ({
  baseUpdateSessionCompletion,
  questionData,
  refKey
}) => {
  const { data, dataRef } = useAuthenticationData();

  const updateSessionCompletion = baseUpdateSessionCompletion(data);

  const QuestionType = componentFormatter[questionData.questionType];
  const answerData = getAnswerData(data, refKey);
  const baseUpdate = buildUpdate(
    updateSessionCompletion,
    answerData.completedTimestamp !== undefined,
    dataRef,
    refKey
  );

  return (
    <QuestionType
      questionData={questionData}
      answerData={answerData}
      baseUpdate={baseUpdate}
    />
  );
};

const AbstractQuestion = ({
  baseUpdateSessionCompletion,
  questionData,
  refKey
}) => {
  const isAuthenticated = useIsAuthenticated();
  const QuestionType = componentFormatter[questionData.questionType];
  if (!QuestionType) return <div></div>; // TODO - throw an error here - this should never be the case since the data isn't dynamic here
  if (!isAuthenticated) return <div></div>; // TODO - put a loading spinner or grayed out blank version of the question here instead
  return (
    <VerifiedAbstractQuestion
      baseUpdateSessionCompletion={baseUpdateSessionCompletion}
      questionData={questionData}
      refKey={refKey}
    />
  );
};

const buildAbstractQuestion = (questionData, sessionRefKey) => ({ refKey }) => {
  const baseBuildRequiredSessionQuestionKeys = buildRequiredSessionQuestionKeys(
    questionData
  );

  const baseUpdateSessionCompletion = buildUpdateSessionCompletion(
    sessionRefKey,
    baseBuildRequiredSessionQuestionKeys,
    refKey
  );

  return (
    <AbstractQuestion
      baseUpdateSessionCompletion={baseUpdateSessionCompletion}
      questionData={questionData[refKey]}
      refKey={`${sessionRefKey}/${refKey}`}
    />
  );
};

export { AbstractQuestion as default, buildAbstractQuestion };
