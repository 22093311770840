import Button from 'components/Button';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Modal from 'components/Modal';
import {
  useAuthenticationData,
  useIsAuthenticated
} from 'components/AuthenticationContext';
import { useJournal } from 'components/JournalContext';

import JournalPDF from './components/JournalPDF';

import styles from './JournalModal.module.css';

const ModalContent = ({ data }) => {
  const savePDF = async () => {
    const blob = await pdf(<JournalPDF data={data} />).toBlob();
    saveAs(blob, 'Journal.pdf');
  };

  // const [instance, updateInstance] = usePDF({ document: JournalPDF });
  // useEffect(() => {

  // }, [data]);
  return (
    <div className={styles['main']}>
      <p>
        Your Journey Journal is your personalized and protected NOT for Me
        workbook. Your responses from “Explore More”, Nic-Check, Commit to Quit,
        and Goal Setting sections are recorded and updated in “real time” and
        will help you track your progress throughout the NOT for Me program.
        Your Journey Journal can be downloaded to preview or you can print,
        email or share with others.
      </p>
      <Button onClick={() => savePDF()}>Click here to view your Journal</Button>
    </div>
  );
};

const JournalModal = () => {
  const { data } = useAuthenticationData();
  const { showJournal, setShowJournal } = useJournal();
  return (
    <Modal
      isOpen={showJournal}
      onClose={() => setShowJournal(false)}
      title="Journey Journal"
    >
      <ModalContent data={data} />
    </Modal>
  );
};

const JournalModalAuthenticationWrapper = () => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? <JournalModal /> : null;
};

export default JournalModalAuthenticationWrapper;
