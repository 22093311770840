import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './BaseDropdown.module.css';

const BaseDropdown = ({ label, options, selected = 'none', onSelect }) => {
  const [id] = useState(uuid());

  return (
    <div className={styles['wrapper']}>
      <label htmlFor={id}>{label}</label>
      <select
        value={selected}
        id={id}
        className={styles['answers']}
        onChange={(e) => onSelect(e.target.value)}
      >
        <option hidden={!!selected} disabled value={'none'}>
          {' '}
          -- select an option --{' '}
        </option>
        {options.map(({ label, value }, i) => (
          <option key={i} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BaseDropdown;
