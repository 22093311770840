import styles from './IntroBackground.module.css';

import background from 'assets/images/background.svg';

const IntroBoard = () => (
  <div className={styles['board-container']}>
    <div
      className={[styles['panel'], styles['side'], styles['left']].join(' ')}
    />
    <div
      className={[styles['panel'], styles['side'], styles['right']].join(' ')}
    />
    <div className={[styles['panel'], styles['bottom']].join(' ')} />
    <div className={styles['background-panel']} />
  </div>
);

const IntroBackground = () => (
  <>
    <div className={styles['background-container']}>
      <img className={styles['background']} src={background} alt="background" />
    </div>
    <div className={styles['trail-board']}>
      <IntroBoard />
    </div>
  </>
);

export default IntroBackground;
