// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'userProfile';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  firstName: {
    questionType: 'shortText',
    question: 'Name (first name/nickname)'
  },
  birthday: {
    questionType: 'dateSelect',
    maxDate: () => new Date(),
    question: 'When is your birthday?'
  },
  startAge: {
    questionType: 'shortText',
    validationType: 'positiveInteger',
    question: 'What age did you start using tobacco products?'
  },
  state: {
    questionType: 'stateSelect',
    question: 'What state do you live in?'
  },
  zipCode: {
    questionType: 'shortText',
    validationType: 'zipCode',
    question: 'What is your 5-digit zip code?'
  },
  phoneNumber: {
    questionType: 'shortText',
    // validationType: 'zipCode', // TODO add phone number validator
    question: 'What is your mobile phone number?'
  },
  genderIdentity: {
    questionType: 'radioSelect',
    question: 'What is your gender identity?',
    answers: [
      {
        label: 'Female',
        value: 'female'
      },
      {
        label: 'Male',
        value: 'male'
      },
      {
        label: 'Non-binary/third gender',
        value: 'nonBinaryOrThirdGender'
      },
      {
        label: 'Prefer to self-describe - write in',
        // dataType: 'other',  // TODO - attach to separate text field
        value: 'other'
      },
      {
        label: 'Prefer not to say',
        value: 'preferNotToSay'
      }
    ]
  },
  otherGenderIdentity: {
    required: (data: any) =>
      data.genderIdentity?.isCompleted &&
      data.genderIdentity?.selectedValue === 'other',
    questionType: 'shortText',
    question: 'Please describe your gender identity:'
  },
  identifyAsTransgender: {
    questionType: 'radioSelect',
    question: 'Do you identify as transgender?',
    answers: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'Prefer not to say',
        value: 'preferNotToSay'
      }
    ]
  },
  sexualOrientation: {
    questionType: 'radioSelect',
    question: 'What is your sexual orientation?',
    answers: [
      {
        label: 'Straight/Heterosexual',
        value: 'heterosexual'
      },
      {
        label: 'Gay or Lesbian',
        value: 'gayOrLesbian'
      },
      {
        label: 'Bisexual',
        value: 'bisexual'
      },
      {
        label: 'Prefer to self-describe - write in',
        value: 'other'
      },
      {
        label: 'Prefer not to say',
        value: 'preferNotToSay'
      }
    ]
  },
  otherSexualOrientation: {
    required: (data: any) =>
      data.sexualOrientation?.isCompleted &&
      data.sexualOrientation?.selectedValue === 'other',
    questionType: 'shortText',
    question: 'Please describe your sexual orientation:'
  },
  ethnicity: {
    style: 'checkbox',
    questionType: 'multipleSelect',
    question:
      'What best describes your race/ethnicity? (Select all that apply)',
    answers: [
      {
        label: 'American Indian or Alaskan Native',
        value: 'americanIndianAlaskanNative'
      },
      {
        label: 'Asian',
        value: 'asian'
      },
      {
        label: 'Black or African American',
        value: 'blackAfricanAmerican'
      },
      {
        label: 'Hispanic or Latinx',
        value: 'hispanicLatinx'
      },
      {
        label: 'Middle Eastern or North African',
        value: 'middleEasternNorthAfrican'
      },
      {
        label: 'Native Hawaiian or Other Pacific Islander',
        value: 'nativeHawaiianPacificIslander'
      },
      {
        label: 'White',
        value: 'white'
      },
      {
        label: 'Other (please specify) – write in',
        // dataType: 'other', // TODO - attach to separate text field
        value: 'other'
      }
    ]
  },
  otherEthnicity: {
    required: (data: any) =>
      data.ethnicity?.isCompleted && data.ethnicity?.other?.selected,
    questionType: 'shortText',
    question: 'Please describe your ethnicity:'
  },
  triedBefore: {
    questionType: 'radioSelect',
    question: 'Have you tried to quit tobacco before?',
    answers: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  },
  previousNumberOfTimes: {
    questionType: 'shortText',
    validationType: 'positiveInteger',
    question:
      'Not counting this time, how many times have you participated in a tobacco cessation program to help you quit tobacco?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
