import { View, Text } from '@react-pdf/renderer';

import Header from '../Header';

const SayItLoud = ({ phrase }) => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <Header>Say It Loud</Header>
    {/* <Text>"{phrase}"</Text> */}
    <Text style={{ marginLeft: '15px', fontWeight: 'bold' }}>“{phrase}”</Text>
  </View>
);

export default SayItLoud;
