import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import IntroBackground from 'components/Layout/components/IntroBackground';

const Intro = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-seven-video" />}
    title="Session 7: Helping Others Along the Way"
    subTitle="Session Introduction"
    Background={IntroBackground}
    showChildrenBackground={false}
  >
    <Section>
      <p>Welcome back!</p>
      <p>
        As you get stronger in your journey, you are better prepared to serve as
        a role model and to help others. We will give you some tips here.
      </p>
      <p>Here’s what we will cover this session:</p>
      <ul>
        <li>
          Understanding how tobacco and e-cigarette advertising affects teens’
          thoughts, feelings, and behaviors
        </li>
        <li>
          Finding out how to be a change force to reduce tobacco use in your
          school or community
        </li>
      </ul>
    </Section>
  </Layout>
);

export default Intro;
