import Layout, { NextPageButton } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';

const Video = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-four-interactive" />}
    title="Session 4: Gearing Up for Quit Day"
    subTitle="Video"
  >
    <VideoEmbed videoId="PupjuL7zVyw" title="Session Four Video" />
  </Layout>
);

export default Video;
