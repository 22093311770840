import { Text, View } from '@react-pdf/renderer';

import Checkbox from '../Checkbox';

const MultiselectDisplay = ({ question, data = {} }) => (
  <>
    {question.answers.map((reason, i) => (
      <View
        key={reason.value}
        style={{ display: 'flex', flexDirection: 'row', marginLeft: '18px' }}
      >
        <Checkbox checked={!!data[reason.value]?.selected} />
        <Text
          style={{
            marginLeft: '5px'
          }}
        >
          {reason.label}
        </Text>
      </View>
    ))}
  </>
);

export default MultiselectDisplay;
