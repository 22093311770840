import { getAnswerData } from 'utils/data';

// import { REF_KEY_BASE as SESSION_ONE_REF_KEY_BASE } from 'pages/SessionOne/data';
// import { REF_KEY_BASE as SESSION_TWO_REF_KEY_BASE } from 'pages/SessionTwo/data';
// import { REF_KEY_BASE as SESSION_THREE_REF_KEY_BASE } from 'pages/SessionThree/data';
// import { REF_KEY_BASE as SESSION_FOUR_REF_KEY_BASE } from 'pages/SessionFour/data';
// import { REF_KEY_BASE as SESSION_FIVE_REF_KEY_BASE } from 'pages/SessionFive/data';
// import { REF_KEY_BASE as SESSION_SIX_REF_KEY_BASE } from 'pages/SessionSix/data';
// import { REF_KEY_BASE as SESSION_SEVEN_REF_KEY_BASE } from 'pages/SessionSeven/data';
// import { REF_KEY_BASE as SESSION_EIGHT_REF_KEY_BASE } from 'pages/SessionEight/data';

import SESSION_DATA from 'utils/sessionData';

/** These question types do not record completion data. */
// const IGNORED_QUESTION_TYPES = [
//   'multipleSelect',
//   'trueFalse',
//   'postItMultipleSelect',
//   'pipingQuestion'
// ];

// console.log('timer', process.env.REACT_APP_SESSION_FIVE_UNLOCK_TIME);

// TODO - adjust/re-add this time delay after text has been approved
// const DELAY_BETWEEN_SESSIONS = 432000; //1000 * 60 * 60 * 24 * 5 = 432000000  // 5 days

// const DELAY = {
//   [SESSION_ONE_REF_KEY_BASE]: {

//   }
// }

const isSessionCompleted = (data, sessionRefKey) => !!data[sessionRefKey]?.isCompleted;

/** Returns the session's completedTimestamp. If the session isn't completed, will return false. */
const getSessionCompletedTimestamp = (data, sessionRefKey) => isSessionCompleted(data, sessionRefKey) ? data[sessionRefKey]?.completedTimestamp || false : false;

/** Gets session data of the earliest /uncompleted/ session. If there are no uncompleted sessions, this will return null. */
const getCurrentUserSession = (data) => {
  if (!data) return null; // TODO this is clunky
  return (SESSION_DATA.find(session => !isSessionCompleted(data, session.key))) || null;
};

/** Gets session data of the session prior to the given session. If there is no valid session, this will return null. */
const getPreviousSession = (data, sessionKey) => {
  if (!data) return null;
  const currentIndex = SESSION_DATA.findIndex(session => session.key === sessionKey);
  if (currentIndex < 1) return null;
  return SESSION_DATA[currentIndex - 1];
};

/** Returns percentage towards unlocking the given session in the form of [0, 1]. */
const getSessionUnlockProgress = (data, sessionKey, currentTimestamp) => {
  if (!data) return 0;  // TODO this is clunky
  const previousSession = getPreviousSession(data, sessionKey);
  if (!previousSession) return 1;
  const previousSessionCompletedTimestamp = getSessionCompletedTimestamp(data, previousSession.key);
  if (!previousSessionCompletedTimestamp) return 0;
  const delayTime = SESSION_DATA.find(session => session.key === sessionKey).delay.time
  // console.log('getSessionUnlockProgress', sessionKey, previousSession, previousSessionCompletedTimestamp)
  return Math.min((currentTimestamp - previousSessionCompletedTimestamp) / delayTime, 1);
}


const isSectionAnswered = (question, data) => {
  const answer = getAnswerData(data, question);
  return !!answer.isCompleted;
};

export {
  // DELAY_BETWEEN_SESSIONS,
  isSectionAnswered,
  getCurrentUserSession,
  getSessionUnlockProgress,
};
