import { useState } from 'react';
import firebase from 'utils/fire';
import { Link } from 'react-router-dom';

import Button from 'components/Button';

import Layout from 'components/Layout';

import styles from './CreateAccount.module.css';

const CreateAccount = () => {
  const [email, setEmail] = useState('');
  const [second, setSecond] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleNewAccount = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = re.test(String(email).toLowerCase());

    if (!email || !second) {
      setErrorMessage('Please fill out both email fields');
    } else if (!password) {
      setErrorMessage('Please enter a password');
    } else if (email !== second) {
      setErrorMessage('Your emails do not match');
    } else if (!isValidEmail) {
      setErrorMessage('Please make sure your email is valid');
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        // .then(({ user }) => {
        //   if (user?.uid && user?.email) {
        //     const dataRef = firebase.database().ref(`users/${user.uid}`);
        //     dataRef.update({
        //       email: user?.email
        //     });
        //   } else {
        //     // TODO - throw an error here - we have no email for the user
        //   }
        // })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    }
  };

  return (
    <Layout>
      <div className={styles['wrapper']}>
        <label htmlFor="login-email">Please enter a valid email address</label>
        <input
          id="login-email"
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="email"
        />

        <label htmlFor="login-email-repeat">Please re-enter your email</label>
        <input
          id="login-email-repeat"
          type="text"
          onChange={(e) => setSecond(e.target.value)}
          value={second}
          placeholder="confirm your email"
        />

        <label htmlFor="password">Create a password</label>
        <input
          id="password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="password"
        />

        <div className={styles['error']}>{errorMessage}</div>

        <Button
          className={styles['login-button']}
          onClick={() => handleNewAccount()}
        >
          Create Account
        </Button>
        <p className={styles['terms-and-conditions']}>
          By creating an account you are agreeing to the{' '}
          <Link to="/terms-and-conditions">
            NOT for Me Terms and Conditions
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default CreateAccount;
