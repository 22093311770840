import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';
import JournalLink from 'components/JournalLink';

import IntroBackground from 'components/Layout/components/IntroBackground';

const Intro = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-two-video" />}
    title="Session 2: Seeing a Tobacco-free View"
    subTitle="Session Introduction"
    Background={IntroBackground}
    showChildrenBackground={false}
  >
    <Section>
      <p>Welcome back!</p>
      <p>
        Take a look at your goals in your <JournalLink /> from last week. How
        did you do? If you struggled, don’t give up. We have a lot more tips and
        tools to share with you during this session!
      </p>

      <p>Here’s what we will explore in this session:</p>

      <ul>
        <li>Understanding your tobacco use history</li>
        <li>Seeing yourself as a non-user of tobacco</li>
        <li>Understanding nicotine dependence</li>
        <li>Understanding how exercise can help you quit</li>
      </ul>
    </Section>
  </Layout>
);

export default Intro;
