import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/analytics';

import config from 'firebase-config.json';

const fire = firebase.initializeApp(config);
const analytics = firebase.analytics();

export { fire as default, analytics };