import { Switch, Route } from 'react-router-dom';

import PageTracking from 'components/analytics/PageTracking';
import ScrollToTop from 'components/ScrollToTop';

import Home from 'pages/Home';
import Landing from 'pages/Landing';
import Login from 'pages/Login';
import PasswordReset from 'pages/PasswordReset';
import CreateAccount from 'pages/CreateAccount';
import PreEval from 'pages/PreEval';
import PostEval from 'pages/PostEval';
import UserProfile from 'pages/UserProfile';
import InfoHub from 'pages/InfoHub';
import TermsAndConditions from 'pages/TermsAndConditions';
import AdminPortal from 'pages/AdminPortal';

import SessionOneIntro from 'pages/SessionOne/Intro';
import SessionOneVideo from 'pages/SessionOne/Video';
import SessionOneInteractive from 'pages/SessionOne/Interactive';

import SessionTwoIntro from 'pages/SessionTwo/Intro';
import SessionTwoVideo from 'pages/SessionTwo/Video';
import SessionTwoInteractive from 'pages/SessionTwo/Interactive';

import SessionThreeIntro from 'pages/SessionThree/Intro';
import SessionThreeVideo from 'pages/SessionThree/Video';
import SessionThreeInteractive from 'pages/SessionThree/Interactive';

import SessionFourIntro from 'pages/SessionFour/Intro';
import SessionFourVideo from 'pages/SessionFour/Video';
import SessionFourInteractive from 'pages/SessionFour/Interactive';

import SessionFiveIntro from 'pages/SessionFive/Intro';
import SessionFiveVideo from 'pages/SessionFive/Video';
import SessionFiveInteractive from 'pages/SessionFive/Interactive';

import SessionSixIntro from 'pages/SessionSix/Intro';
import SessionSixVideo from 'pages/SessionSix/Video';
import SessionSixInteractive from 'pages/SessionSix/Interactive';

import SessionSevenIntro from 'pages/SessionSeven/Intro';
import SessionSevenVideo from 'pages/SessionSeven/Video';
import SessionSevenInteractive from 'pages/SessionSeven/Interactive';

import SessionEightIntro from 'pages/SessionEight/Intro';
import SessionEightVideo from 'pages/SessionEight/Video';
import SessionEightInteractive from 'pages/SessionEight/Interactive';

import CompletionCertificate from 'pages/CompletionCertificate';

import ErrorNotFound from 'pages/ErrorNotFound';

const MainRouter = () => (
  <main>
    <PageTracking />
    <ScrollToTop />
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/reset">
        <PasswordReset />
      </Route>
      <Route exact path="/create-account">
        <CreateAccount />
      </Route>
      <Route exact path="/terms-and-conditions">
        <TermsAndConditions />
      </Route>
      <Route exact path="/landing">
        <Landing />
      </Route>
      <Route exact path="/info-hub/:section?/:item?">
        <InfoHub />
      </Route>
      <Route exact path="/user-profile">
        <UserProfile />
      </Route>
      <Route exact path="/user-info">
        <UserProfile intro={true} />
      </Route>
      <Route exact path="/pre-eval">
        <PreEval />
      </Route>
      <Route exact path="/post-program-survey">
        <PostEval />
      </Route>
      <Route exact path="/session-one">
        <SessionOneIntro />
      </Route>
      <Route exact path="/session-one-video">
        <SessionOneVideo />
      </Route>
      <Route exact path="/session-one-interactive">
        <SessionOneInteractive />
      </Route>
      <Route exact path="/session-two">
        <SessionTwoIntro />
      </Route>
      <Route exact path="/session-two-video">
        <SessionTwoVideo />
      </Route>
      <Route exact path="/session-two-interactive">
        <SessionTwoInteractive />
      </Route>
      <Route exact path="/session-three">
        <SessionThreeIntro />
      </Route>
      <Route exact path="/session-three-video">
        <SessionThreeVideo />
      </Route>
      <Route exact path="/session-three-interactive">
        <SessionThreeInteractive />
      </Route>
      <Route exact path="/session-four">
        <SessionFourIntro />
      </Route>
      <Route exact path="/session-four-video">
        <SessionFourVideo />
      </Route>
      <Route exact path="/session-four-interactive">
        <SessionFourInteractive />
      </Route>
      <Route exact path="/session-five">
        <SessionFiveIntro />
      </Route>
      <Route exact path="/session-five-video">
        <SessionFiveVideo />
      </Route>
      <Route exact path="/session-five-interactive">
        <SessionFiveInteractive />
      </Route>
      <Route exact path="/session-six">
        <SessionSixIntro />
      </Route>
      <Route exact path="/session-six-video">
        <SessionSixVideo />
      </Route>
      <Route exact path="/session-six-interactive">
        <SessionSixInteractive />
      </Route>
      <Route exact path="/session-seven">
        <SessionSevenIntro />
      </Route>
      <Route exact path="/session-seven-video">
        <SessionSevenVideo />
      </Route>
      <Route exact path="/session-seven-interactive">
        <SessionSevenInteractive />
      </Route>
      <Route exact path="/session-eight">
        <SessionEightIntro />
      </Route>
      <Route exact path="/session-eight-video">
        <SessionEightVideo />
      </Route>
      <Route exact path="/session-eight-interactive">
        <SessionEightInteractive />
      </Route>
      <Route exact path="/certificate">
        <CompletionCertificate />
      </Route>
      <Route exact path="/admin">
        <AdminPortal />
      </Route>
      <Route path="*">
        <ErrorNotFound />
      </Route>
    </Switch>
  </main>
);

export default MainRouter;
