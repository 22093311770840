import { Text } from '@react-pdf/renderer';

import Bullets from '../../components/Bullets';

const RelaxationHub = ({ bullets }) => (
  <>
    <Text style={{ fontWeight: 'bold' }}>
      Check out the Relaxation Center and additional resources on the Info Hub.
    </Text>
    <Bullets bullets={bullets} />
    {/* {bullets.map((bullet, i) => (
      <Text key={i}>{bullet}</Text>
    ))} */}
  </>
);

export default RelaxationHub;
