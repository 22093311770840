import MultipleSelect from 'components/MultipleSelect';

import styles from './PostItMultipleSelect.module.css';

const PostIt = ({
  header,
  description,
  answers,
  answerData = {},
  baseUpdate
}) => (
  <div className={styles['post-it-col']}>
    <h5>{header}</h5>
    <p>{description}</p>
    <MultipleSelect
      questionData={{
        style: 'note',
        answers
        // answers: answers.map(({ label, value }) => ({
        //   label,
        //   value,
        //   dataType: 'text'
        // }))
      }}
      answerData={answerData}
      baseUpdate={baseUpdate}
    />
  </div>
);

const PostItMultipleSelect = ({
  questionData: { answers },
  answerData,
  baseUpdate
}) => {
  return (
    <div className={styles['post-it-row']}>
      {answers.map(({ refKey, header, description, answers }, i) => {
        const wrappedBaseUpdate = (subKey) => baseUpdate(`${refKey}/${subKey}`);
        return (
          <PostIt
            key={i}
            header={header}
            description={description}
            answers={answers}
            answerData={answerData[refKey]}
            baseUpdate={wrappedBaseUpdate}
          />
        );
      })}
    </div>
  );
};

export default PostItMultipleSelect;
