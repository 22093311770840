import Layout, { NextPageButton } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';

const Video = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-seven-interactive" />}
    title="Session 7: Helping Others Along the Way"
    subTitle="Video"
  >
    <VideoEmbed videoId="Q0GTCWiGYfY" title="Session Seven Video" />
  </Layout>
);

export default Video;
