import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import InfoHubLink from 'components/InfoHubLink';
import NicCheckLink from 'components/NicCheckLink';
import JournalLink from 'components/JournalLink';

import { isSectionAnswered } from 'utils/progressCalculator';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const Interactive = () => {
  const { data } = useAuthenticationData();
  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          text="Submit Session"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Session 4: Gearing Up for Quit Day"
      subTitle="Explore More"
      Background={null}
    >
      <Section>
        <p>
          Let’s go a little further. Your answers on this exploration will be
          saved to your Journey Journal to help you understand your tobacco
          habits and track your progress.
        </p>
      </Section>

      <Section
        title="Signs of Healing"
        completed={isSectionAnswered(`${REF_KEY_BASE}/healing`, data)}
      >
        <p>
          As you give up tobacco products, you will have signals that your body
          is healing--a plus! But recovery signs can be uncomfortable, off and
          on, for two to four weeks. There are things you can do to get relief.
          Check out{' '}
          <InfoHubLink section="session-four" item="my-signs-of-healing">
            My Signs of Healing
          </InfoHubLink>{' '}
          for additional information. Check off the relief strategies that you
          want to try as part of your quit plan:
        </p>
        <AbstractQuestion refKey={'healing'} />
      </Section>

      <Section title="Reflect and Relax">
        <p>
          Remember, relaxation is especially important as your gear up for quit
          day because it can help you:
        </p>
        <ul>
          <li>Improve your chances of successfully quitting tobacco</li>
          <li>Reduce stress</li>
          <li>Be more aware of your tobacco habits and cravings</li>
          <li>Improve self-control</li>
        </ul>
        <p>
          Check out the different relaxation exercises in the Info Hub.{' '}
          <InfoHubLink section="relaxation">Give them a try.</InfoHubLink>
        </p>
      </Section>

      <Section title="Quitting Tobacco Makes Cents">
        <p>
          Another benefit of quitting tobacco is saving money. Check out{' '}
          <InfoHubLink
            section="session-four"
            item="making-cents-cost-calculator"
          >
            Making Cents - Cost Calculator
          </InfoHubLink>
          . Use this worksheet to figure out how much money you spend on tobacco
          and how much you could save.
        </p>
      </Section>

      <Section title="Quit Day Checklist">
        <p>
          You’ve come a long way in your journey so far. Take a look at{' '}
          <InfoHubLink section="session-four" item="my-quit-plan-checklist">
            My Quit Plan Checklist
          </InfoHubLink>{' '}
          and review this before your Quit Day. You may be surprised by how much
          you’ve already done to prepare yourself for success.
        </p>
      </Section>

      <Section
        title="Committed to Quit"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/motivation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/confidence`, data)
        }
      >
        <p>
          Mapping readiness and commitment to quit is proven to help teens quit
          tobacco use. Let’s take a closer look at where you are right now. Your
          answers will be saved in your <JournalLink /> to help you track your
          progress.
        </p>
        <AbstractQuestion refKey={'motivation'} />
        <AbstractQuestion refKey={'confidence'} />
      </Section>

      <Section
        title="Goal Setting"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/savings`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/useMoney`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/rewards`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/escapePlan`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/exercise`, data)
        }
      >
        <AbstractQuestion refKey={'savings'} />
        <AbstractQuestion refKey={'useMoney'} />
        <AbstractQuestion refKey={'rewards'} />
        <AbstractQuestion refKey={'escapePlan'} />
        <AbstractQuestion refKey={'exercise'} />
      </Section>

      <Section title="Nic Check">
        <p>
          Every time you use tobacco, track it with <NicCheckLink />. The more
          you track, the more aware you will be about your tobacco use,
          cravings, and triggers, which improves your chances to successfully
          quit tobacco! Your responses will be saved in your <JournalLink />.
        </p>
      </Section>

      <Section title="Info Hub">
        <p>Check out additional resources for this session!</p>
        {/* <p>
          <InfoHubLink section="session-four" item="exercising-my-rights">
            Exercising My Rights
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-four" item="got-to-keep-moving">
            Got to Keep Moving
          </InfoHubLink>
        </p> */}
        <p>
          <InfoHubLink section="session-four" item="my-signs-of-healing">
            My Signs of Healing
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink
            section="session-four"
            item="making-cents-cost-calculator"
          >
            Making Cents - Cost Calculator
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-four" item="my-quit-plan-checklist">
            My Quit Plan Checklist
          </InfoHubLink>
        </p>
      </Section>

      <Section title="Journey Journal">
        <p>
          <b>You have completed this session,</b> don’t forget to log back in on
          your designated quit day, next week. You’ll receive a reminder when
          it’s time to log back in.
        </p>
        <p>
          All of your responses from this session will be saved in your Journey
          Journal. <JournalLink>Click here to review your Journal.</JournalLink>
        </p>
      </Section>
    </Layout>
  );
};

export { Interactive as default, INTERACTIVE_QUESTIONS };
