import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './MultipleSelect.module.css';

const Select = ({
  answer: { label, tooltipText, icon },
  // value,
  selected,
  baseUpdate,
  lastSelected
}) => {
  const [id] = useState(uuid());
  const updateCheckbox = baseUpdate('selected');
  // const updateInput = baseUpdate('value');

  return (
    <span className={styles['select-wrapper']}>
      <input
        type="checkbox"
        checked={selected}
        onChange={() => updateCheckbox(!selected, !lastSelected)}
        id={id}
      />
      <label className={styles['label']} htmlFor={id}>
        {icon && (
          <div
            className={[styles['label-icon'], styles[`icon-${icon}`]].join(' ')}
          />
        )}
        {/* {icon && <img className={styles['label-icon']} src={icon} alt="" />} */}
        <div className={styles['label-text']}>{label}</div>

        {/* {tooltipText && (
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip">{tooltipText}</Tooltip>}
          >
            <InfoIcon />
          </OverlayTrigger>
        )} */}
      </label>
    </span>
  );
};

const MultipleSelect = ({
  questionData: { style = 'text', question, answers, required = true },
  answerData,
  baseUpdate
}) => {
  const [id] = useState(uuid());
  const answerCount = Object.values(answerData).filter(
    ({ selected }) => selected
  ).length;

  return (
    <div className={styles[`${style}-multiselect-wrapper`]}>
      {question && (
        <label htmlFor={id} className={styles['question']}>
          {question}
        </label>
      )}
      <div id={id} className={styles[`${style}-multiselect-group`]}>
        {answers.map((answer) => {
          const { value } = answer;
          const wrappedBaseUpdate = (subKey) =>
            baseUpdate(`${value}/${subKey}`);
          const { selected = false } = answerData[value] || {};

          // TODO if required, then pass in some way of determining whether there will be
          // at least one checked value after a change
          return (
            <Select
              key={value}
              answer={answer}
              selected={selected}
              baseUpdate={wrappedBaseUpdate}
              lastSelected={required && selected && answerCount === 1}
            />
          );
        })}
      </div>
      {/* {required && !answerData?.isCompleted && <div>Answer required</div>} */}
    </div>
  );
};

export default MultipleSelect;
