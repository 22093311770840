import styles from './PathSegment.module.css';

const PathSegment = ({ segment: Segment, length, percentageComplete = 0 }) => (
  <>
    {percentageComplete < 1 && (
      <Segment className={[styles['segment'], styles['unfilled']].join(' ')} />
    )}
    {percentageComplete > 0 && percentageComplete < 1 && (
      <Segment
        className={[styles['segment'], styles['filled']].join(' ')}
        style={{
          strokeDasharray: length,
          strokeDashoffset: length - length * percentageComplete
        }}
      />
    )}
    {percentageComplete >= 1 && (
      <Segment className={[styles['segment'], styles['filled']].join(' ')} />
    )}
  </>
);

export default PathSegment;
