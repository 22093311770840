import { Link } from 'react-router-dom';

import styles from './Footer.module.css';

const Footer = ({ className, children }) => (
  <div
    className={
      className ? [className, styles['footer']].join(' ') : styles['footer']
    }
  >
    {children}
    <div className={styles['footer-items']}>
      <div
        className={styles['footer-item']}
        onClick={() => {
          window.location.href =
            'mailto:NOT@Lung.org?subject=Issue with NOT for Me';
        }}
      >
        {`Help`}
      </div>
      <Link to="/terms-and-conditions" className={styles['footer-item']}>
        {`Terms`}
      </Link>
    </div>
  </div>
);

export default Footer;
