import { useHistory } from 'react-router-dom';

import { useAuthenticationData } from 'components/AuthenticationContext';
import styles from './MobileMap.module.css';

import SESSION_DATA from 'utils/sessionData';
import {
  getCurrentUserSession,
  getSessionUnlockProgress
} from 'utils/progressCalculator';

const MobileMap = () => {
  const { data } = useAuthenticationData();
  const history = useHistory();
  const activeSessionData = getCurrentUserSession(data);
  const currentTimestamp = Date.now();

  return (
    <div className={styles['mobile-map']}>
      <div className={styles['mobile-map-content-wrapper']}>
        {SESSION_DATA.filter((session) => !!session.map?.flag).map(
          (
            { key, url, title, MobileButtonBackground: ButtonBackground },
            index
          ) => {
            const isActiveSession = key === activeSessionData?.key;
            const isLockedSession =
              getSessionUnlockProgress(data, key, currentTimestamp) < 1;

            return (
              <div
                key={key}
                className={styles['session-wrapper']}
                onClick={() => {
                  if (!isLockedSession) {
                    history.push(url);
                  }
                }}
              >
                <div
                  className={[
                    styles['map-path'],
                    isLockedSession ? styles['locked'] : styles['unlocked']
                  ].join(' ')}
                />
                <div className={styles['session-contents']}>
                  <div
                    className={[
                      styles['session-number'],
                      isActiveSession ? styles['active'] : '',
                      isLockedSession ? styles['locked'] : ''
                    ].join(' ')}
                  >
                    {index + 1}
                  </div>
                  <div
                    className={[
                      styles['session-title'],
                      isActiveSession ? styles['active'] : '',
                      isLockedSession ? styles['locked'] : ''
                    ].join(' ')}
                  >
                    <ButtonBackground
                      className={styles['session-background']}
                    />
                    <div className={styles['border']} />
                    <span>{title}</span>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default MobileMap;
