import React from 'react';

// For reference: https://kentcdodds.com/blog/how-to-use-react-context-effectively

const AuthenticationContext = React.createContext({
  user: undefined,
  data: undefined,
  dataRef: undefined
});

const AuthenticationProvider = ({ user, data, dataRef, children }) => {
  return (
    <AuthenticationContext.Provider value={{ user, data, dataRef }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const useIsAuthenticated = () => {
  const { user } = React.useContext(AuthenticationContext);
  return !!user;
};

const useAuthenticationData = () => {
  const authenticationData = React.useContext(AuthenticationContext);
  // if (!authenticationData) {
  //   throw new Error(
  //     `useAuthentication must be used within a AuthenticationProvider`
  //   );
  // }
  return authenticationData;
};

export { AuthenticationProvider, useIsAuthenticated, useAuthenticationData };
