import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import { getAnswerData } from 'utils/data';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const buildIsMultiselectOptionSelected = (data, questionKey) => (optionKey) =>
  !!getAnswerData(data, questionKey)[optionKey]?.selected;

const PostEval = () => {
  const { data } = useAuthenticationData();

  const { isCompleted = false } = data?.[REF_KEY_BASE] || {};

  const isTobaccoProductSelected = buildIsMultiselectOptionSelected(
    data,
    `${REF_KEY_BASE}/tobaccoTypes`
  );

  const hasQuit =
    getAnswerData(data, `${REF_KEY_BASE}/haveYouQuit`)?.isCompleted &&
    getAnswerData(data, `${REF_KEY_BASE}/haveYouQuit`)?.selectedValue === 'yes';
  const hasNotQuit =
    getAnswerData(data, `${REF_KEY_BASE}/haveYouQuit`)?.isCompleted &&
    getAnswerData(data, `${REF_KEY_BASE}/haveYouQuit`)?.selectedValue === 'no';

  const isECigOtherSelected = buildIsMultiselectOptionSelected(
    data,
    `${REF_KEY_BASE}/eCigBrands`
  )('other');

  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Staying On Course"
      subTitle="Survey"
      Background={null}
    >
      <Section>
        <p>
          Congratulations on completing the NOT for Me program! As part of this
          program, you have tracked your journey towards quitting consistently.
          The questions below address your habits and attitudes towards tobacco
          use now, after you’ve completed the program. Your responses will help
          the American Lung Association evaluate the program success with all
          teens who use it. For privacy, your name will never be connected to
          the evaluation results.
        </p>
      </Section>
      <Section>
        <AbstractQuestion refKey={'haveYouQuit'} />

        {hasQuit && <AbstractQuestion refKey={'howManyDays'} />}
        {hasNotQuit && (
          <>
            <AbstractQuestion refKey={'tobaccoTypes'} />
            {isTobaccoProductSelected('other') && (
              <AbstractQuestion refKey={'otherTobaccoType'} />
            )}

            <AbstractQuestion refKey={'flavoredTobacco'} />

            {isTobaccoProductSelected('eCigarettes') && (
              <>
                <AbstractQuestion refKey={'eCigTypes'} />
                <AbstractQuestion refKey={'eCigBrands'} />
              </>
            )}
            {isTobaccoProductSelected('eCigarettes') && isECigOtherSelected && (
              <AbstractQuestion refKey={'eCigOtherBrand'} />
            )}

            {isTobaccoProductSelected('eCigarettes') && (
              <AbstractQuestion refKey={'eCigUsageDays'} />
            )}
            {isTobaccoProductSelected('cigarettes') && (
              <AbstractQuestion refKey={'cigaretteUsageDays'} />
            )}
            {isTobaccoProductSelected('smokeless') && (
              <AbstractQuestion refKey={'smokelessUsageDays'} />
            )}
            {isTobaccoProductSelected('cigars') && (
              <AbstractQuestion refKey={'cigarUsageDays'} />
            )}
            {isTobaccoProductSelected('hookah') && (
              <AbstractQuestion refKey={'hookahUsageDays'} />
            )}

            {(isTobaccoProductSelected('cigarettes') ||
              isTobaccoProductSelected('cigars')) && (
              <AbstractQuestion refKey={'smokePerDay'} />
            )}

            {isTobaccoProductSelected('eCigarettes') && (
              <AbstractQuestion refKey={'eCigPerDay'} />
            )}

            {isTobaccoProductSelected('smokeless') && (
              <AbstractQuestion refKey={'smokelessPerDay'} />
            )}
            <AbstractQuestion refKey={'howSoonAfterWaking'} />
          </>
        )}

        <AbstractQuestion refKey={'anyoneInLifeTobacco'} />
        <AbstractQuestion refKey={'reachingForTobacco'} />
        <AbstractQuestion refKey={'outOfMyWay'} />
        <AbstractQuestion refKey={'moreBeforeDisallowed'} />
        <AbstractQuestion refKey={'intolerableCravings'} />

        {hasQuit && (
          <>
            <AbstractQuestion refKey={'motivationToStayOff'} />
            <AbstractQuestion refKey={'confidenceToStayOff'} />
          </>
        )}

        {hasNotQuit && (
          <>
            <AbstractQuestion refKey={'motivationToStop'} />
            <AbstractQuestion refKey={'confidenceToStop'} />
          </>
        )}

        <AbstractQuestion refKey={'satisfiedWithProgram'} />
      </Section>
      <Section>
        <p>
          Rate how meaningful the following program components were during your
          quit journey?
        </p>

        <AbstractQuestion refKey={'meaningfulVideos'} />
        <AbstractQuestion refKey={'meaningfulVideosTellUsMore'} />

        <AbstractQuestion refKey={'meaningfulAffirmations'} />
        <AbstractQuestion refKey={'meaningfulAffirmationsTellUsMore'} />

        <AbstractQuestion refKey={'meaningfulRelaxationActivities'} />
        <AbstractQuestion refKey={'meaningfulRelaxationActivitiesTellUsMore'} />

        <AbstractQuestion refKey={'meaningfulExploreActivities'} />
        <AbstractQuestion refKey={'meaningfulExploreActivitiesTellUsMore'} />

        <AbstractQuestion refKey={'meaningfulGoalSettingQuestions'} />
        <AbstractQuestion refKey={'meaningfulGoalSettingQuestionsTellUsMore'} />

        <AbstractQuestion refKey={'meaningfulNicChecks'} />
        <AbstractQuestion refKey={'meaningfulNicChecksTellUsMore'} />

        <AbstractQuestion refKey={'meaningfulJournal'} />
        <AbstractQuestion refKey={'meaningfulJournalTellUsMore'} />

        <AbstractQuestion refKey={'wouldReferBack'} />
        <AbstractQuestion refKey={'returnReasons'} />
        <AbstractQuestion refKey={'feelToTakeOnOwn'} />
        <AbstractQuestion refKey={'feelToTakeOnline'} />
        <AbstractQuestion refKey={'relatableToIdentity'} />
        <AbstractQuestion refKey={'wouldRecommendToFriend'} />
        <AbstractQuestion refKey={'recommendToFriendReasons'} />
        <AbstractQuestion refKey={'whatWouldYouImprove'} />
      </Section>
      <Section>
        <p>
          You did it! You have completed the NOT for Me program! You should be
          proud of every step you took to become and remain tobacco-free. You
          can log back in to access any of the sessions or content if you need
          reminders or support in the future. Click here to download your
          certificate of completion.
        </p>
        {!isCompleted && (
          <p className="error">
            Please answer all questions above to continue to next page.
          </p>
        )}
      </Section>
    </Layout>
  );
};

export default PostEval;
