import React, { useState, useRef, useEffect } from 'react';
import styles from './Dropdown.module.css';

const Dropdown = ({
  options: baseOptions,
  value,
  onSelect,
  allowUnselected = false
}) => {
  // add an initial "unselected" choice that disappears once one has been selected
  const options =
    value === ''
      ? [
          {
            value: '',
            display: ''
          },
          ...baseOptions
        ]
      : baseOptions;
  return (
    <select value={value} onChange={(e) => onSelect(e.target.value)}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.display}
        </option>
      ))}
    </select>
  );
};

export type DropdownOption = {
  value: string;
  display: React.ReactChild;
};

export const ImageDropdown = ({
  id,
  options,
  value,
  onSelect
}: {
  id: string;
  options: DropdownOption[];
  value: any;
  onSelect: any;
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const displayValue = value ? (
    options.find((option) => option.value === value)?.display
  ) : (
    <div></div>
  );

  return (
    <div ref={wrapperRef} className={styles['wrapper']}>
      <button
        id={id}
        onClick={() => setShowOptions(!showOptions)}
        className={[styles['option'], styles['selected']].join(' ')}
      >
        {displayValue}
      </button>
      <div
        className={
          showOptions
            ? styles['options']
            : [styles['options'], styles['hidden']].join(' ')
        }
      >
        {options.map(({ display, value }, i) => (
          <div
            key={i}
            className={styles['option']}
            onClick={() => {
              onSelect(value);
              setShowOptions(false);
            }}
          >
            {display}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
