// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'sessionFourInteractive';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  healing: {
    questionType: 'postItMultipleSelect',
    question: undefined,
    answers: [
      {
        refKey: 'feelingGrouchy',
        header: 'Feeling grouchy?',
        description:
          'Nicotine is addictive. When you quit, you are taking away the “fix” and you may be grouchy.',
        answers: [
          {
            label: 'Set a timer on your phone to meditate daily',
            value: 'meditationTimer'
          },
          {
            label: 'Go for a walk with a friend',
            value: 'walkWithFriend'
          },
          {
            label: 'Take a hot bath or shower',
            value: 'bath'
          }
        ]
      },
      {
        refKey: 'feelingTired',
        header: 'Feeling tired?',
        description:
          'Nicotine increases your metabolism, and quitting will cause it to go back to normal. So, your energy level may drop until your body adjusts.',
        answers: [
          {
            label: 'Take a power nap',
            value: 'powerNap'
          },
          {
            label:
              'Put a note near your bed as a reminder for morning stretches',
            value: 'morningStretches'
          },
          {
            label:
              'Put a note near your desk as a reminder to take stand breaks',
            value: 'standBreaks'
          },
          {
            label: 'Set a reminder on your phone to exercise daily',
            value: 'exerciseDaily'
          }
        ]
      },
      {
        refKey: 'cantSleep',
        header: 'Can’t sleep?',
        description:
          'Your body is still craving the nicotine that was in your body so your body is not letting you relax.',
        answers: [
          {
            label:
              'Put a sign on the fridge as a reminder to NOT drink caffeine after 6 pm',
            value: 'noCaffeine'
          },
          {
            label: 'Sip herbal tea instead of coffee',
            value: 'herbalTea'
          },
          {
            label: 'Practice deep breathing',
            value: 'deepBreathing'
          },
          {
            label: 'Download a free relaxation app',
            value: 'relaxationApp'
          }
        ]
      },
      {
        refKey: 'dryThroat',
        header: 'Cough, dry throat, runny nose, coughing with phlegm?',
        description:
          'When you quit, your body begins to repair itself by cleaning the tar and nicotine from your lungs.',
        answers: [
          {
            label: 'Drink water (every hour)',
            value: 'waterHourly'
          },
          {
            label: 'Download a free water drinking reminder app',
            value: 'waterReminderApp'
          },
          {
            label:
              'Keep hard candy or cough drops where you used to keep your tobacco products',
            value: 'coughDrops'
          },
          {
            label: 'Keep tissues nearby',
            value: 'tissues'
          }
        ]
      },
      {
        refKey: 'cantConcentrate',
        header: 'Can’t concentrate?',
        description: 'Nicotine affects a smoker’s concentration.',
        answers: [
          {
            label: 'Let teachers know you might need extra time to focus',
            value: 'notifyTeachers'
          },
          {
            label: 'Rearrange your space to avoid triggers',
            value: 'rearrangeSpace'
          },
          {
            label: 'Take a quick walk outside',
            value: 'quickWalk'
          },
          {
            label: 'Meditate to clear your head',
            value: 'meditate'
          }
        ]
      },
      {
        refKey: 'constipation',
        header: 'Constipation, gas, stomach pain?',
        description:
          'Nicotine increases intestinal movements because it is a stimulant. Without nicotine, your body has to adjust to working without the drug.',
        answers: [
          {
            label: 'Eat fruits, veggies and whole grain cereals',
            value: 'eatHealthy'
          },
          {
            label: 'Drink lots of water',
            value: 'drinkWater'
          },
          {
            label: 'Look up healthy, fiber-filled recipes online',
            value: 'fiberRecipes'
          },
          {
            label: 'Keep a water bottle nearby',
            value: 'waterBottle'
          }
        ]
      },
      {
        refKey: 'feelingHungry',
        header: 'Feeling hungry?',
        description: 'Nicotine dulls appetite.',
        answers: [
          {
            label:
              'Eat healthy snacks like pretzels, fruit, yogurt, or popcorn',
            value: 'healthySnacks'
          },
          {
            label: 'Drink water before you eat',
            value: 'waterBeforeEating'
          },
          {
            label: 'Cook something new and healthy',
            value: 'cookSomething'
          },
          {
            label: 'Play a game',
            value: 'game'
          }
        ]
      },
      {
        refKey: 'cravingTobacco',
        header: 'Craving tobacco?',
        description:
          'Your body is dependent on nicotine, and the craving is your body wanting a nic-fix.',
        answers: [
          {
            label:
              'Make signs to remind yourself these feelings will pass soon',
            value: 'feelingsPass'
          },
          {
            label: 'Text or call supportive friends',
            value: 'callFriends'
          },
          {
            label: 'Say encouraging words to yourself out loud',
            value: 'encouragingWords'
          },
          {
            label: 'Take care of a plant or pet',
            value: 'plantOrPet'
          }
        ]
      }
    ]
  },
  motivation: {
    questionType: 'motivationLikert',
    question:
      'How would you rate your motivation to stop using tobacco products right now?'
  },
  confidence: {
    questionType: 'confidenceLikert',
    question:
      'How would you rate your confidence in stopping use of tobacco products right now?'
  },
  savings: {
    questionType: 'shortText',
    question:
      'From "Not Using Makes Cents", how much money did you calculate that you might save from not using tobacco?'
  },
  useMoney: {
    questionType: 'shortText',
    question: `What will you do with the money that you'll save?`
  },
  rewards: {
    questionType: 'shortText',
    question:
      'Plan  rewards for the money you will save.  Name 2 rewards you look forward to?'
  },
  escapePlan: {
    questionType: 'shortText',
    question:
      'Don’t forget about your escape plan. Name one situation where you might need an escape plan. What is your plan?'
  },
  exercise: {
    questionType: 'shortText',
    question:
      'Do at least 15 minutes of physical activity each day this week (next 7 days). What types of physical activity will you try?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
