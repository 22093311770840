import { Text } from '@react-pdf/renderer';

import Session from '../../components/Session';
import Section from '../../components/Section';
import SessionSummary from '../../components/SessionSummary';
import MultiselectDisplay from '../../components/MultiselectDisplay';
import LikertPairDisplay from '../../components/LikertPairDisplay';
import RelaxationHub from '../../components/RelaxationHub';
import SayItLoud from '../../components/SayItLoud';

import SESSION_EIGHT_QUESTIONS from 'pages/SessionEight/data';

const SessionEight = ({ data }) => {
  const {
    descriptionBefore,
    descriptionAfter,
    characteristics,
    motivation,
    confidence,
    goalReward,
    goalMeditation,
    goalWhatWillYouTellThem,
    goalExercise
  } = data;
  return (
    <Session>
      <SessionSummary
        header="Session 8: Staying on Course"
        bullets={[
          'Understanding the physical and psychological changes that come with quitting tobacco',
          'Learning healthy ways to respond to physical and emotional stress',
          'Finding ways to celebrate your successes'
        ]}
      />
      <Section header="Explore More">
        {descriptionBefore?.isCompleted && (
          <>
            <Text>
              Here are some of the ways I'd describe myself as a tobacco user
              before starting this program:
            </Text>
            <Text>{descriptionBefore.value}</Text>
          </>
        )}
        {descriptionAfter?.isCompleted && (
          <>
            <Text>
              Here's how I would describe myself after going through the
              program:
            </Text>
            <Text>{descriptionAfter.value}</Text>
          </>
        )}
        {characteristics?.isCompleted && (
          <>
            <Text>“Self Portrait”</Text>
            <Text>
              These are some of the positive changes I’ve seen since going
              through the NOT for Me program:
            </Text>
            <MultiselectDisplay
              question={SESSION_EIGHT_QUESTIONS.characteristics}
              data={characteristics}
            />
          </>
        )}
      </Section>
      <Section header="Committed to Quit">
        <LikertPairDisplay motivation={motivation} confidence={confidence} />
      </Section>
      <Section header="My Goals">
        {goalReward?.isCompleted && (
          <>
            <Text>The reward I'm giving myself this week:</Text>
            <Text>{goalReward.value}</Text>
          </>
        )}
        {goalMeditation?.isCompleted && (
          <>
            <Text>
              The rlaxation exercise I will practice at least once a week is:
            </Text>
            <Text>{goalMeditation.value}</Text>
          </>
        )}
        {goalWhatWillYouTellThem?.isCompleted && (
          <>
            <Text>I will tell a friend about these positive life changes:</Text>
            <Text>{goalWhatWillYouTellThem.value}</Text>
          </>
        )}
        {goalExercise?.isCompleted && (
          <>
            <Text>
              Here are how my exercise habits have changed since starting NOT
              for Me:
            </Text>
            <Text>{goalExercise.value}</Text>
          </>
        )}
      </Section>
      <Section header="My Reminders">
        <Text>
          Remember to track my tobacco use with Nic Check if I slip. Check out
          the Relaxation Center and additional resources on the Info Hub.
        </Text>
        <RelaxationHub
          bullets={[
            'My Signs of Healing',
            'Stretch Time',
            'Snack Time',
            'Healthier Food Alternatives',
            'Healthy Habits',
            'Tips for Staying on Track',
            'Celebrate Every Little Step'
          ]}
        />
      </Section>
      <SayItLoud phrase="Tobacco is NOT for Me!" />
      <Section>
        <Text>
          Congratulations on completing the 8 sessions of NOT for Me! You can
          log in and revisit any sessions at any time to help boost your quit
          support. You can also save or print your Journey Journal to review the
          skills and information you’ve learned throughout your journey. On
          behalf of the American Lung Association, we wish you much success in
          your future being tobacco free!
        </Text>
      </Section>
    </Session>
  );
};

export default SessionEight;
