import { REF_KEY_BASE as SESSION_ONE_REF_KEY_BASE } from 'pages/SessionOne/data';
import { REF_KEY_BASE as SESSION_TWO_REF_KEY_BASE } from 'pages/SessionTwo/data';
import { REF_KEY_BASE as SESSION_THREE_REF_KEY_BASE } from 'pages/SessionThree/data';
import { REF_KEY_BASE as SESSION_FOUR_REF_KEY_BASE } from 'pages/SessionFour/data';
import { REF_KEY_BASE as SESSION_FIVE_REF_KEY_BASE } from 'pages/SessionFive/data';
import { REF_KEY_BASE as SESSION_SIX_REF_KEY_BASE } from 'pages/SessionSix/data';
// import { REF_KEY_BASE as SESSION_SEVEN_REF_KEY_BASE } from 'pages/SessionSeven/data';
import { REF_KEY_BASE as SESSION_EIGHT_REF_KEY_BASE } from 'pages/SessionEight/data';

const RELAXATION_RESOURCES = [
  {
    id: 'breathing1',
    resourceDescription: 'Deep Breathing',
    resourceType: 'audio',
    resourceLink: '/info_hub/relaxation_center/deep_breathing.mp3'
  },
  {
    id: 'breathing2',
    resourceDescription: 'Progressive Muscle Relaxation',
    resourceType: 'audio',
    resourceLink: '/info_hub/relaxation_center/progressive_muscle_relaxation.mp3'
  },
  {
    id: 'breathing3',
    resourceDescription: 'Safe Space-Mental Vacation',
    resourceType: 'audio',
    resourceLink: '/info_hub/relaxation_center/safe_space_mental_vacation.mp3'
  },
  {
    id: 'breathing4',
    resourceDescription: 'Temper Tantrum Exercise',
    resourceType: 'audio',
    resourceLink: '/info_hub/relaxation_center/temper_tantrum_exercise.mp3'
  }
];

const SESSION_ONE_RESOURCES = [
  {
    id: 'fact-or-fiction',
    resourceDescription: 'Fact or Fiction',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/e89095e5-577e-4c99-bd31-2f74fb42d7b1/FACT-or-FICTION.pdf',
      preview: '/info_hub/session_1/FACT-or-FICTION-preview.jpg'
    // preview: factOrFictionPreview
  },
  {
    id: 'tobacco-products-overview',
    resourceDescription: 'Tobacco Products Overview',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/41557ae5-91db-4dd2-81ac-b8e398dac44a/Tobacco-Product-Overviews.pdf',
      preview: '/info_hub/session_1/Tobacco-Product-Overview-preview.jpg'
      // preview: tobaccoProductsPreview
  }
];

const SESSION_TWO_RESOURCES = [
  {
    id: 'exercising-my-rights',
    resourceDescription: 'Exercising My Rights',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/87d3bbc8-f2c9-47da-aaae-3ef7c309aa4a/Exercising-My-Rights.pdf',
      preview: '/info_hub/session_2/Exercising-My-Rights-preview.jpg'
  },
  {
    id: 'keep-on-moving',
    resourceDescription: 'Keep On Moving',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/07bdbc25-4977-465a-889d-fb4fb651277e/Keep-on-Moving.pdf',
      preview: '/info_hub/session_2/Keep-on-Moving-preview.jpg'
  },
  {
    id: 'nicotine-trap',
    resourceDescription: 'Nicotine Trap',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/6f281f9e-2eb9-4822-9af0-075d44bbac92/Nicotine-Trap.pdf',
      preview: '/info_hub/session_2/Nicotine-Trap-preview.jpg'
  }
];

const SESSION_THREE_RESOURCES = [
  {
    id: 'the-body-destroyer',
    resourceDescription: 'The Body Destroyer',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/f4388411-f7a2-48f4-9bdf-0cb8a81bd7eb/The-Body-Destroyer.pdf',
      preview: '/info_hub/session_3/The-Body-Destroyer-preview.jpg'
  },
  {
    id: 'making-my-space-tobacco-free',
    resourceDescription: 'Making My Space Tobacco-free',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/8c11c0f6-d7a0-418e-9802-e03d9e5d3f14/Making-My-Space-Tobacco-Free.pdf',
      preview: '/info_hub/session_3/Making-My-Space-Tobacco-Free-preview.jpg'
  },
  {
    id: 'so-now-what',
    resourceDescription: 'So Now What?',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/aa617703-5157-4733-9b7c-3148b1208885/So-Now-What.pdf',
      preview: '/info_hub/session_3/So-Now-What-preview.jpg'
  },
  {
    id: 'rocks-that-dont-roll',
    resourceDescription: `Rocks That Don't Roll`,
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/539920d4-a4a0-4293-a66b-dac5bc967619/Rocks-That-Dont-Roll.pdf',
      preview: '/info_hub/session_3/Rocks-That-Dont-Roll-preview.jpg'
  },
  {
    id: 'for-my-support-person',
    resourceDescription: 'For My Support Person',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/92078229-06bb-4b79-97c2-3acd801e7ad7/For-My-Support-Person.pdf',
      preview: '/info_hub/session_3/For-My-Support-Person-preview.jpg'
  },
  {
    id: 'nic-check-understanding-my-use',
    resourceDescription: 'Nic-Check - Understanding My Use',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/35ba0af3-bfba-479d-8f7c-21a6c466ac41/Nic-Check-Understanding-my-use.pdf',
      preview: '/info_hub/session_3/Nic-Check-Understanding-my-use-preview.jpg'
  }
];

const SESSION_FOUR_RESOURCES = [
  // {
  //   id: 'exercising-my-rights',
  //   resourceDescription: 'Exercising My Rights',
  //   resourceType: 'pdf',
  //   resourceLink:
  //     'https://www.lung.org/getmedia/87d3bbc8-f2c9-47da-aaae-3ef7c309aa4a/Exercising-My-Rights.pdf',
  //   preview: tobaccoProductsPreview
  // },
  // {
  //   id: 'keep-on-moving',
  //   resourceDescription: 'Keep on Moving',
  //   resourceType: 'pdf',
  //   resourceLink:
  //     'https://www.lung.org/getmedia/07bdbc25-4977-465a-889d-fb4fb651277e/Keep-on-Moving.pdf',
  //   preview: tobaccoProductsPreview
  // },
  {
    id: 'my-signs-of-healing',
    resourceDescription: 'My Signs of Healing',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/2c2d8963-95ad-415e-af19-914439b1020e/My-Signs-of-Healing.pdf',
      preview: '/info_hub/session_4/My-Signs-of-Healing-preview.jpg'
  },
  {
    id: 'making-cents-cost-calculator',
    resourceDescription: 'Making Cents Cost Calculator',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/98dce681-d147-448e-afea-eb7c1610b0b4/Making-Cents-Cost-Calculator.pdf',
      preview: '/info_hub/session_4/Making-Cents-Cost-Calculator-preview.jpg'
  },
  {
    id: 'my-quit-plan-checklist',
    resourceDescription: 'My Quit Plan Checklist',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/0d902c42-8d8f-48ed-882d-3a70d3b092f4/My-Quit-Plan-Checklist.pdf',
      preview: '/info_hub/session_4/My-Quit-Plan-Checklist-preview.jpg'
  }
];

const SESSION_FIVE_RESOURCES = [
  {
    id: 'dealing-with-urges-and-cravings',
    resourceDescription: 'Dealing with Urges and Cravings',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/82386370-504f-416f-a067-7fc97a2eb90d/Dealing-with-Urges-and-Cravings.pdf',
    preview: '/info_hub/session_5/Dealing-with-Urges-and-Cravings-preview.jpg'
  },
  {
    id: 'my-rewards-list',
    resourceDescription: 'My Rewards List',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/666830fa-f84b-455e-afc3-2c9073e4eca4/My-Rewards-List.pdf',
    preview: '/info_hub/session_5/My-Rewards-List-preview.jpg'
  }
];

const SESSION_SIX_RESOURCES = [
  {
    id: 'knowing-what-you-need-from-others',
    resourceDescription: 'Knowing What You Need From Others',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/e835e026-fd41-4a46-8cb9-f37b7cc931cc/Knowing-What-You-Need-From-Others.pdf',
    preview: '/info_hub/session_6/Knowing-What-You-Need-From-Others-preview.jpg'
  },
  {
    id: 'strategy-card',
    resourceDescription: 'Strategy Cards',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/6bde95d3-aca0-46a4-8729-595c3db60b42/Strategy-Card.pdf',
    preview: '/info_hub/session_6/Strategy-Card-preview.jpg'
  }
];

// const SESSION_SEVEN_RESOURCES = [
//   // none
// ];

const SESSION_EIGHT_RESOURCES = [
  {
    id: 'my-signs-of-healing',
    resourceDescription: 'My Signs of Healing',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/2c2d8963-95ad-415e-af19-914439b1020e/My-Signs-of-Healing.pdf',
    preview: '/info_hub/session_8/My-Signs-of-Healing-preview.jpg'
  },
  {
    id: 'stretch-time',
    resourceDescription: 'Stretch Time',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/93970cd1-5c43-4b5b-a2d0-301364a6b4f3/Stretch-Time.pdf',
    preview: '/info_hub/session_8/Stretch-Time-preview.jpg'
  },
  {
    id: 'snack-time',
    resourceDescription: 'Snack Time',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/32b0b49a-0022-4665-b2b6-5fad193904fd/Snack-Time.pdf',
    preview: '/info_hub/session_8/Snack-Time-preview.jpg'
  },
  {
    id: 'healthier-food-alternatives',
    resourceDescription: 'Healthier Food Alternatives',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/d43da08c-3f2c-43fe-8b67-2d4bc5b481b9/Healthy-Food-Alternatives.pdf',
    preview: '/info_hub/session_8/Healthier-Food-Alternatives-preview.jpg'
  },
  {
    id: 'healthy-habits',
    resourceDescription: 'Healthy Habits',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/a7bc8a24-a3e7-4c2e-a26f-3618c3ecf134/Healthy-Habits.pdf',
    preview: '/info_hub/session_8/Healthy-Habits-preview.jpg'
  },
  {
    id: 'staying-on-track',
    resourceDescription: 'Tips for Staying on Track',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/b2288c2d-dac9-41fc-b2be-3d475e638a62/Staying-on-Track.pdf',
    preview: '/info_hub/session_8/Staying-on-Track-preview.jpg'
  },
  {
    id: 'celebrate-every-little-step',
    resourceDescription: 'Celebrate Every Little Step',
    resourceType: 'pdf',
    resourceLink:
      'https://www.lung.org/getmedia/0aa9d31f-3204-4d0b-9803-81183106e98d/Celebrate-Every-Little-Step.pdf',
    preview: '/info_hub/session_8/Celebrate-Every-Little-Step-preview.jpg'
  }
];

const INFO_HUB_DATA = [
  {
    id: 'relaxation',
    sectionHeader: 'Relaxation Center',
    resources: RELAXATION_RESOURCES
  },
  {
    key: SESSION_ONE_REF_KEY_BASE,
    id: 'session-one',
    sectionHeader: 'Session 1',
    buttonLink: '/session-one-interactive',
    resources: SESSION_ONE_RESOURCES
  },
  {
    key: SESSION_TWO_REF_KEY_BASE,
    id: 'session-two',
    sectionHeader: 'Session 2',
    buttonLink: '/session-two-interactive',
    resources: SESSION_TWO_RESOURCES
  },
  {
    key: SESSION_THREE_REF_KEY_BASE,
    id: 'session-three',
    sectionHeader: 'Session 3',
    buttonLink: '/session-three-interactive',
    resources: SESSION_THREE_RESOURCES
  },
  {
    key: SESSION_FOUR_REF_KEY_BASE,
    id: 'session-four',
    sectionHeader: 'Session 4',
    buttonLink: '/session-four-interactive',
    resources: SESSION_FOUR_RESOURCES
  },
  {
    key: SESSION_FIVE_REF_KEY_BASE,
    id: 'session-five',
    sectionHeader: 'Session 5',
    buttonLink: '/session-five-interactive',
    resources: SESSION_FIVE_RESOURCES
  },
  {
    key: SESSION_SIX_REF_KEY_BASE,
    id: 'session-six',
    sectionHeader: 'Session 6',
    buttonLink: '/session-six-interactive',
    resources: SESSION_SIX_RESOURCES
  },
  {
    key: SESSION_EIGHT_REF_KEY_BASE,
    id: 'session-eight',
    sectionHeader: 'Session 8',
    buttonLink: '/session-eight-interactive',
    resources: SESSION_EIGHT_RESOURCES
  }
];

export default INFO_HUB_DATA;