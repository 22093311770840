import { useState } from 'react';
import Modal from 'components/Modal';
import Dropdown from './components/Dropdown';
import { ImageDropdown } from './components/Dropdown';

// seriously? https://github.com/wojtekmaj/react-time-picker/issues/92
import TimePicker from 'react-time-picker';
import DatePicker from 'react-date-picker';
import moment from 'moment';

import Button from 'components/Button';

import styles from './NicCheckModal.module.css';

import medium from 'assets/icons/medium.png';
import none from 'assets/icons/none.png';
import sad from 'assets/icons/sad.png';
import bored from 'assets/icons/bored.png';

import { useNicCheck } from 'components/NicCheckContext';
import {
  useAuthenticationData,
  useIsAuthenticated
} from 'components/AuthenticationContext';
import Collapsible from './components/Collapsible';

const feelingOptions = [
  {
    value: 'good',
    display: <img src={medium} alt="" />
    // display: 'good'
  },
  {
    value: 'okay',
    display: <img src={none} alt="" />
  },
  {
    value: 'bad',
    display: <img src={sad} alt="" />
  }
];

const usageNeedOptions = [
  { value: 'really', display: 'really needed' },
  { value: 'kindof', display: 'kind of needed' },
  { value: 'didnt', display: "didn't really need" }
];

const productOptions = [
  { value: 'e-cigarettes', display: 'E-cigarettes' },
  { value: 'cigarettes', display: 'Cigarettes' },
  { value: 'smokeless', display: 'Smokeless tobacco' },
  { value: 'cigars', display: 'Cigars' },
  { value: 'hookah', display: 'Hookah tobacco' },
  { value: 'other', display: 'Other' }
];

const getDisplayFromValue = (options, value) =>
  (options.find((entry) => entry.value === value) || { display: '' }).display;

const NicCheckGridHeader = () => (
  <>
    <div className={styles['grid-header-item']}>Date</div>
    <div className={styles['grid-header-item']}>Product</div>
    <div className={styles['grid-header-item']}>Need to Use</div>
    <div className={styles['grid-header-item']}>When I Used I Was...</div>
    <div className={styles['grid-header-item']}>I Felt</div>
  </>
);

const MobileNicCheckGridHeader = () => (
  <>
    <div className={styles['grid-header-item']}>Date</div>
    <div className={styles['grid-header-item']}>I Felt</div>
    <div className={styles['grid-header-item']}></div>
  </>
);

const getIconFromFeelingValue = (value) => {
  if (value === 'good') return medium;
  if (value === 'okay') return none;
  if (value === 'bad') return sad;
  return bored;
};

const NicCheckGridRow = ({
  nicCheck: {
    dateTimeSelected,
    productSelected,
    needSelected,
    informationFreeForm,
    feelingSelected
  }
}) => (
  <>
    <div className={styles['grid-item']}>
      {moment(dateTimeSelected).format('MM/DD/YY')} -{' '}
      {moment(dateTimeSelected).format('h:mm a')}
    </div>
    <div className={styles['grid-item']}>
      {getDisplayFromValue(productOptions, productSelected)}
    </div>
    <div className={styles['grid-item']}>
      {getDisplayFromValue(usageNeedOptions, needSelected)}
    </div>
    <div className={styles['grid-item']}>{informationFreeForm}</div>
    <div className={[styles['grid-item'], styles['last-column']].join(' ')}>
      <img
        className={styles['feeling-image']}
        src={getIconFromFeelingValue(feelingSelected)}
        alt={getDisplayFromValue(feelingOptions, feelingSelected)}
      />
    </div>
  </>
);

const MobileNicCheckGridRow = ({
  nicCheck: {
    dateTimeSelected,
    productSelected,
    needSelected,
    informationFreeForm,
    feelingSelected
  }
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div
        className={styles['grid-item']}
        onClick={() => setExpanded(!expanded)}
      >
        {moment(dateTimeSelected).format('MM/DD/YY')} -{' '}
        {moment(dateTimeSelected).format('h:mm a')}
      </div>
      <div
        className={styles['grid-item']}
        onClick={() => setExpanded(!expanded)}
      >
        <img
          className={styles['feeling-image']}
          src={getIconFromFeelingValue(feelingSelected)}
          alt={getDisplayFromValue(feelingOptions, feelingSelected)}
        />
      </div>
      <div
        className={[styles['grid-item'], styles['last-column']].join(' ')}
        onClick={() => setExpanded(!expanded)}
      >
        <div
          className={
            expanded
              ? [styles['caret'], styles['expanded']].join(' ')
              : styles['caret']
          }
        />
      </div>
      <div
        className={
          expanded
            ? styles['expanded-row']
            : [styles['expanded-row'], styles['hidden']].join(' ')
        }
      >
        <div className={styles['expanded-grid-item']}>Product</div>
        <div className={styles['expanded-grid-item']}>
          {getDisplayFromValue(productOptions, productSelected)}
        </div>
        <div className={styles['expanded-grid-item']}>Need to Use</div>
        <div className={styles['expanded-grid-item']}>
          {getDisplayFromValue(usageNeedOptions, needSelected)}
        </div>
        <div className={styles['expanded-grid-item']}>When I Used I Was...</div>
        <div className={styles['expanded-grid-item']}>
          {informationFreeForm}
        </div>
      </div>
    </>
  );
};

const NicCheckGrid = ({ nicChecks }) => {
  return (
    <>
      <div className={[styles['grid'], 'desktop-only'].join(' ')}>
        <NicCheckGridHeader />
        {nicChecks
          .sort((a, b) => a.dateTimeSelected > b.dateTimeSelected)
          .map((nicCheck, i) => (
            <NicCheckGridRow key={i} nicCheck={nicCheck} />
          ))}
      </div>

      <div className={[styles['grid'], 'mobile-only'].join(' ')}>
        <MobileNicCheckGridHeader />
        {nicChecks
          .sort((a, b) => a.dateTimeSelected > b.dateTimeSelected)
          .map((nicCheck, i) => (
            <MobileNicCheckGridRow key={i} nicCheck={nicCheck} />
          ))}
      </div>
    </>
  );
};

const NicCheckInfo = ({ initialState }) => {
  const [informationCollapsed, setInformationCollapsed] = useState(
    initialState
  );

  return (
    <div className={styles['check-info']}>
      <div className={styles['form-section']}>
        <Collapsible
          header={`How much do you know about your tobacco use behavior?`}
          collapsed={informationCollapsed}
          setCollapsed={setInformationCollapsed}
        >
          <div>
            <p>Using this tool can help you:</p>
            <ul className={styles['reminder']}>
              <li>think about when and where you use</li>
              <li>how much you feel you need to use in the moment</li>
              <li>and how you're feeling when you're using tobacco products</li>
            </ul>
          </div>

          <div>
            <p>A reminder about the different types of tobacco product:</p>
            <ul className={styles['reminder']}>
              <li>
                E-cigarettes (including vapes, vape pens, hookah pens,
                e-hookahs, e-cigars, e-pipes, disposables, pods, and mods)
              </li>
              <li>Cigarettes</li>
              <li>
                Smokeless tobacco (including dip, snuff, snus, and chewing
                tobacco)
              </li>
              <li>Cigars (including cigarillos and little cigars)</li>
              <li>Hookah tobacco (including waterpipe and shisha tobacco)</li>
              <li>
                Other (i.e. nicotine gel, bidis, loose tobacco, dissolvables)
              </li>
            </ul>
          </div>
        </Collapsible>
      </div>
    </div>
  );
};

const NicCheck = ({ data, dataRef }) => {
  const getNicCheckData = () => {
    return data ? data.nicCheck : {};
  };

  const getNicCheckSubmissions = () => {
    const nicCheck = getNicCheckData();
    if (!nicCheck || !nicCheck.submissions) {
      return [];
    }
    return nicCheck.submissions;
  };

  const [productSelected, setProductSelected] = useState('');
  const [timeSelected, setTimeSelected] = useState(
    moment(new Date()).format('hh:mm')
  );
  const [dateSelected, setDateSelected] = useState(new Date());
  const [informationFreeForm, setInformationFreeForm] = useState('');
  const [needSelected, setNeedSelected] = useState('');
  const [feelingSelected, setFeelingSelected] = useState('');
  const [errorText, setErrorText] = useState(null);

  // const nicCheckDataRef = dataRef.child('nicCheck');
  // const submissionsDataRef = nicCheckDataRef.child('submissions');

  const submitNicCheck = (nicCheckSubmissions) => {
    if (
      !productSelected ||
      !timeSelected ||
      !dateSelected ||
      !informationFreeForm ||
      !needSelected ||
      !feelingSelected
    ) {
      setErrorText(
        "Whoops! Seems like you're missing one of the entries. Please make sure you filled everything out."
      );
      return;
    }

    const parsedTime = moment(timeSelected, 'hh:mm');
    const dateTimeSelected = moment(dateSelected)
      .hour(parsedTime.hour())
      .minute(parsedTime.minute())
      .toDate();

    nicCheckSubmissions.push({
      productSelected,
      dateTimeSelected,
      informationFreeForm,
      needSelected,
      feelingSelected,
      timeSubmitted: new Date()
    });
    // TODO need to sanitize freeform text entries
    dataRef.update({
      'nicCheck/submissions': nicCheckSubmissions
    });
    // submissionsDataRef.push({
    //   productSelected,
    //   timeSelected,
    //   dateSelected,
    //   informationFreeForm,
    //   needSelected,
    //   feelingSelected
    // });

    // TODO should instead set these in a useEffect when the form is opened/after submitting
    setProductSelected('');
    setTimeSelected(moment(new Date()).format('hh:mm'));
    setDateSelected(new Date());
    setInformationFreeForm('');
    setNeedSelected('');
    setFeelingSelected('');
    setErrorText(null);
  };

  const nicCheckSubmissions = getNicCheckSubmissions();

  return (
    <div className={styles['nic-check']}>
      <NicCheckInfo initialState={getNicCheckSubmissions().length > 0} />
      <div className={styles['check-entry']}>
        <div className={styles['form-section']}>
          <span className={styles['form-subsection']}>
            <label htmlFor="product">I used </label>
            <Dropdown
              id="product"
              options={productOptions}
              value={productSelected}
              onSelect={(value) => setProductSelected(value)}
            />
          </span>
          <span className={styles['form-subsection']}>
            <label htmlFor="time"> at </label>
            <TimePicker
              id="time"
              value={timeSelected}
              onChange={(value) => setTimeSelected(value)}
              disableClock={true}
            />
          </span>
          <span className={styles['form-subsection']}>
            <label htmlFor="date"> on </label>
            <DatePicker
              id="date"
              value={dateSelected}
              onChange={(value) => setDateSelected(value)}
              clearIcon={null}
            />
          </span>
        </div>

        <div className={styles['form-section']}>
          <label htmlFor="informationFreeForm">When I used, I was… </label>
          <input
            id="informationFreeForm"
            type="text"
            value={informationFreeForm}
            placeholder="Where were you? What were you doing when you used?"
            onChange={(e) => setInformationFreeForm(e.target.value)}
          />
        </div>

        <div className={styles['form-section']}>
          <label htmlFor="needed">I </label>
          <Dropdown
            id="needed"
            options={usageNeedOptions}
            value={needSelected}
            onSelect={(value) => setNeedSelected(value)}
          />
          <label htmlFor="needed"> to use.</label>
        </div>

        <div className={styles['form-section']}>
          <label htmlFor="feeling">Before I used, I felt </label>
          <ImageDropdown
            id="feeling"
            options={feelingOptions}
            value={feelingSelected}
            onSelect={(value) => setFeelingSelected(value)}
          />
        </div>

        <div className={styles['submit-button-wrapper']}>
          <Button onClick={() => submitNicCheck(nicCheckSubmissions)}>
            Submit Nic Check
          </Button>
        </div>

        <div className="error">{errorText}</div>
      </div>
      <div className={styles['previous-checks']}>
        <p>
          Previously submitted {nicCheckSubmissions.length} Nic Check
          {nicCheckSubmissions.length === 1 ? null : 's'}
        </p>
        <NicCheckGrid nicChecks={nicCheckSubmissions} />
      </div>
    </div>
  );
};

const NicCheckModal = () => {
  const { data, dataRef } = useAuthenticationData();
  const { showNicCheck, setShowNicCheck } = useNicCheck();
  return (
    <Modal
      isOpen={showNicCheck}
      onClose={() => setShowNicCheck(false)}
      title="Nic Check"
    >
      <NicCheck dataRef={dataRef} data={data} />
    </Modal>
  );
};

const NicCheckModalAuthenticationWrapper = () => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? <NicCheckModal /> : null;
};

export default NicCheckModalAuthenticationWrapper;
