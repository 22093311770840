import ReactModal from 'react-modal';

import styles from './Modal.module.css';

ReactModal.setAppElement('#root');

const Modal = ({
  isOpen,
  onClose,
  title,
  modalClass,
  modalTitleClass,
  children
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    className={modalClass || styles['modal']}
    overlayClassName={styles['overlay']}
  >
    <div className={modalTitleClass || styles['title-bar']}>
      {title && <h2 className={styles['title-text']}>{title}</h2>}
      <button className={styles['close-button']} onClick={onClose} />
    </div>
    {children}
  </ReactModal>
);

export default Modal;
