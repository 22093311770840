import { Text } from '@react-pdf/renderer';

import Session from '../../components/Session';
import Section from '../../components/Section';
import SessionSummary from '../../components/SessionSummary';
import MultiselectDisplay from '../../components/MultiselectDisplay';
import NumberedList from '../../components/NumberedList';
import MultilineNumberedList from '../../components/MultilineNumberedList';
import RelaxationHub from '../../components/RelaxationHub';
import SayItLoud from '../../components/SayItLoud';

import SESSION_ONE_QUESTIONS from 'pages/SessionOne/data';

const SessionOne = ({ data }) => {
  const { reasonsIUse, reason1, reason2, reason3, namePerson, quitDate } = data;
  return (
    <Session>
      <SessionSummary
        header="Session 1: Getting the Facts"
        bullets={[
          'Understanding reasons why teens use tobacco',
          'Getting to know the facts and fiction around tobacco use',
          'Identifying all types of tobacco products'
        ]}
      />
      <Section header="Explore More">
        {reasonsIUse?.isCompleted && (
          <>
            <Text style={{ fontWeight: 'bold' }}>
              Some of the reasons why I use tobacco are:
            </Text>
            <MultiselectDisplay
              question={SESSION_ONE_QUESTIONS['reasonsIUse']}
              data={reasonsIUse}
            />
          </>
        )}
        <Text style={{ fontWeight: 'bold' }}>
          The reasons I want to quit using tobacco products are:
        </Text>
        <NumberedList
          items={[reason1, reason2, reason3]
            .filter((reason) => reason?.isCompleted)
            .map(({ value }) => (
              <Text>{value}</Text>
            ))}
        />
        <Text style={{ fontStyle: 'italic' }}>
          Say them out loud every day!
        </Text>
      </Section>

      <Section header="My Goals">
        <MultilineNumberedList
          items={[
            {
              question:
                'The person I will tell about my plan to quit using tobacco is:',
              answer: namePerson?.value
            },
            {
              question: `The date I'm thinking about for my Quit Date is:`,
              answer: quitDate?.value
            }
          ]}
        />
      </Section>
      <Section header="My Reminders">
        <Text style={{ fontWeight: 'bold' }}>
          Remember to track my tobacco use with Nic Check.
        </Text>
        <RelaxationHub
          bullets={['Fact or Fiction', 'Tobacco Product Overviews']}
        />
      </Section>
      <SayItLoud phrase="I have the power to begin my quit journey now!" />
    </Session>
  );
};

export default SessionOne;
