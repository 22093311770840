import Button from 'components/Button';
import Layout from 'components/Layout';
import { useState } from 'react';
import fire from 'utils/fire';

import { ReactComponent as LoadingIndicator } from 'assets/icons/loading_indicator.svg';

import styles from './AdminPortal.module.css';

const MinimalContentWrapper = ({ children }) => <>{children}</>;

const AdminPortal = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  return (
    <Layout
      nextPageButton={null}
      title="Info Hub"
      Background={null}
      ContentWrapper={MinimalContentWrapper}
    >
      <div className={styles['loading-status']}>
        {isLoading && (
          <div className={styles['loading-text']}>
            Generating user data export... <LoadingIndicator />
          </div>
        )}
        {error && (
          <div
            className={[styles['loading-text'], styles['error-text']].join(' ')}
          >
            Error: {error}
          </div>
        )}
      </div>
      <Button
        disabled={isLoading}
        className={styles['export-button']}
        onClick={async () => {
          setLoading(true);
          const token = await fire.auth().currentUser.getIdToken();
          fetch('/api/export', {
            headers: {
              Authorization: token
            }
          })
            .then((res) => res.blob())
            .then((blob) => URL.createObjectURL(blob))
            .then((url) => {
              setLoading(false);
              window.open(url, '_blank');
              URL.revokeObjectURL(url);
            })
            .catch((error) => {
              setLoading(false);
              setError(error);
            });
        }}
      >
        {isLoading ? `Downloading...` : `Export Data`}
      </Button>
    </Layout>
  );
};

export default AdminPortal;
