import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import styles from './Communication.module.css';

const Statement = ({ label, value, onChange }) => {
  const [id] = useState(uuid());

  return (
    <div className={styles['statement']}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        value={value}
        type="text"
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

const keys = ['feel', 'want', 'can', 'need'];

const Communication = ({
  questionData: { question, required = true },
  answerData,
  baseUpdate
}) => {
  const { isCompleted } = answerData;
  const updates = keys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: (val) =>
        baseUpdate(key)(
          val,
          val?.length > 0 &&
            keys
              .filter((otherKey) => otherKey !== key)
              .every((otherKey) => answerData?.[otherKey]?.length > 0)
        )
    };
  }, {});

  return (
    <div className={styles['wrapper']}>
      <label>{question}</label>
      <Statement
        label={'You feel...'}
        value={answerData['feel']}
        onChange={(val) => updates['feel'](val)}
      />
      <Statement
        label={'You want to...'}
        value={answerData['want']}
        onChange={(val) => updates['want'](val)}
      />
      <Statement
        label={'You are asking them to help you...'}
        value={answerData['can']}
        onChange={(val) => updates['can'](val)}
      />
      <Statement
        label={'You need...'}
        value={answerData['need']}
        onChange={(val) => updates['need'](val)}
      />
      <span className={styles.error}>
        {required && !isCompleted ? `Response required` : ' '}
      </span>
    </div>
  );
};

export default Communication;
