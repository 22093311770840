import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { analytics } from 'utils/fire';

const PageTracking = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(({ pathname }, action) => {
      // const { pathname: currentPathname } = history.location;
      // console.log(action, currentPathname, newPathname);
      // if (action === 'PUSH' && currentPathname !== newPathname) {
      //   window.scrollTo(0, 0);
      // }
      if (action === 'PUSH') {
        analytics.logEvent('page_view', { page_path: pathname });
      }
    });
    return () => unlisten();
  }, [history]);
  return null;
};

export default PageTracking;
