import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import InfoHubLink from 'components/InfoHubLink';
import NicCheckLink from 'components/NicCheckLink';
import JournalLink from 'components/JournalLink';

import { isSectionAnswered } from 'utils/progressCalculator';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const Interactive = () => {
  const { data } = useAuthenticationData();
  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          text="Submit Session"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Session 5: Gaining Momentum"
      subTitle="Explore More"
      Background={null}
    >
      <Section>
        <p>
          Let’s go a little further. Your answers on this exploration will be
          saved to your Journey Journal to help you understand your tobacco
          habits and track your progress.
        </p>
      </Section>

      <Section
        title="Seeing It Through: Dealing with Urges and Cravings"
        completed={isSectionAnswered(
          `${REF_KEY_BASE}/cravingAlternatives`,
          data
        )}
      >
        <p>
          When you have an urge to use tobacco, here are some things you can do
          in the moment. Check items listed below that you will try when you
          really want a nicotine fix.
        </p>
        <p>
          Review{' '}
          <InfoHubLink
            section="session-five"
            item="dealing-with-urges-and-cravings"
          >
            Dealing with Urges and Cravings
          </InfoHubLink>{' '}
          for more details.
        </p>
        <AbstractQuestion refKey={'cravingAlternatives'} />
      </Section>

      <Section
        title="Reward Yourself"
        completed={isSectionAnswered(`${REF_KEY_BASE}/rewards`, data)}
      >
        <p>
          Quitting is a big deal. Don’t forget to reward yourself. Everyone has
          different ways of rewarding themselves. Check off some rewards that
          you can give to yourself during your quit journey—some are free and
          some can be bought with the money you save.
        </p>

        <p>
          Look at{' '}
          <InfoHubLink section="session-five" item="my-rewards-list">
            My Rewards List
          </InfoHubLink>{' '}
          for more ideas.
        </p>

        <AbstractQuestion refKey={'rewards'} />
      </Section>

      <Section title="Reflect/Relax">
        <p>
          Remember, relaxation is especially important as your body is going
          through the quitting journey. You can return to the{' '}
          <InfoHubLink section="relaxation">Relaxation hub</InfoHubLink>, any
          time to practice any of these exercises.
        </p>
      </Section>

      <Section
        title="Committed to Quit"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/motivation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/confidence`, data)
        }
      >
        <p>
          Mapping readiness and commitment to quit is proven to help teens quit
          tobacco use. Let’s take a closer look at where you are right now. Your
          answers will be saved in your <JournalLink /> to help you track your
          progress.
        </p>
        <AbstractQuestion refKey={'motivation'} />
        <AbstractQuestion refKey={'confidence'} />
      </Section>

      <Section
        title="Goal Setting"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/goalChampions`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalPepTalk`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalExercise`, data)
        }
      >
        <AbstractQuestion refKey={'goalChampions'} />
        <AbstractQuestion refKey={'goalPepTalk'} />
        <AbstractQuestion refKey={'goalExercise'} />
      </Section>
      <Section title="Nic Check">
        <p>
          Now that you’ve quit, it may help to look closely at your{' '}
          <NicCheckLink /> to see your tobacco use “at a glance”. This can help
          you recognize moods, times of day or urges that you’ll want to prepare
          for to stay tobacco free. If you slip, be sure to document on your{' '}
          <NicCheckLink /> also, so you can develop a plan for how to handle
          similar situations in the future. Your responses will be saved in your{' '}
          <JournalLink />.
        </p>
      </Section>
      <Section title="Info Hub">
        <p>Check out additional resources for this session!</p>
        <p>
          <InfoHubLink
            section="session-five"
            item="dealing-with-urges-and-cravings"
          >
            Dealing with Urges and Cravings
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-five" item="my-rewards-list">
            My Rewards List
          </InfoHubLink>
        </p>
      </Section>

      <Section title="Journey Journal">
        <p>
          <b>You have completed this session.</b> Since this is your quit day,
          we encourage you to come back in 2-3 days for extra support this week.
          You’ll receive a reminder when it’s time to log back in for your next
          session.
        </p>
        <p>
          All of your responses from this session will be saved in your Journey
          Journal. <JournalLink>Click here to review your Journal.</JournalLink>
        </p>
      </Section>
    </Layout>
  );
};

export { Interactive as default, INTERACTIVE_QUESTIONS };
