import { Text } from '@react-pdf/renderer';

import { DEFAULT_MOTIVATION, DEFAULT_CONFIDENCE } from 'components/Likert';

const LikertPairDisplay = ({ motivation, confidence }) => (
  <>
    {motivation?.isCompleted && (
      <>
        <Text style={{ fontWeight: 'bold' }}>
          My motivation to stop using tobacco at this time is:
        </Text>
        <Text>
          {
            DEFAULT_MOTIVATION.find(({ value }) => motivation.value === value)
              .label
          }
        </Text>
      </>
    )}
    {confidence?.isCompleted && (
      <>
        <Text style={{ fontWeight: 'bold' }}>
          My confidence in stopping use of tobacco right now is:
        </Text>
        <Text>
          {
            DEFAULT_CONFIDENCE.find(({ value }) => confidence.value === value)
              .label
          }
        </Text>
      </>
    )}
  </>
);

export default LikertPairDisplay;
