import styles from './Section.module.css';

const Section = ({ title, children }) => (
  <div className={styles['section']}>
    {title && <span className={styles['title']}>{title}</span>}
    {children}
  </div>
);

export default Section;
