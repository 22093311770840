import Layout, { NextPageButton } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';

const Video = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-five-interactive" />}
    title="Session 5: Gaining Momentum"
    subTitle="Video"
  >
    <VideoEmbed videoId="ny4zZetRCY4" title="Session Five Video" />
  </Layout>
);

export default Video;
