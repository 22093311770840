import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';
import InfoHubLink from 'components/InfoHubLink';
import NicCheckLink from 'components/NicCheckLink';
import JournalLink from 'components/JournalLink';

import styles from './Interactive.module.css';

import { isSectionAnswered } from 'utils/progressCalculator';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const Interactive = () => {
  const { data } = useAuthenticationData();
  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          text="Submit Session"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Session 1: Getting the Facts"
      subTitle="Explore More"
      Background={null}
    >
      <Section>
        <p>
          Let’s go a little further. Your answers on this exploration will be
          saved to your Journey Journal to help you understand your tobacco use
          behaviors and track your progress.
        </p>
      </Section>
      <Section
        title="Reasons for Tobacco Use"
        completed={isSectionAnswered(`${REF_KEY_BASE}/reasonsIUse`, data)}
      >
        <p>
          Remember from the video that teens start using tobacco for many
          reasons.
        </p>
        {/* <p className={styles['special-header-2']}>
          What are some of your reasons for vaping/smoking/chewing?
        </p> */}
        <div className={styles['activity-section']}>
          <AbstractQuestion refKey={'reasonsIUse'} />
        </div>
      </Section>

      <Section title="Reflect and Relax">
        <p>Relaxation exercises can help you:</p>
        <ul>
          <li>Improve your chances of successfully quitting tobacco</li>
          <li>Reduce stress</li>
          <li>Be more aware of your tobacco habits and cravings</li>
          <li>Improve self-control</li>
        </ul>
        <p>
          Check out the different relaxation exercises in the Info Hub.{' '}
          <InfoHubLink section="relaxation">Give them a try.</InfoHubLink>
        </p>
      </Section>

      <Section
        title="Committed to Quit"
        completed={[
          `${REF_KEY_BASE}/reason1`,
          // `${REF_KEY_BASE}/reason2`,
          // `${REF_KEY_BASE}/reason3`,
          `${REF_KEY_BASE}/namePerson`,
          `${REF_KEY_BASE}/quitDate`
        ].every((refKey) => isSectionAnswered(refKey, data))}
      >
        <p>
          Mapping readiness and commitment to quit is proven to help teens quit
          tobacco use. During each session you will be asked a couple of
          questions on how you are feeling about your quit journey. Your answers
          will be saved in your Journey Journal to help you track your progress.
        </p>
      </Section>

      <Section title="Goal Setting">
        <AbstractQuestion refKey={'reason1'} />
        <AbstractQuestion refKey={'reason2'} />
        <AbstractQuestion refKey={'reason3'} />
        <AbstractQuestion refKey={'namePerson'} />
        <AbstractQuestion refKey={'quitDate'} />
      </Section>

      <Section title="Nic Check">
        <p>
          Every time you use tobacco, track it with <NicCheckLink />. The more
          you track, the more aware you will be about your tobacco use,
          cravings, and triggers, which improves your chances to successfully
          quit tobacco! Your responses will be saved in your <JournalLink />.
        </p>
      </Section>

      <Section title="Info Hub">
        <p>Check out additional resources for this session!</p>
        <p>
          <InfoHubLink section="session-one" item="fact-or-fiction">
            Fact or Fiction
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-one" item="tobacco-products-overview">
            Tobacco Products Overview
          </InfoHubLink>
        </p>
      </Section>

      <Section title="Journey Journal">
        <p>
          <b>You have completed this session,</b> come back next week for your
          next session. You’ll receive a reminder when it’s time to log back in.
        </p>
        <p>
          All of your responses from this session will be saved in your Journey
          Journal. <JournalLink>Click here to review your Journal.</JournalLink>
        </p>
      </Section>
    </Layout>
  );
};

export default Interactive;
