import InfoHubLink from 'components/InfoHubLink';
import NicCheckLink from 'components/NicCheckLink';
import JournalLink from 'components/JournalLink';

import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import { isSectionAnswered } from 'utils/progressCalculator';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const Interactive = () => {
  const { data } = useAuthenticationData();
  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          text="Submit Session"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Session 2: Seeing a Tobacco-free View"
      subTitle="Explore More"
      Background={null}
    >
      <Section>
        <p>
          Let’s go a little further. Your answers in this section will be saved
          to your Journey Journal to help you understand your tobacco use
          behaviors and track your progress.
        </p>
      </Section>
      <Section
        title="The Nicotine Trap"
        completed={isSectionAnswered(`${REF_KEY_BASE}/trueFalse`, data)}
      >
        <p>
          Using tobacco leads to nicotine addiction, also known as nicotine
          dependence, putting you at risk of being a tobacco user for a long
          time. The nicotine in tobacco exposes you to chemicals that harm
          nearly every organ in your body, now and later in life.
        </p>
        <AbstractQuestion refKey={'trueFalse'} />
      </Section>

      <Section
        title="Triggers for Using Tobacco"
        completed={isSectionAnswered(`${REF_KEY_BASE}/triggers`, data)}
      >
        <p>
          A trigger is anything that makes you want to light up, vape, dip, or
          use any tobacco product. Triggers can be{' '}
          <b>people, places, situations,</b> and even <b>moods</b>. For example,
          you may connect using tobacco with a group of friends or a certain
          place, or, with stressful situations like arguing with parents, taking
          an exam, or going on a date. It’s important to identify your triggers
          so you can avoid them or learn to deal with them. Your answers here
          will be saved to your Journey Journal to help you better understand
          your tobacco story.
        </p>
        <AbstractQuestion refKey={'triggers'} />
      </Section>

      <Section title="Reflect and Relax">
        <p>
          One tool for changing behavior is to imagine you already made the
          change. As you listen to one of the relaxation exercises on the Info
          Hub, think about yourself as tobacco-free:
        </p>
        <ul>
          <li>
            How is your image of yourself different without tobacco (healthier,
            more in control...)?
          </li>
          <li>How will others see you differently?</li>
          <li>How will your life be better without tobacco use?</li>
        </ul>

        <p>Relaxation exercises can help you:</p>
        <ul>
          <li>Improve your chances of successfully quitting tobacco</li>
          <li>Reduce stress</li>
          <li>Be more aware of your tobacco habits and cravings</li>
          <li>Improve self-control</li>
        </ul>
        <p>
          Check out the different relaxation exercises in the Info Hub.{' '}
          <InfoHubLink section="relaxation">Give them a try.</InfoHubLink>
        </p>
      </Section>

      <Section
        title="Committed to Quit"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/motivation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/confidence`, data)
        }
      >
        <p>
          Mapping readiness and commitment to quit is proven to help teens quit
          tobacco use. Let’s take a closer look at where you are right now. Your
          answers will be saved in your Journey Journal to help you track your
          progress.
        </p>
        <AbstractQuestion refKey={'motivation'} />

        <AbstractQuestion refKey={'confidence'} />
      </Section>

      <Section
        title="Goal Setting"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/trigger`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/physical`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/quitDateConfirm`, data)
        }
      >
        <p>Setting goals can help you stay committed to quitting.</p>
        <AbstractQuestion refKey={'trigger'} />
        <AbstractQuestion refKey={'physical'} />
        <AbstractQuestion refKey={'quitDateConfirm'} />
      </Section>

      <Section title="Nic Check">
        <p>
          Every time you use tobacco, track it with <NicCheckLink />. The more
          you track, the more aware you will be about your tobacco use,
          cravings, and triggers, which improves your chances to successfully
          quit tobacco! Your responses will be saved in your <JournalLink />.
        </p>
      </Section>

      <Section title="Info Hub">
        <p>Check out additional resources for this session!</p>
        <p>
          <InfoHubLink section="session-two" item="exercising-my-rights">
            Exercising My Rights
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-two" item="keep-on-moving">
            Keep On Moving
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-two" item="nicotine-trap">
            Nicotine Trap
          </InfoHubLink>
        </p>
      </Section>

      <Section title="Journey Journal">
        <p>
          <b>You have completed this session,</b> come back next week for your
          next session. You’ll receive a reminder when it’s time to log back in.
        </p>
        <p>
          All of your responses from this session will be saved in your Journey
          Journal. <JournalLink>Click here to review your Journal.</JournalLink>
        </p>
      </Section>
    </Layout>
  );
};

export { Interactive as default, INTERACTIVE_QUESTIONS };
