import { useState } from 'react';
import firebase from 'utils/fire';
import { useHistory, Link } from 'react-router-dom';

import Layout from 'components/Layout';
import Button from 'components/Button';

import styles from './Login.module.css';

const Login = () => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(' ');
  // const [resetPasswordSent, setResetPasswordSent] = useState(false);

  // const handlePasswordReset = () => {
  //   firebase
  //     .auth()
  //     .sendPasswordResetEmail(username)
  //     .then((e) => {
  //       setResetPasswordSent(true);
  //     });
  // }

  const handleLogin = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(() => {
        history.push('/landing');
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  return (
    <Layout>
      <form className={styles['wrapper']}>
        <label htmlFor="login-username">Username</label>
        <input
          id="login-username"
          type="text"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          placeholder="Username"
        />

        <label htmlFor="login-password">Password</label>
        <input
          id="login-password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="Password"
        />
        <div className={styles['reset-container']}>
          <Link to="/reset" className={styles['reset-link']}>
            Forgot your password?
          </Link>
        </div>

        <div className={styles['error']}>{errorMessage}</div>

        <Button
          className={styles['login-button']}
          onClick={(e) => handleLogin(e)}
        >
          Login
        </Button>
      </form>
    </Layout>
  );
};

export default Login;
