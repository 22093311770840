import styles from './ErrorNotFound.module.css';

const ErrorNotFound = () => (
  <div className={styles.main}>
    <h3 className={styles.title}>404 page not found</h3>
    <p>We are sorry but the page you are looking for does not exist.</p>
    <p>Please recheck your url</p>
  </div>
);

export default ErrorNotFound;
