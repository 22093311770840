// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const isTobaccoTypeSelected = (data: any, tobaccoType: string) =>
  data.tobaccoTypes?.isCompleted && data.tobaccoTypes[tobaccoType]?.selected;

const hasQuit = (data: any) =>
  data.haveYouQuit?.isCompleted && data.haveYouQuit?.selectedValue === 'yes';

const hasNotQuit = (data: any) =>
  data.haveYouQuit?.isCompleted && data.haveYouQuit?.selectedValue === 'no';

const PIPING_OPTIONS = [
  {
    label: '0 days',
    value: '0days'
  },
  {
    label: '1 or 2 days',
    value: '1or2days'
  },
  {
    label: '3 to 5 days',
    value: '3to5days'
  },
  {
    label: '6 to 9 days',
    value: '6to9days'
  },
  {
    label: '10 to 19 days',
    value: '10to19days'
  },
  {
    label: '20 to 29 days',
    value: '20to29days'
  },
  {
    label: 'All 30 days',
    value: 'all30days'
  }
];

const REF_KEY_BASE = 'postEval';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  haveYouQuit: {
    questionType: 'radioSelect',
    question: 'Have you quit tobacco?',
    answers: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  },
  howManyDays: {
    required: (data) => hasQuit(data),
    questionType: 'shortText',
    validationType: 'positiveInteger',
    question: 'How many days since you last used a tobacco product?'
  },
  tobaccoTypes: {
    required: (data) => hasNotQuit(data),
    style: 'checkbox',
    questionType: 'multipleSelect',
    question:
      'What types of tobacco products do you use now or have used most recently (in the past 30 days)? (Select all that apply)',
    answers: [
      {
        label:
          'E-cigarettes (vapes, vape or hookah pens, e-pipes, e-cigars, disposables, pods, mod, or other e-devices with nicotine)',
        value: 'eCigarettes'
      },
      {
        label: 'Cigarettes',
        value: 'cigarettes'
      },
      {
        label:
          'Smokeless tobacco (including dip, snuff, snus, and chewing tobacco)',
        value: 'smokeless'
      },
      {
        label: 'Cigars (including cigarillos and little cigars)',
        value: 'cigars'
      },
      {
        label: 'Hookah tobacco (including waterpipe and shisha tobacco)',
        value: 'hookah'
      },
      {
        label: 'Other',
        value: 'other'
      }
    ]
  },
  otherTobaccoType: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'other'),
    questionType: 'shortText',
    question:
      'What other type of tobacco product do you use now or have used most recently?'
  },
  flavoredTobacco: {
    required: (data) => hasNotQuit(data),
    style: 'checkbox',
    questionType: 'multipleSelect',
    question: 'Do you use flavored tobacco products?',
    answers: [
      {
        label: 'I use flavored products (i.e. fruit, candy, dessert)',
        value: 'flavored'
      },
      {
        label: 'I use menthol products (i.e. mint, menthol)',
        value: 'menthol'
      },
      {
        label: 'I use non-flavored products (i.e. regular tobacco)',
        value: 'regular'
      }
    ]
  },
  eCigTypes: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'eCigarettes'),
    style: 'checkbox',
    questionType: 'multipleSelect',
    question: 'What type of e-cigarette/vape do you use most?',
    answers: [
      {
        label: 'Disposable',
        value: 'disposable'
      },
      {
        label: 'Pre-filled pods',
        value: 'prefilled'
      },
      {
        label: 'Tanks refillable with e-liquid',
        value: 'refillable'
      },
      {
        label: 'Mod systems that can be customized',
        value: 'customizable'
      },
      {
        label: 'Unsure',
        value: 'unsure'
      }
    ]
  },
  eCigBrands: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'eCigarettes'),
    style: 'checkbox',
    questionType: 'multipleSelect',
    question: 'What brand(s) of e-cigarettes do you use the most?',
    answers: [
      {
        label: 'Juul',
        value: 'juul'
      },
      {
        label: 'Blu',
        value: 'blu'
      },
      {
        label: 'Logic',
        value: 'logic'
      },
      {
        label: 'SMOK',
        value: 'smok'
      },
      {
        label: 'RELX',
        value: 'relx'
      },
      {
        label: 'Vaporesso',
        value: 'vaporesso'
      },
      {
        label: 'Bidi Stick',
        value: 'bidiStick'
      },
      {
        label: 'MNGO',
        value: 'mngo'
      },
      {
        label: 'UWELL',
        value: 'uwell'
      },
      {
        label: 'VOOPOO',
        value: 'voopoo'
      },
      {
        label: 'Geekvape',
        value: 'geekvape'
      },
      {
        label: 'Puff Bar',
        value: 'puffBar'
      },
      {
        label: 'Other',
        value: 'other'
      }
    ]
  },
  eCigOtherBrand: {
    required: (data) =>
      hasNotQuit(data) &&
      isTobaccoTypeSelected(data, 'eCigarette') &&
      data.eCigBrands.other?.selected,
    questionType: 'shortText',
    question: 'What other brand of e-cigarette do you use the most?'
  },
  eCigUsageDays: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'eCigarettes'),
    questionType: 'radioSelect',
    question:
      'During the past 30 days, on how many days did you use e-cigarettes?',
    answers: PIPING_OPTIONS
  },
  // eCigUsagePerDay: {
  //   required: (data) => isTobaccoTypeSelected(data, 'eCigarettes'),
  //   questionType: 'shortText',
  //   validationType: 'positiveInteger',
  //   question: 'On the days you use e-cigarettes/vape, how many TIMES per DAY do you usually use your electronic cigarette? (One "TIME" consists of around 15 puffs, or lasts around 10 minutes)'
  // },
  cigaretteUsageDays: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'cigarettes'),
    questionType: 'radioSelect',
    question:
      'During the past 30 days, on how many days did you use cigarettes?',
    answers: PIPING_OPTIONS
  },
  // cigaretteUsagePerDay: {
  //   required: (data) => isTobaccoTypeSelected(data, 'cigarettes'),
  //   questionType: 'shortText',
  //   validationType: 'positiveInteger',
  //   question: 'On the days you use cigarettes or cigars, about how many do you smoke per day?'
  // },
  smokelessUsageDays: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'smokeless'),
    questionType: 'radioSelect',
    question:
      'During the past 30 days, on how many days did you use smokeless tobacco?',
    answers: PIPING_OPTIONS
  },
  // smokelessUsagePerDay: {
  //   required: (data) => isTobaccoTypeSelected(data, 'smokeless'),
  //   questionType: 'shortText',
  //   validationType: 'positiveInteger',
  //   question: 'On the days you use smokeless tobacco, how many TIMES per DAY do you usually take a pinch of dip or chew?'
  // },
  cigarUsageDays: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'cigars'),
    questionType: 'radioSelect',
    question: 'During the past 30 days, on how many days did you use cigars?',
    answers: PIPING_OPTIONS
  },
  hookahUsageDays: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'hookah'),
    questionType: 'radioSelect',
    question: 'During the past 30 days, on how many days did you use hookah?',
    answers: PIPING_OPTIONS
  },
  smokePerDay: {
    required: (data) =>
      hasNotQuit(data) &&
      (isTobaccoTypeSelected(data, 'cigarettes') ||
        isTobaccoTypeSelected(data, 'cigars')),
    questionType: 'shortText',
    validationType: 'positiveInteger',
    question:
      'On the days you use cigarettes or cigars, about how many do you smoke per day?'
  },
  eCigPerDay: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'eCigarettes'),
    questionType: 'shortText',
    validationType: 'positiveInteger',
    question:
      'On the days you use e-cigarettes/vape, how many TIMES per DAY do you usually use your electronic cigarette? (One "TIME" consists of around 15 puffs, or lasts around 10 minutes)'
  },
  smokelessPerDay: {
    required: (data) =>
      hasNotQuit(data) && isTobaccoTypeSelected(data, 'smokeless'),
    questionType: 'shortText',
    validationType: 'positiveInteger',
    question:
      'On the days you use smokeless tobacco, how many TIMES per DAY do you usually take a pinch of dip or chew?'
  },

  // nicotinePercentage: {
  //   required: (data) => isTobaccoTypeSelected(data, 'eCigarettes'),
  //   questionType: 'shortText',
  //   validationType: 'positiveInteger',
  //   question:
  //     'Find your cartridge and locate the nicotine percentage on it. It is usually found somewhere on the package and will likely say something like, “59 mg/mL Nicotine”.  What percentage of nicotine does your cartridge contain?'
  // },
  anyoneInLifeTobacco: {
    questionType: 'radioSelect',
    question:
      'Is there anyone in your life, a friend, family member, etc. who uses tobacco?',
    answers: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  },
  howSoonAfterWaking: {
    required: (data) => hasNotQuit(data),
    questionType: 'radioSelect',
    question: 'How soon after waking up do you usually use a tobacco product?',
    answers: [
      {
        label: 'Within 5 minutes',
        value: 'within5'
      },
      {
        label: '6 - 30 minutes after waking up',
        value: '6to30'
      },
      {
        label: '31 - 60 minutes after waking up',
        value: '31to60'
      },
      {
        label: 'More than 60 minutes after waking up',
        value: 'moreThan60'
      }
    ]
  },
  reachingForTobacco: {
    questionType: 'radioSelect',
    question:
      'I find myself reaching for my tobacco product (e-cigarette/vape, cigarette, smokeless tobacco, etc.) without thinking about it.',
    answers: [
      {
        label: 'Never',
        value: 'never'
      },
      {
        label: 'Rarely',
        value: 'rarely'
      },
      {
        label: 'Sometimes',
        value: 'sometimes'
      },
      {
        label: 'Often',
        value: 'often'
      },
      {
        label: 'Almost Always',
        value: 'almostAlways'
      }
    ]
  },
  outOfMyWay: {
    questionType: 'radioSelect',
    question:
      'I drop everything to go out and get my tobacco product (e-cigarettes or e-juice/vapes, cigarettes, or smokeless tobacco).',
    answers: [
      {
        label: 'Never',
        value: 'never'
      },
      {
        label: 'Rarely',
        value: 'rarely'
      },
      {
        label: 'Sometimes',
        value: 'sometimes'
      },
      {
        label: 'Often',
        value: 'often'
      },
      {
        label: 'Almost Always',
        value: 'almostAlways'
      }
    ]
  },
  moreBeforeDisallowed: {
    questionType: 'radioSelect',
    question:
      'I vape/smoke/chew more before going into a situation where using my tobacco product is not allowed.',
    answers: [
      {
        label: 'Never',
        value: 'never'
      },
      {
        label: 'Rarely',
        value: 'rarely'
      },
      {
        label: 'Sometimes',
        value: 'sometimes'
      },
      {
        label: 'Often',
        value: 'often'
      },
      {
        label: 'Almost Always',
        value: 'almostAlways'
      }
    ]
  },
  intolerableCravings: {
    questionType: 'radioSelect',
    question:
      "When I haven't been able to vape/smoke/chew for a few hours, the craving gets intolerable.",
    answers: [
      {
        label: 'Never',
        value: 'never'
      },
      {
        label: 'Rarely',
        value: 'rarely'
      },
      {
        label: 'Sometimes',
        value: 'sometimes'
      },
      {
        label: 'Often',
        value: 'often'
      },
      {
        label: 'Almost Always',
        value: 'almostAlways'
      }
    ]
  },
  motivationToStayOff: {
    required: (data) => hasQuit(data),
    questionType: 'motivationLikert',
    question: 'How would you rate your motivation to stay tobacco free?'
  },
  confidenceToStayOff: {
    required: (data) => hasQuit(data),
    questionType: 'confidenceLikert',
    question: 'How would you rate your confidence to stay tobacco free?'
  },
  motivationToStop: {
    required: (data) => hasNotQuit(data),
    questionType: 'motivationLikert',
    question:
      'How would you rate your motivation to stop using tobacco products right now?'
  },
  confidenceToStop: {
    required: (data) => hasNotQuit(data),
    questionType: 'confidenceLikert',
    question:
      'How would you rate your confidence to stop using tobacco right now?'
  },
  satisfiedWithProgram: {
    questionType: 'likert',
    question:
      'How satisfied were you with the program as a way to quit using tobacco?',
    answers: [
      {
        label: 'Not at all satisfied',
        value: 'notAtAll'
      },
      {
        label: 'Slightly satisfied',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very satisfied',
        value: 'very'
      },
      {
        label: 'Extremely satisfied',
        value: 'extremely'
      }
    ]
  },
  meaningfulVideos: {
    questionType: 'likert',
    question: 'Videos',
    answers: [
      {
        label: 'Not at all meaningful',
        value: 'notAtAll'
      },
      {
        label: 'Slightly meaningful',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very meaningful',
        value: 'very'
      },
      {
        label: 'Extremely meaningful',
        value: 'extremely'
      }
    ]
  },
  meaningfulVideosTellUsMore: {
    questionType: 'shortText',
    question: 'Tell us more about how the videos were meaningful to you'
  },
  meaningfulAffirmations: {
    questionType: 'likert',
    question: 'Affirmations',
    answers: [
      {
        label: 'Not at all meaningful',
        value: 'notAtAll'
      },
      {
        label: 'Slightly meaningful',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very meaningful',
        value: 'very'
      },
      {
        label: 'Extremely meaningful',
        value: 'extremely'
      }
    ]
  },
  meaningfulAffirmationsTellUsMore: {
    questionType: 'shortText',
    question: 'Tell us more about how the affirmations were meaningful to you'
  },
  meaningfulRelaxationActivities: {
    questionType: 'likert',
    question: 'Relaxation Activities',
    answers: [
      {
        label: 'Not at all meaningful',
        value: 'notAtAll'
      },
      {
        label: 'Slightly meaningful',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very meaningful',
        value: 'very'
      },
      {
        label: 'Extremely meaningful',
        value: 'extremely'
      }
    ]
  },
  meaningfulRelaxationActivitiesTellUsMore: {
    questionType: 'shortText',
    question:
      'Tell us more about how the relaxation activities were meaningful to you'
  },
  meaningfulExploreActivities: {
    questionType: 'likert',
    question: 'Explore Activities',
    answers: [
      {
        label: 'Not at all meaningful',
        value: 'notAtAll'
      },
      {
        label: 'Slightly meaningful',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very meaningful',
        value: 'very'
      },
      {
        label: 'Extremely meaningful',
        value: 'extremely'
      }
    ]
  },
  meaningfulExploreActivitiesTellUsMore: {
    questionType: 'shortText',
    question:
      'Tell us more about how the explore activities were meaningful to you'
  },
  meaningfulGoalSettingQuestions: {
    questionType: 'likert',
    question: 'Goal Setting Questions',
    answers: [
      {
        label: 'Not at all meaningful',
        value: 'notAtAll'
      },
      {
        label: 'Slightly meaningful',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very meaningful',
        value: 'very'
      },
      {
        label: 'Extremely meaningful',
        value: 'extremely'
      }
    ]
  },
  meaningfulGoalSettingQuestionsTellUsMore: {
    questionType: 'shortText',
    question:
      'Tell us more about how the goal setting questions were meaningful to you'
  },
  meaningfulNicChecks: {
    questionType: 'likert',
    question: 'Nic Checks',
    answers: [
      {
        label: 'Not at all meaningful',
        value: 'notAtAll'
      },
      {
        label: 'Slightly meaningful',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very meaningful',
        value: 'very'
      },
      {
        label: 'Extremely meaningful',
        value: 'extremely'
      }
    ]
  },
  meaningfulNicChecksTellUsMore: {
    questionType: 'shortText',
    question: 'Tell us more about how the Nic Checks were meaningful to you'
  },
  meaningfulJournal: {
    questionType: 'likert',
    question: 'Journal',
    answers: [
      {
        label: 'Not at all meaningful',
        value: 'notAtAll'
      },
      {
        label: 'Slightly meaningful',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very meaningful',
        value: 'very'
      },
      {
        label: 'Extremely meaningful',
        value: 'extremely'
      }
    ]
  },
  meaningfulJournalTellUsMore: {
    questionType: 'shortText',
    question: 'Tell us more about how the journal was meaningful to you'
  },
  wouldReferBack: {
    questionType: 'likert',
    question:
      'How likely are you to refer back to this program if you started to use tobacco again?',
    answers: [
      {
        label: 'Not at all likely',
        value: 'notAtAll'
      },
      {
        label: 'Slightly likely',
        value: 'slightly'
      },
      {
        label: 'Neutral',
        value: 'neutral'
      },
      {
        label: 'Very likely',
        value: 'very'
      },
      {
        label: 'Extremely likely',
        value: 'extremely'
      }
    ]
  },
  returnReasons: {
    required: false,
    questionType: 'shortText',
    question: 'Why or why not would you return to NOT For Me?'
  },
  feelToTakeOnOwn: {
    questionType: 'shortText',
    question: 'How did you feel going through this program on your own?'
  },
  feelToTakeOnline: {
    questionType: 'shortText',
    question: 'How did you feel going through this program online?'
  },
  relatableToIdentity: {
    questionType: 'shortText',
    question:
      'How relatable was this program to your personal identity and life experiences?'
  },
  wouldRecommendToFriend: {
    questionType: 'radioSelect',
    question: 'Would you recommend this program to a friend?',
    answers: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  },
  recommendToFriendReasons: {
    questionType: 'shortText',
    question: 'Why or why not?'
  },
  whatWouldYouImprove: {
    questionType: 'shortText',
    question: 'What would you improve about the program?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
