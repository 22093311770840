import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './RadioSelect.module.css';

const RadioSelect = ({
  questionData: { question, answers },
  answerData: { selectedValue },
  baseUpdate
}) => {
  const [id] = useState(uuid());
  const update = baseUpdate(`selectedValue`);

  return (
    <div className={styles['wrapper']}>
      <label htmlFor={id}>{question}</label>
      <div id={id} className={styles['answers']}>
        {answers.map(({ label, value }, i) => (
          <div key={i}>
            <input
              type="radio"
              id={`${id}-${value}`}
              checked={selectedValue === value}
              onChange={() => update(value, true)}
            />
            <label htmlFor={`${id}-${value}`}>{label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioSelect;
