import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import IntroBackground from 'components/Layout/components/IntroBackground';

const Intro = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-one-video" />}
    title="Session 1: Getting The Facts"
    subTitle="Session Introduction"
    Background={IntroBackground}
    showChildrenBackground={false}
  >
    <Section>
      <p>
        Congratulations on deciding to quit tobacco! The NOT for Me program is
        your personal journey to help you quit tobacco or reduce the amount you
        use, on your own terms. NOT for Me addresses all types of tobacco,
        including cigarettes, e-cigarettes (vaping or ‘Juuling’), hookah,
        smokeless tobacco and cigars. All contain nicotine and lead to nicotine
        addiction. NOT for Me helps you free yourself from nicotine addiction,
        no matter what tobacco products you use.
      </p>
      <p>
        NOT for Me also improves how you relate to others, solve problems, and
        deal with stress - skills that will help you in life and to stay
        tobacco-free.
      </p>

      <p>Here’s what we will explore in this session:</p>
      <ul>
        <li>Understanding reasons why teens use tobacco</li>
        <li>Getting to know the facts and fiction around tobacco use</li>
        <li>Identifying all types of tobacco products</li>
      </ul>
    </Section>
  </Layout>
);

export default Intro;
