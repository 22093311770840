import { View } from '@react-pdf/renderer';

import Header from '../Header';

const Section = ({ header, children }) => (
  <View style={{ marginTop: '15px', marginBottom: '15px' }}>
    {header && <Header>{header}</Header>}
    {children}
  </View>
);

export default Section;
