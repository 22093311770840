import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import IntroBackground from 'components/Layout/components/IntroBackground';

import JournalLink from 'components/JournalLink';

const Intro = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-four-video" />}
    title="Session 4: Gearing Up for Quit Day"
    subTitle="Session Introduction"
    Background={IntroBackground}
    showChildrenBackground={false}
  >
    <Section>
      <p>Welcome back!</p>
      <p>
        Take a look at your goals in your <JournalLink /> from last week. How
        did you do? As you gear up for Quit Day, planning is key. We have more
        tips and tools to help you plan smart for this part of the journey!
      </p>

      <p>Here’s what we will cover this session:</p>
      <ul>
        <li>
          Understanding the physical, psychological and social benefits of
          quitting
        </li>
        <li>Dealing with urges and cravings</li>
        <li>Making a personal commitment to quitting</li>
      </ul>
    </Section>
  </Layout>
);

export default Intro;
