import { useState } from 'react';
import DatePicker from 'react-date-picker';

import styles from './DateSelector.module.css';

const DateSelector = ({
  questionData: {
    question,
    minDate: minDateFunc,
    maxDate: maxDateFunc,
    required = true
  },
  answerData: { value: dateString, isCompleted },
  baseUpdate
}) => {
  const [minDate] = useState(minDateFunc ? minDateFunc() : null);
  const [maxDate] = useState(maxDateFunc ? maxDateFunc() : null);
  const update = baseUpdate('value');
  const date = dateString ? new Date(dateString) : null;
  return (
    <div className={styles['wrapper']}>
      <label>{question}</label>
      <DatePicker
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(value) => update(new Date(value).toISOString(), true)}
        clearIcon={null}
      />
      <span className={styles.error}>
        {required && !isCompleted && `Please select a date`}
      </span>
    </div>
  );
};

export default DateSelector;
