import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MainRouter from './MainRouter.jsx';
import firebase from 'utils/fire';
import { AuthenticationProvider } from 'components/AuthenticationContext';

const Main = () => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);
  const [dataRef, setDataRef] = useState(null);

  // const shouldRedirect =
  //   location.pathname === '/' ||
  //   location.pathname === '/login' ||
  //   location.pathname === '/create-account';

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // TODO extract to utility function
        const dataRef = firebase.database().ref(`users/${user.uid}`);
        dataRef.on('value', (snapshot) => {
          const data = snapshot.val();
          setData(data);

          // TODO I'd prefer to handle authentication in a better way. Could potentially add
          // a value to the user to indicate if it's pre-initial-data-load so that the
          // page/router can show a loading indicator during that time and then, if that value is
          // false, redirect based on permissions
          if (data) {
            if (history.location.pathname === '/admin' && !data?.admin) {
              history.push('/');
            } else if (
              history.location.pathname !== '/user-info' &&
              !data?.userProfile?.isCompleted
            ) {
              history.push('/user-info');
            } else if (
              history.location.pathname !== '/user-info' &&
              history.location.pathname !== '/pre-eval' &&
              !data?.preEval?.isCompleted
            ) {
              history.push('/pre-eval');
            }
          }
        });

        setUser(user);
        setDataRef(dataRef);

        if (history.location.pathname === '/create-account') {
          // If the user is coming from /create-account, redirect them to the first page in the user
          // creation process (/user-info)
          history.push('/user-info');
        } else if (history.location.pathname === '/login') {
          // If they're logging into an existing account, redirect them to /landing instead
          history.push('/landing');
        }
      } else {
        setUser(undefined);
        setData(undefined);
        setDataRef(undefined);

        const shouldRedirect =
          history.location.pathname !== '/' &&
          history.location.pathname !== '/login' &&
          history.location.pathname !== '/create-account' &&
          history.location.pathname !== '/terms-and-conditions';
        // If the user isn't on an unrestricted page and they're not logged in, redirect them to /
        if (shouldRedirect) history.push('/');
      }
    });
    return () => {
      unsubscribe();
    };
  }, [history]);

  return (
    <AuthenticationProvider user={user} data={data} dataRef={dataRef}>
      <MainRouter />
    </AuthenticationProvider>
  );
};

export default Main;
