import Layout, { NextPageButton } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';

const Video = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-six-interactive" />}
    title="Session 6: Getting Needs Met"
    subTitle="Video"
  >
    <VideoEmbed videoId="MRQhTYOuloI" title="Session Six Video" />
  </Layout>
);

export default Video;
