import { useHistory, useLocation } from 'react-router-dom';

import styles from './InfoHubLink.module.css';

// const useQuery = () => {
//   const { search } = useLocation();
//   return new URLSearchParams(search)
//     .entries()
//     .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
// };

const InfoHubLink = ({ section, item, children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  // console.log('location', location);

  return (
    <span
      className={styles['wrapper']}
      onClick={() =>
        history.push(
          `info-hub/${section}${item ? `/${item}` : ``}?returnUrl=${pathname}`
        )
      }
    >
      <span className={styles['text-wrapper']}>{children}</span>
      <span className={styles['icon-wrapper']}>
        <span className={styles['background']} />
        <span className={styles['icon']} alt={`Info Hub icon`} />
      </span>
    </span>
  );
};

export default InfoHubLink;
