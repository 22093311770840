// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'sessionSevenInteractive';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  digitalAdvocateText: {
    questionType: 'shortText',
    question: 'Write what you will say on your post here for practice:'
  },
  digitalAdvocateWhere: {
    questionType: 'shortText',
    question: 'Where will you post it?'
  },
  voiceImpact: {
    questionType: 'shortText',
    question:
      'Your voice is powerful. You DO make a difference and can influence others. What low/medium/high impact activity will you participate in to help influence others to make better decisions about tobacco use?'
  },
  motivation: {
    questionType: 'motivationLikert',
    question:
      'How would you rate your motivation to stop using tobacco products right now?'
  },
  confidence: {
    questionType: 'confidenceLikert',
    question:
      'How would you rate your confidence in stopping use of tobacco products right now?'
  },
  goalNice: {
    questionType: 'shortText',
    question:
      'This week, volunteer to do something nice for at least 1 person during the week. This could mean something like helping a friend with their homework, reading to a younger brother or sister, or running an errand for an elderly neighbor. What will you do?'
  },
  goalTellAboutJourney: {
    questionType: 'shortText',
    question:
      'Tell at least 1 friend who uses tobacco about your quit journey (or blast it with a social media post). Who will you tell?'
  },
  goalTellThemWhat: {
    questionType: 'shortText',
    question: 'What will you tell them?'
  },
  goalTellNumberOfDays: {
    questionType: 'shortText',
    question: `Tell at least 1 person about the number of days that you haven't used tobacco. How many days has it been since you quit using tobacco?`
  },
  goalExercise: {
    questionType: 'shortText',
    question:
      'Exercise at least 20 minutes per day for 4 of the next 7 days. What will you do for exercise?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
