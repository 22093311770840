import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Layout, { NextPageButton } from 'components/Layout';
import Button from 'components/Button';

import styles from './Landing.module.css';
import { useAuthenticationData } from 'components/AuthenticationContext';

import {
  getCurrentUserSession,
  getSessionUnlockProgress
} from 'utils/progressCalculator';

import Map from './components/Map';
import MobileMap from './components/MobileMap';

import moment from 'moment';
import Footer from 'components/Footer';

const buildSessionUnlockText = (duration) => {
  let durationText = [];

  const days = Math.floor(duration.asDays());
  if (days) {
    durationText.push(`${days} Day${days > 1 ? 's' : ''}`);
  }

  const hours = duration.hours();
  if (hours) {
    durationText.push(`${hours} Hour${hours > 1 ? 's' : ''}`);
  }

  const minutes = duration.minutes();
  if (minutes && days < 1) {
    durationText.push(`${minutes} Minute${minutes > 1 ? 's' : ''}`);
  }

  const seconds = duration.seconds();
  if (days < 1 && hours < 1 && minutes < 10) {
    if (minutes < 1 && seconds < 1) {
      // Special edge case - when the timer has less than a second left, there is a moment of time
      // where the actual unlock time hasn't been passed but the visuals have updated, showing
      // just "Session Available in" - this keeps it at 1 second until the actual time has elapsed.
      durationText.push(`1 Second`);
    } else {
      durationText.push(`${seconds} Second${seconds > 1 ? 's' : ''}`);
    }
  }

  if (durationText.length > 1) {
    durationText[durationText.length - 1] = `and ${
      durationText[durationText.length - 1]
    }`;
  }

  return `Available in ${durationText.join(' ')}`;
};

const calculateValues = (data) => {
  const currentTimestamp = Date.now();

  const currentSession = getCurrentUserSession(data);
  const currentSessionUnlockProgress = getSessionUnlockProgress(
    data,
    currentSession?.key,
    currentTimestamp
  );

  let buttonText = `Continue to ${currentSession?.name}`;
  if (currentSessionUnlockProgress < 1) {
    const duration = moment.duration(
      currentSession.delay.time * (1 - currentSessionUnlockProgress),
      'milliseconds'
    );
    buttonText = `${currentSession?.name} ${buildSessionUnlockText(duration)}`;
  }

  return {
    currentSession,
    currentSessionUnlockProgress,
    buttonText
  };
};

const UnlockButton = ({ data }) => {
  const history = useHistory();
  const {
    currentSession: initialCurrentSession,
    currentSessionUnlockProgress: initialCurrentSessionUnlockProgress,
    buttonText: initialButtonText
  } = calculateValues(data);

  const [currentSession, setCurrentSession] = useState(initialCurrentSession);
  const [
    currentSessionUnlockProgress,
    setCurrentSessionUnlockProgress
  ] = useState(initialCurrentSessionUnlockProgress);

  const [buttonText, setButtonText] = useState(initialButtonText);

  useEffect(() => {
    const interval = setInterval(() => {
      const {
        currentSession: updatedCurrentSession,
        currentSessionUnlockProgress: updatedCurrentSessionUnlockProgress,
        buttonText: updatedButtonText
      } = calculateValues(data);
      setCurrentSession(updatedCurrentSession);
      setCurrentSessionUnlockProgress(updatedCurrentSessionUnlockProgress);
      setButtonText(updatedButtonText);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [data]);

  // TODO - can remove this once the final "session" is the congratulations/certificate page
  return (
    <>
      {currentSession && (
        <Button
          className={styles['unlock-button']}
          disabled={currentSessionUnlockProgress < 1}
          onClick={() => history.push(currentSession?.url || '#')}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

const MinimalContentWrapper = ({ children }) => <>{children}</>;

const MobileStickyFooter = ({ children }) => (
  <Footer className={styles['mobile-sticky-footer']}>{children}</Footer>
);

const Landing = () => {
  const { data, dataRef } = useAuthenticationData();
  const { showMapHelp = true } = data || {};
  const setShowMapHelp = (val) => dataRef.update({ showMapHelp: val });

  return (
    <Layout
      nextPageButton={
        data ? (
          <UnlockButton data={data} />
        ) : (
          <NextPageButton url="/landing" disabled={true} text="Loading..." />
        )
      }
      Background={null}
      ContentWrapper={MinimalContentWrapper}
      Footer={MobileStickyFooter}
    >
      <Map showMapHelp={showMapHelp} setShowMapHelp={setShowMapHelp} />
      <MobileMap />
    </Layout>
  );
};

export default Landing;
