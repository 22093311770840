import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import IntroBackground from 'components/Layout/components/IntroBackground';

const Intro = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-eight-video" />}
    title="Session 8: Staying on Course"
    subTitle="Session Introduction"
    Background={IntroBackground}
    showChildrenBackground={false}
  >
    <Section>
      <p>
        Welcome back! You did it! You arrived at the final session in NOT For
        Me! No matter where you are in your quit journey, be proud of your
        progress.
      </p>

      <p>We have a few more critical topics to explore in this last session:</p>

      <ul>
        <li>
          Understanding the physical and psychological changes that come with
          quitting tobacco
        </li>
        <li>
          Learning healthy ways to respond to physical and emotional stress
        </li>
        <li>Finding ways to celebrate your successes</li>
      </ul>
    </Section>
  </Layout>
);

export default Intro;
