import { Text } from '@react-pdf/renderer';

import Session from '../../components/Session';
import Section from '../../components/Section';
import SessionSummary from '../../components/SessionSummary';
import MultiselectDisplay from '../../components/MultiselectDisplay';
import LikertPairDisplay from '../../components/LikertPairDisplay';
import MultilineNumberedList from '../../components/MultilineNumberedList';
import RelaxationHub from '../../components/RelaxationHub';
import SayItLoud from '../../components/SayItLoud';

import SESSION_TWO_QUESTIONS from 'pages/SessionTwo/data';

const SessionTwo = ({ data }) => {
  const {
    // trueFalse,
    triggers,
    motivation,
    confidence,
    trigger,
    physical,
    quitDateConfirm
  } = data;

  return (
    <Session>
      <SessionSummary
        header="Session 2: Seeing a Tobacco-free View"
        bullets={[
          'Understanding your tobacco use history',
          'Seeing yourself as a non-user of tobacco',
          'Understanding nicotine dependence',
          'Understanding how exercise can help you quit'
        ]}
      />
      <Section header="Explore More">
        {triggers?.isCompleted && (
          <>
            <Text style={{ fontWeight: 'bold' }}>
              My triggers for using tobacco are:
            </Text>
            <MultiselectDisplay
              question={SESSION_TWO_QUESTIONS['triggers']}
              data={triggers}
            />
          </>
        )}
      </Section>
      <Section header="Committed to Quit">
        <LikertPairDisplay motivation={motivation} confidence={confidence} />
      </Section>
      <Section header="My Goals">
        {trigger?.isCompleted &&
          physical?.isCompleted &&
          quitDateConfirm?.isCompleted && (
            <MultilineNumberedList
              items={[
                {
                  question: 'The trigger I will try to resist this week is:',
                  answer: trigger?.value
                },
                {
                  question:
                    'The physical activity I am going to try for 10 minutes each day is:',
                  answer: physical?.value
                },
                {
                  question: 'My Quit Date is:',
                  answer: quitDateConfirm?.value
                    ? `${
                        new Date(quitDateConfirm.value).getMonth() + 1
                      }/${new Date(quitDateConfirm.value).getDate()}/${new Date(
                        quitDateConfirm.value
                      )
                        .getFullYear()
                        .toString()
                        .substring(2)}`
                    : ''
                }
              ]}
            />
          )}
      </Section>
      <Section header="My Reminders">
        <Text style={{ fontWeight: 'bold' }}>
          Remember to track my tobacco use with Nic Check.
        </Text>
        <RelaxationHub
          bullets={['Exercising My Rights', 'Keep On Moving', 'Nicotine Trap']}
        />
      </Section>
      <SayItLoud phrase="I am stronger than nicotine dependence!" />
    </Session>
  );
};

export default SessionTwo;
