// TODO - fix tslint to work properly with absoulte paths (when building from functions folder)
import { QuestionSet } from '../../utils/types';

const REF_KEY_BASE = 'sessionEightInteractive';

const INTERACTIVE_QUESTIONS: QuestionSet = {
  descriptionBefore: {
    questionType: 'shortText',
    question:
      'What were some ways that you would describe yourself as a tobacco user, before you started this program?'
  },
  descriptionAfter: {
    questionType: 'shortText',
    question:
      'Since you’ve gone through the program, now, how would you describe yourself?'
  },
  characteristics: {
    questionType: 'multipleSelect',
    question: undefined,
    answers: [
      {
        label:
          'Keep note in your phone to remind yourself why you are quitting.',
        value: 'keepNotes'
      },
      {
        label: 'Ask your friends and family to help you stay off tobacco.',
        value: 'askFriendsAndFamily'
      },
      {
        label:
          'Save your money in a separate jar or bank. Use it to buy yourself something special after a few weeks or months.',
        value: 'saveMoney'
      },
      {
        label:
          'Chew sugarless gum or eat veggie sticks when you get the urge to use tobacco.',
        value: 'chewGum'
      },
      {
        label: 'Get rid of all tobacco products, matches or lighters.',
        value: 'getRidOfProducts'
      },
      {
        label:
          'If you have an urge to use tobacco, take 10 deep breaths instead.',
        value: 'deepBreaths'
      },
      {
        label:
          'Eat three small meals a day instead of one or two large ones. This maintains blood sugar levels and decreases the urge to use tobacco.',
        value: 'eatSmallMeals'
      },
      {
        label: 'Keep healthy snacks on hand.',
        value: 'keepSnacks'
      },
      {
        label: 'Take a nap when you need a little extra rest.',
        value: 'takeNaps'
      },
      {
        label: 'Spend more time with people who don’t use tobacco.',
        value: 'spendTimeWithNonUsers'
      },
      {
        label:
          'Celebrate all of your anniversaries off tobacco (24 hours, 24 days, 24 months…).',
        value: 'celebrateAnniversaries'
      },
      {
        label: 'Meditate',
        value: 'meditate'
      },
      {
        label: 'Use positive self-talk.',
        value: 'positiveSelfTalk'
      }
    ]
  },
  motivation: {
    questionType: 'motivationLikert',
    question:
      'How would you rate your motivation to stop using tobacco products right now?'
  },
  confidence: {
    questionType: 'confidenceLikert',
    question:
      'How would you rate your confidence in stopping use of tobacco products right now?'
  },
  goalReward: {
    questionType: 'shortText',
    question: 'Buy or give yourself a reward this week. What will you get?'
  },
  goalMeditation: {
    questionType: 'shortText',
    question:
      'Meditate at least once this week.  What relaxation exercise will you practice?'
  },
  goalWhatWillYouTellThem: {
    questionType: 'shortText',
    question:
      'Tell at least 1 friend about the positive life changes you made from this program. What will you tell them?'
  },
  goalExercise: {
    questionType: 'shortText',
    question:
      'Exercise at least 20-30 minutes for 5 of the next 7 days. How have your exercise habits changed since you started NOT for Me?'
  }
};

export { INTERACTIVE_QUESTIONS as default, REF_KEY_BASE };
