import Layout, { NextPageButton } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';

const Video = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-three-interactive" />}
    title="Session 3: Getting Ready"
    subTitle="Video"
  >
    <VideoEmbed videoId="V1bHWbo98tQ" title="Session Three Video" />
  </Layout>
);

export default Video;
