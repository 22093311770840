import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import { getAnswerData } from 'utils/data';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';

import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const buildIsMultiselectOptionSelected = (data, questionKey) => (optionKey) =>
  !!getAnswerData(data, questionKey)[optionKey]?.selected;

const UserProfile = ({ intro = false }) => {
  const { data } = useAuthenticationData();

  const { isCompleted = false } = data?.[REF_KEY_BASE] || {};

  const isGenderIdentityWriteIn =
    getAnswerData(data, `${REF_KEY_BASE}/genderIdentity`)?.selectedValue ===
    'other';

  const isSexualOrientationWriteIn =
    getAnswerData(data, `${REF_KEY_BASE}/sexualOrientation`)?.selectedValue ===
    'other';

  const isEthnicityWriteIn = buildIsMultiselectOptionSelected(
    data,
    `${REF_KEY_BASE}/ethnicity`
  )('other');

  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url={intro ? '/pre-eval' : '/landing'}
          text={intro ? 'Continue' : 'Return to Map'}
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="User Profile"
      subTitle="Survey"
      Background={null}
    >
      <Section>
        <AbstractQuestion refKey={'firstName'} />
        <AbstractQuestion refKey={'birthday'} />
        <AbstractQuestion refKey={'startAge'} />
        <AbstractQuestion refKey={'state'} />
        <AbstractQuestion refKey={'zipCode'} />
        <AbstractQuestion refKey={'phoneNumber'} />
        <AbstractQuestion refKey={'genderIdentity'} />
        {isGenderIdentityWriteIn && (
          <AbstractQuestion refKey={'otherGenderIdentity'} />
        )}
        <AbstractQuestion refKey={'identifyAsTransgender'} />
        <AbstractQuestion refKey={'sexualOrientation'} />
        {isSexualOrientationWriteIn && (
          <AbstractQuestion refKey={'otherSexualOrientation'} />
        )}
        <AbstractQuestion refKey={'ethnicity'} />
        {isEthnicityWriteIn && <AbstractQuestion refKey={'otherEthnicity'} />}
        <AbstractQuestion refKey={'triedBefore'} />
        <AbstractQuestion refKey={'previousNumberOfTimes'} />
      </Section>
      <Section>
        <p>
          Thank you for answering these questions. Your answers will be saved
          anonymously to help evaluate this program. We will never connect your
          responses back to you.
        </p>
        {!isCompleted && (
          <p className="error">
            Please answer all questions above to continue to next page.
          </p>
        )}
      </Section>
    </Layout>
  );
};

export default UserProfile;
