import React, { useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AudioPlayer from 'react-h5-audio-player';

import { useAuthenticationData } from 'components/AuthenticationContext';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Layout, { NextPageButton } from 'components/Layout';

import audioPreview from 'assets/images/audio_preview.png';

import styles from './InfoHub.module.css';
import 'react-h5-audio-player/lib/styles.css';

import {
  getSessionUnlockProgress,
  getCurrentUserSession
} from 'utils/progressCalculator';

import infoHubData from './data';

const SectionResource = ({ type, link }) => {
  if (type === 'pdf') {
    return (
      <div className={styles['info-hub-pdf']}>
        <object
          data={link}
          type="application/pdf"
          width="100%"
          height="100%"
          aria-label="info hub pdf"
        ></object>
      </div>
    );
  } else if (type === 'audio') {
    return (
      <div className="info-hub-audio">
        <AudioPlayer src={link} />
      </div>
    );
  }
  return <div />;
};

const InfoHubSection = ({
  sectionHeader,
  resources,
  buttonLink,
  expanded,
  handleChange,
  handleSelectItem,
  handleMobileSelectItem
}) => {
  const history = useHistory();
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={styles.typography}>{sectionHeader}</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles['info-hub-details']}>
        {resources.map(
          ({ id, resourceDescription, preview = audioPreview }) => (
            <React.Fragment key={id}>
              <InfoHubResource
                className={'desktop-only'}
                id={id}
                description={resourceDescription}
                preview={preview}
                handleSelectItem={handleSelectItem}
              />
              <InfoHubResource
                className={'mobile-only'}
                id={id}
                description={resourceDescription}
                preview={preview}
                handleSelectItem={handleMobileSelectItem}
              />
            </React.Fragment>
          )
        )}
      </AccordionDetails>
      {buttonLink ? (
        <Button
          className={styles['session-return-button']}
          onClick={() => history.push(buttonLink)}
        >
          Go to {sectionHeader}
        </Button>
      ) : null}
    </Accordion>
  );
};

const InfoHubResource = ({
  className,
  id,
  preview,
  description,
  handleSelectItem
}) => (
  <div
    className={
      className
        ? [className, styles['resource-wrapper']].join(' ')
        : styles['resource-wrapper']
    }
    onClick={() => handleSelectItem(id)}
  >
    <img
      className={styles['resource-preview']}
      src={preview}
      alt={`Preview of ${description}`}
    />
    <div className={styles['resource-description']}>{description}</div>
  </div>
);

const InfoHubItemModal = ({ resourceUrl, resourceType, onClose, title }) => {
  return (
    <Modal
      isOpen={!!(resourceUrl && resourceUrl.length > 0)}
      onClose={onClose}
      title={title}
      modalClass={styles['modal']}
      modalTitleClass={styles['modal-title']}
    >
      <SectionResource type={resourceType} link={resourceUrl} />
    </Modal>
  );
};

const MinimalContentWrapper = ({ children }) => <>{children}</>;

const useQuery = () => {
  const { search } = useLocation();
  return Array.from(new URLSearchParams(search).entries()).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  );
};

const InfoHub = () => {
  const { section = '', item = '' } = useParams();
  const [activeSectionId, setActiveSectionId] = useState(section);
  const [selectedItemId, setSelectedItemId] = useState(item);

  const { returnUrl } = useQuery();

  const { data } = useAuthenticationData();

  const currentTimestamp = Date.now();
  const { key: currentSessionKey } = getCurrentUserSession(data) || {};
  const sessionUnlockProgress = getSessionUnlockProgress(
    data,
    currentSessionKey,
    currentTimestamp
  );

  const filteredInfoHubData = infoHubData.filter(
    ({ key }) =>
      !key ||
      !!data?.[key]?.isCompleted ||
      (sessionUnlockProgress >= 1 && currentSessionKey === key)
  );

  const {
    // id: resourceId = '',
    resourceDescription = '',
    resourceType = '',
    resourceLink = ''
  } =
    (
      infoHubData.find(
        (infoHubSection) => infoHubSection.id === activeSectionId
      ) || { resources: [] }
    ).resources.find((resource) => resource.id === selectedItemId) || {};

  // TODO use history to get previous page and set that as Layout's nextPage
  return (
    <div>
      <InfoHubItemModal
        resourceUrl={resourceLink}
        resourceType={resourceType}
        title={resourceDescription}
        onClose={() => setSelectedItemId('')}
      />
      <Layout
        nextPageButton={
          <NextPageButton
            url={returnUrl || `/landing`}
            text={returnUrl ? `Return to Session` : `Go to Journey Map`}
          />
        }
        title="Info Hub"
        Background={null}
        ContentWrapper={MinimalContentWrapper}
      >
        <div className={styles.hubItems}>
          {filteredInfoHubData.map(
            ({ id, sectionHeader, resources, buttonLink }) => (
              <InfoHubSection
                key={id}
                sectionHeader={sectionHeader}
                resources={resources}
                buttonLink={buttonLink}
                expanded={id === activeSectionId}
                handleChange={() => {
                  setSelectedItemId('');
                  setActiveSectionId(activeSectionId === id ? '' : id);
                }}
                handleSelectItem={(itemId) =>
                  setSelectedItemId(selectedItemId === itemId ? '' : itemId)
                }
                handleMobileSelectItem={(itemId) => {
                  const {
                    id,
                    resourceLink: url,
                    resourceType: type
                  } = resources.find((item) => item.id === itemId);
                  if (url) {
                    if (type === 'audio') {
                      setSelectedItemId(selectedItemId === id ? '' : id);
                    } else {
                      window.open(url, '_self');
                    }
                  }
                }}
              />
            )
          )}
        </div>
      </Layout>
    </div>
  );
};

export default InfoHub;
