import { useNicCheck } from 'components/NicCheckContext';

import styles from './NicCheckLink.module.css';

const NicCheckLink = ({ children = <>Nic Check</> }) => {
  const { setShowNicCheck } = useNicCheck();
  return (
    <span className={styles['wrapper']} onClick={() => setShowNicCheck(true)}>
      <span className={styles['text-wrapper']}>{children}</span>
      <span className={styles['icon-wrapper']}>
        <span className={styles['background']} />
        <span className={styles['icon']} alt={`Nic Check icon`} />
      </span>
    </span>
  );
};

export default NicCheckLink;
