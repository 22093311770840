import { Text } from '@react-pdf/renderer';

import Session from '../../components/Session';
import Section from '../../components/Section';
import SessionSummary from '../../components/SessionSummary';
import LikertPairDisplay from '../../components/LikertPairDisplay';
import RelaxationHub from '../../components/RelaxationHub';
import SayItLoud from '../../components/SayItLoud';

// import SESSION_SIX_QUESTIONS, {
//   REF_KEY_BASE as SESSION_SIX_REF_KEY
// } from 'pages/SessionSix/data';

const SessionSix = ({ data }) => {
  const {
    reasonForQuitting,
    tellYourselfToRemind,
    friendOffersTobacco,
    someoneVapesInCar,
    motivation,
    confidence,
    tellPeople,
    temptingSituation,
    barrierToExercise
  } = data;
  return (
    <Session>
      <SessionSummary
        header="Session 6: Getting Needs Met"
        bullets={[
          'Identifying ways to express your needs to friends and family',
          'Getting familiar with challenges to expect during the quitting process',
          'Planning for overcoming obstacles to quitting'
        ]}
      />
      <Section header="Explore More">
        <Text style={{ fontWeight: 'bold' }}>Strategies to Resist</Text>
        {reasonForQuitting?.isCompleted && (
          <>
            <Text>My reasons for quitting are:</Text>
            <Text>{reasonForQuitting.value}</Text>
          </>
        )}
        {tellYourselfToRemind?.isCompleted && (
          <>
            <Text>
              Here's what I can tell myself to remind me why I want to quit:
            </Text>
            <Text>{tellYourselfToRemind.value}</Text>
          </>
        )}
        <Text style={{ fontWeight: 'bold' }}>Assertive Communication</Text>
        {friendOffersTobacco?.isCompleted && (
          <>
            <Text>If someone offers me tobacco, I will respond:</Text>
            <Text>{friendOffersTobacco.value}</Text>
          </>
        )}
        {someoneVapesInCar?.isCompleted && (
          <>
            <Text>If I'm in a car with someone who starts to vape, I'll:</Text>
            <Text>{someoneVapesInCar.value}</Text>
          </>
        )}
      </Section>
      <Section header="Committed to Quit">
        <LikertPairDisplay motivation={motivation} confidence={confidence} />
      </Section>
      <Section header="My Goals">
        {tellPeople?.isCompleted && (
          <>
            <Text>
              1. I will tell at least 2 people how I am feeling about my quit
              journey. This is one feeling I have about my journey right now?
            </Text>
            <Text>{tellPeople.value}</Text>
          </>
        )}
        {temptingSituation?.isCompleted && (
          <>
            <Text>
              2. From my “Top 5” list of ways to refuse the temptation to smoke,
              chew, vape, or use other tobacco products, one of the most
              tempting situations I think I will face is:
            </Text>
            <Text>{temptingSituation.value}</Text>
          </>
        )}
        {barrierToExercise?.isCompleted && (
          <>
            <Text>
              3. I will do least 20 minutes of exercise or physical activity 4
              out of 7 days this week. One of my biggest barriers to exercise or
              physical activity is:
            </Text>
            <Text>{barrierToExercise.value}</Text>
          </>
        )}
      </Section>
      <Section header="My Reminders">
        <Text>
          Remember to track my tobacco use with Nic Check. Check out the
          Relaxation Center and additional resources on the Info Hub.
        </Text>
        <RelaxationHub
          bullets={['Knowing What You Need From Others', 'Strategy Cards']}
        />
      </Section>
      <SayItLoud phrase="I stand up for myself and my commitment to live without tobacco!" />
    </Session>
  );
};

export default SessionSix;
