import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import InfoHubLink from 'components/InfoHubLink';
import NicCheckLink from 'components/NicCheckLink';
import JournalLink from 'components/JournalLink';

import { isSectionAnswered } from 'utils/progressCalculator';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const Interactive = () => {
  const { data } = useAuthenticationData();
  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          text="Submit Session"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Session 6: Getting Needs Met"
      subTitle="Explore More"
      Background={null}
    >
      <Section
        title="Strategies to Resist"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/reasonForQuitting`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/tellYourselfToRemind`, data)
        }
      >
        <p>
          It’s important to remind yourself of why you are on your quit journey.
          Take a look at the{' '}
          <InfoHubLink section="session-six" item="strategy-card">
            Strategy Card
          </InfoHubLink>
          . Developing your strategy will help you be ready in situations where
          it’s difficult to stay tobacco-free. This will be posted in your
          Journey Journal to remind you why you are quitting and to help you
          stay on track. You can also put it in your phone notes!
        </p>

        <p>
          Do you remember what your reasons are for quitting? If they're
          different today than they were when you first started, that’s okay.
        </p>
        <AbstractQuestion refKey={'reasonForQuitting'} />
        <AbstractQuestion refKey={'tellYourselfToRemind'} />
      </Section>
      <Section
        title="Assertive Communication"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/friendOffersTobacco`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/someoneVapesInCar`, data)
        }
      >
        <p>
          Communicating in a confident, clear and calm manner will help you to
          speak with others in an assertive and effective way. Take a look at{' '}
          <InfoHubLink section="session-six" item="strategy-card">
            Knowing What You Need From Others
          </InfoHubLink>{' '}
          to help guide you. It is important to express your needs, even if the
          other person is unsupportive. It is important to learn how to take a
          stand for what you feel is important.
        </p>

        <p>
          Now, it’s your turn to practice. Create confident, clear and calm
          statements for what you will say in the following situations.
        </p>
        <AbstractQuestion refKey={'friendOffersTobacco'} />
        <AbstractQuestion refKey={'someoneVapesInCar'} />
      </Section>
      <Section title="Reflect/Relax">
        <p>
          Remember, relaxation is especially important as your body is going
          through the quitting journey. You can return to the{' '}
          <InfoHubLink section="relaxation">Relaxation hub</InfoHubLink>,
          anytime, to practice these exercises.
        </p>
      </Section>
      <Section
        title="Committed to Quit"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/motivation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/confidence`, data)
        }
      >
        <p>
          Mapping readiness and commitment to quit is proven to help teens quit
          tobacco use. Let’s take a closer look at where you are right now. Your
          answers will be saved in your <JournalLink /> to help you track your
          progress.
        </p>
        <AbstractQuestion refKey={'motivation'} />
        <AbstractQuestion refKey={'confidence'} />
      </Section>
      <Section
        title="Goal Setting"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/tellPeople`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/temptingSituation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/barrierToExercise`, data)
        }
      >
        <AbstractQuestion refKey={'tellPeople'} />
        <AbstractQuestion refKey={'temptingSituation'} />
        <AbstractQuestion refKey={'barrierToExercise'} />
      </Section>
      <Section title="Nic Check">
        <p>
          A reminder to review your <NicCheckLink /> to see your tobacco use “at
          a glance”. This can help you recognize moods, times of day or urges
          that you’ll want to prepare for to stay tobacco free. If you slip, be
          sure to document on your <NicCheckLink /> also, so you can develop a
          plan for how to handle similar situations in the future. Your
          responses will be saved in your <JournalLink />.
        </p>
      </Section>

      <Section title="Info Hub">
        <p>Check out additional resources for this session!</p>
        <p>
          <InfoHubLink
            section="session-six"
            item="knowing-what-you-need-from-others"
          >
            Knowing What You Need From Others
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-six" item="strategy-card">
            Strategy Card
          </InfoHubLink>
        </p>
      </Section>

      <Section title="Journey Journal">
        <p>
          <b>You have completed this session,</b> come back next week for your
          next session. You’ll receive a reminder when it’s time to log back in.
        </p>
        <p>
          All of your responses from this session will be saved in your Journey
          Journal. <JournalLink>Click here to review your Journal.</JournalLink>
        </p>
      </Section>
    </Layout>
  );
};

export { Interactive as default, INTERACTIVE_QUESTIONS };
