import Layout, { NextPageButton } from 'components/Layout';
import InfoHubLink from 'components/InfoHubLink';
import NicCheckLink from 'components/NicCheckLink';
import JournalLink from 'components/JournalLink';

import Section from 'components/Section';

import { isSectionAnswered } from 'utils/progressCalculator';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const Interactive = () => {
  const { data } = useAuthenticationData();
  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          text="Submit Session"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Session 3: Getting Ready"
      subTitle="Explore More"
      Background={null}
    >
      <Section>
        <p>
          Let’s go a little further. Your answers on this section will be saved
          to your Journey Journal to help you understand your tobacco use
          behaviors and track your progress.
        </p>
      </Section>

      <Section
        title="The Body Destroyer"
        completed={isSectionAnswered(`${REF_KEY_BASE}/impactConcerns`, data)}
      >
        <p>
          Take a look at{' '}
          <InfoHubLink section="session-three" item="the-body-destroyer">
            The Body Destroyer
          </InfoHubLink>
        </p>
        <p>
          Teens who use tobacco go through many short and long term body
          changes. Click on the images or words that show your biggest concern
          about how tobacco use is impacting you <b>right now</b>.
        </p>
        <AbstractQuestion refKey={'impactConcerns'} />
      </Section>

      <Section
        title="Do Something Else (Instead of Using Tobacco)"
        completed={isSectionAnswered(`${REF_KEY_BASE}/replacement1`, data)}
      >
        <p>
          Look at{' '}
          <InfoHubLink
            section="session-three"
            item="nic-check-understanding-my-use"
          >
            Nic-Check - Understanding My Use
          </InfoHubLink>{' '}
          and then look at your Nic Check entries. Review your triggers, where
          and when you use tobacco, and your mood when you use it. A successful
          quit plan helps you resist your triggers. Think of things you can do
          instead of using tobacco when a trigger happens. Check out{' '}
          <InfoHubLink section="session-three" item="so-now-what">
            So Now What?
          </InfoHubLink>{' '}
          for some additional ideas.
        </p>
        <p>
          <i>For example:</i>
          <br />
          <b>Instead of:</b>{' '}
          <i>Using tobacco as soon as I get up in the morning</i>
          <br />
          <b>I can:</b> <i>Do stretches or yoga as soon as I wake up.</i>
        </p>
        <p>
          <i>For example:</i>
          <br />
          <b>Instead of:</b> <i>Vaping so I don’t snack.</i>
          <br />
          <b>I can:</b> <i>Eat healthy snacks like popcorn, fruit, or yogurt</i>
        </p>
        <p>Now, you try it...</p>
        <AbstractQuestion refKey={'replacement1'} />
        <AbstractQuestion refKey={'replacement2'} />
        <AbstractQuestion refKey={'replacement3'} />
        <AbstractQuestion refKey={'replacement4'} />
      </Section>

      <Section
        title="Making Your Space Tobacco-free"
        completed={isSectionAnswered(`${REF_KEY_BASE}/simpleItems`, data)}
      >
        <p>
          A few simple actions can make quitting easier! Check a few you might
          add to your quit plan.
        </p>
        <p>
          Look at{' '}
          <InfoHubLink
            section="session-three"
            item="making-my-space-tobacco-free"
          >
            Making My Space Tobacco-free
          </InfoHubLink>{' '}
          for additional ideas.
        </p>
        <AbstractQuestion refKey={'simpleItems'} />
      </Section>

      <Section title="Reflect and Relax">
        <p>Relaxation exercises can help you:</p>
        <ul>
          <li>Improve your chances of successfully quitting tobacco</li>
          <li>Reduce stress</li>
          <li>Be more aware of your tobacco habits and cravings</li>
          <li>Improve self-control</li>
        </ul>
        <p>
          Check out the different relaxation exercises in the Info Hub.{' '}
          <InfoHubLink section="relaxation">Give them a try.</InfoHubLink>
        </p>
      </Section>

      <Section
        title="Committed to Quit"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/motivation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/confidence`, data)
        }
      >
        <p>
          Mapping readiness and commitment to quit is proven to help teens quit
          tobacco use. Let’s explore where you are right now. Your answers will
          be saved to your Journey Journal to help you track your progress.
        </p>

        <AbstractQuestion refKey={'motivation'} />
        <AbstractQuestion refKey={'confidence'} />
      </Section>

      <Section
        title="Goal Setting"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/supportPerson`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/supportTellThem`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/remove`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/physical`, data)
        }
      >
        <AbstractQuestion refKey={'supportPerson'} />
        <AbstractQuestion refKey={'supportTellThem'} />
        <AbstractQuestion refKey={'remove'} />
        <AbstractQuestion refKey={'physical'} />
      </Section>
      <Section title="Nic Check">
        <p>
          Every time you use tobacco, track it with <NicCheckLink />. The more
          you track, the more aware you will be about your tobacco use,
          cravings, and triggers, which improves your chances to successfully
          quit tobacco! Your responses will be saved in your <JournalLink />.
          Check out{' '}
          <InfoHubLink
            section="session-three"
            item="nic-check-understanding-my-use"
          >
            Nic-Check - Understanding My Use
          </InfoHubLink>{' '}
          to learn more.
        </p>
      </Section>

      <Section title="Info Hub">
        <p>Check out additional resources for this session!</p>
        <p>
          <InfoHubLink section="session-three" item="the-body-destroyer">
            The Body Destroyer
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink
            section="session-three"
            item="making-my-space-tobacco-free"
          >
            Making My Space Tobacco-free
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-three" item="so-now-what">
            So Now What? What I Can Do Instead of Using Tobacco
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-three" item="rocks-that-dont-roll">
            Rocks that Don't Roll
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-three" item="for-my-support-person">
            For My Support Person
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink
            section="session-three"
            item="nic-check-understanding-my-use"
          >
            Nic-Check - Understanding My Use
          </InfoHubLink>
        </p>
      </Section>

      <Section title="Journey Journal">
        <p>
          <b>You have completed this session,</b> come back next week for your
          next session. You’ll receive a reminder when it’s time to log back in.
        </p>
        <p>
          All of your responses from this session will be saved in your Journey
          Journal. <JournalLink>Click here to review your Journal.</JournalLink>
        </p>
      </Section>
    </Layout>
  );
};

export { Interactive as default, INTERACTIVE_QUESTIONS };
