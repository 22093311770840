import { useState } from 'react';
import firebase from 'utils/fire';

import Button from 'components/Button';

import Layout from 'components/Layout';
import styles from './PasswordReset.module.css';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(' ');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = (e) => {
    e.preventDefault();
    if (email && email.length > 0) {
      setIsLoading(true);
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setIsLoading(false);
          setSuccess(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setErrorMessage(error);
          setSuccess(false);
        });
    } else {
      setErrorMessage('You must enter an email address first');
    }
  };

  return (
    <Layout>
      <form className={styles['wrapper']}>
        <label htmlFor="reset-email">Email</label>
        <input
          id="reset-email"
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Email"
        />

        {!isLoading && !success && (
          <div className={styles['error']}>{errorMessage}</div>
        )}
        {!isLoading && success && (
          <div className={styles['success']}>
            A password reset email has been sent! Please check your inbox.
          </div>
        )}

        <Button
          disabled={isLoading}
          className={styles['reset-button']}
          onClick={(e) => handleReset(e)}
        >
          Reset Password
        </Button>
      </form>
    </Layout>
  );
};

export default PasswordReset;
