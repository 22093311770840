import { Path, Svg } from '@react-pdf/renderer';

const BackgroundLogo = (style = {}) => (
  <Svg viewBox="0 0 34.27 34.4" fill="#D9F0FA" style={style}>
    <Path d="M21.56.58C12.46 4.43 8.18 7.16 0 15.64A17.2 17.2 0 0 1 21.56.58Z" />
    <Path d="M.03 19.3a47.17 47.17 0 0 1 23.66-18c.22.06.4.14.73.32C14.27 7.24 6.97 16.02 4.04 28.36a16.89 16.89 0 0 1-4-9.05ZM19 34.34C17.83 22.1 22.6 12.32 29.56 5.32c7.72 7.56 7.1 26.03-10.56 29.02Z" />
    <Path d="M14.98 34.3a17.48 17.48 0 0 1-4.94-1.38c.96-11.63 6.6-23.1 17.98-29.05.13.1.29.2.56.5C18.07 15.59 16.94 23.82 16.32 34.4c-.05.02-.66-.02-1.34-.1z" />
  </Svg>
);

export default BackgroundLogo;
