import { useJournal } from 'components/JournalContext';

import styles from './JournalLink.module.css';

const JournalLink = ({ children = <>Journey Journal</> }) => {
  const { setShowJournal } = useJournal();
  return (
    <span className={styles['wrapper']} onClick={() => setShowJournal(true)}>
      <span className={styles['text-wrapper']}>{children}</span>
      <span className={styles['icon-wrapper']}>
        <span className={styles['background']} />
        <span className={styles['icon']} alt={`Journal icon`} />
      </span>
    </span>
  );
};

export default JournalLink;
