import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';

import BackgroundLogo from './components/BackgroundLogo';

import logoImg from 'assets/images/ala_logo.png';

// Font URLS retrieved using this method: https://github.com/diegomura/react-pdf/issues/680#issuecomment-695144450
Font.register({
  family: 'Cabin',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7E.ttf',
      fontWeight: 400
    },
    {
      src:
        'https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkbqDL7E.ttf',
      fontWeight: 700
    },
    {
      src:
        'https://fonts.gstatic.com/s/cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHx_Klwg.ttf',
      fontWeight: 400,
      fontStyle: 'italic'
    },
    {
      src:
        'https://fonts.gstatic.com/s/cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHIPWlwg.ttf',
      fontWeight: 700,
      fontStyle: 'italic'
    }
  ]
});

Font.register({
  family: 'Source Sans Pro',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf',
      fontWeight: 400
    },
    {
      src:
        'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf', // Actually weight 600
      // 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkA.ttf',
      fontWeight: 700
    },
    {
      src:
        'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7g.ttf',
      fontWeight: 400,
      fontStyle: 'italic'
    },
    {
      src:
        'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdr.ttf', // Actually weight 600
      // 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdr.ttf',
      fontWeight: 700,
      fontStyle: 'italic'
    }
  ]
});

/*
--primary: #0064ff; Trusted Champion Blue 
--primary-darkened: #0057da;

--primary-contrast: #ffffff;  White 

--secondary: #002158;  Midnight

 Primary accent colors 
--accent-a: #d1e33b;  Lime 
--accent-a-darkened: #c8d838;

--accent-b: #bdd3fa;  Sky 
 Secondary accent colors 
--accent-c: #eb8ced;  Orchid 
--accent-d: #b2e1d8;  Sea Foam 
--accent-e: #87414c;  Plum 
*/

// const colors = {
//   primary: '#0064ff',
//   secondary: '#002158'
// };

const CertificatePDF = ({ name }) => {
  return (
    <Document>
      <Page
        size="letter"
        orientation="landscape"
        style={{
          fontFamily: 'Source Sans Pro',
          fontSize: '14px',
          backgroundColor: 'white',
          padding: '30px'
        }}
      >
        <View
          style={{
            width: '100%',
            backgroundColor: '#ABDEF4',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <Image
            src={logoImg}
            style={{
              margin: '20px 0',
              width: '60%'
            }}
          />
        </View>
        <Text
          style={{
            fontFamily: 'Cabin',
            fontWeight: 'bold',
            fontSize: '28px',
            marginTop: '35px',
            marginBottom: '5px',
            textAlign: 'center',
            color: '#1C2858'
          }}
        >
          Certificate of Completion
        </Text>

        <Text
          style={{
            fontFamily: 'Cabin',
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '10px',
            textAlign: 'center'
          }}
        >
          This certificate is awarded to
        </Text>
        <View style={{ width: '100%', height: '40vh' }}>
          <BackgroundLogo />
          <View
            style={{
              position: 'absolute',
              top: '50%',
              left: '0',
              right: '0'
            }}
          >
            <Text
              style={{
                fontFamily: 'Cabin',
                fontWeight: 'bold',
                fontSize: '28px',
                textAlign: 'center',
                color: '#1C2858',
                width: '100%',
                top: '-50%'
              }}
            >
              {name}
            </Text>
          </View>
        </View>
        <Text
          style={{
            fontFamily: 'Cabin',
            fontWeight: 'bold',
            fontSize: '18px',
            marginTop: '15px',
            marginBottom: '15px',
            textAlign: 'center'
          }}
        >
          Congratulations on your efforts to be tobacco-free!
        </Text>
        <View
          style={{
            marginTop: '5px',
            width: '100%',
            height: '25px',
            backgroundColor: '#1C2858'
          }}
        ></View>
      </Page>
    </Document>
  );
};

export default CertificatePDF;
