import { Text } from '@react-pdf/renderer';

import Section from '../Section';
import Bullets from '../Bullets';

const colors = {
  primary: '#0064ff',
  secondary: '#002158'
};

const SessionSummary = ({ header, bullets }) => (
  <Section>
    <Text
      style={{
        fontFamily: 'Cabin',
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: '15px',
        color: colors.secondary
      }}
    >
      {header}
    </Text>
    <Text
      style={{
        fontFamily: 'Cabin',
        fontSize: '16px',
        fontWeight: 'bold',
        color: colors.primary,
        // marginBottom: '5px',
        fontStyle: 'italic'
      }}
    >
      Session Summary
    </Text>
    <Bullets bullets={bullets} />
    {/* {bullets.map((bullet, i) => (
      <View key={i} style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
        <Bullet />
        <Text style={{ marginLeft: '3px' }}>{bullet}</Text>
      </View>
    ))} */}
  </Section>
);

export default SessionSummary;
