import { Text } from '@react-pdf/renderer';

import Section from '../../components/Section';

const Intro = () => (
  <>
    <Section>
      <Text>
        You’ve taken a big step in starting your journey in becoming
        tobacco-free.
      </Text>
    </Section>
    <Section>
      <Text>
        There are eight sessions to complete at your own pace throughout the
        program. You will quit halfway through at session 4. Remember, the more
        sessions you complete, the greater your odds are of quitting.
      </Text>
    </Section>
    <Section>
      <Text>
        Information that you enter during each session will be displayed within
        your journal for you to review at any time. Click the icon to view.
      </Text>
    </Section>
    <Section>
      <Text>
        There are additional resources and takeaways located in the info-hub.
      </Text>
    </Section>
  </>
);

export default Intro;
