import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import InfoHubLink from 'components/InfoHubLink';
import JournalLink from 'components/JournalLink';

import { isSectionAnswered } from 'utils/progressCalculator';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const Interactive = () => {
  const { data } = useAuthenticationData();
  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/post-program-survey"
          text="Continue to Staying On Course Survey"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Session 8: Staying on Course"
      subTitle="Explore More"
      Background={null}
    >
      <Section
        title="Self-Portrait"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/descriptionBefore`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/descriptionAfter`, data)
        }
      >
        <AbstractQuestion refKey={'descriptionBefore'} />
        <AbstractQuestion refKey={'descriptionAfter'} />
      </Section>
      <Section
        title="Tips for Staying on Course"
        completed={isSectionAnswered(`${REF_KEY_BASE}/characteristics`, data)}
      >
        <p>Check at least 5 you plan to use...</p>
        <AbstractQuestion refKey={'characteristics'} />
      </Section>
      <Section title="Reflect/Relax">
        <p>
          Remember, relaxation is especially important as your body is going
          through the quitting journey. You can return to the Relaxation hub,
          any time to continue to practice any of these exercises.
        </p>
      </Section>
      <Section
        title="Committed to Quit"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/motivation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/confidence`, data)
        }
      >
        <p>
          Mapping readiness and commitment to quit is proven to help teens quit
          tobacco use. Let’s take a closer look at where you are right now. Your
          answers will be saved in your <JournalLink /> to help you track your
          progress.
        </p>
        <AbstractQuestion refKey={'motivation'} />
        <AbstractQuestion refKey={'confidence'} />
      </Section>
      <Section
        title="Goal Setting"
        completed={
          isSectionAnswered(`${REF_KEY_BASE}/goalReward`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalMeditation`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalWhoWillYouTell`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalWhatWillYouTellThem`, data) &&
          isSectionAnswered(`${REF_KEY_BASE}/goalExercise`, data)
        }
      >
        <p>
          Make a list of characteristics that describe you before you started
          this program, as a tobacco user. Then, make a list of how you see
          yourself after quitting tobacco.
        </p>

        <AbstractQuestion refKey={'goalReward'} />
        <AbstractQuestion refKey={'goalMeditation'} />
        <AbstractQuestion refKey={'goalWhatWillYouTellThem'} />
        <AbstractQuestion refKey={'goalExercise'} />

        <p>
          You’re encouraged to continue to set weekly goals for yourself to keep
          on track. Remember your quit journey continues beyond this program as
          you practice the skills you’ve learned. You can revisit these sessions
          anytime you need a refresher.{' '}
        </p>
      </Section>
      <Section title="Info Hub">
        <p>Check out additional resources for this session!</p>
        <p>
          <InfoHubLink section="session-eight" item="my-signs-of-healing">
            My Signs of Healing
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-eight" item="stretch-time">
            Stretch Time
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-eight" item="snack-time">
            Snack Time!
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink
            section="session-eight"
            item="healthier-food-alternatives"
          >
            Healthier Food Alternatives
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-eight" item="healthy-habits">
            Healthy Habits
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink section="session-eight" item="staying-on-track">
            Staying on Track
          </InfoHubLink>
        </p>
        <p>
          <InfoHubLink
            section="session-eight"
            item="celebrate-every-little-step"
          >
            Celebrate Every Little Step
          </InfoHubLink>
        </p>
      </Section>
    </Layout>
  );
};

export { Interactive as default, INTERACTIVE_QUESTIONS };
