import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import styles from './Replacement.module.css';

const Replacement = ({
  questionData: { required = true },
  answerData: { original = '', replacement = '', isCompleted },
  baseUpdate
}) => {
  const [originalId] = useState(uuid());
  const [replacementId] = useState(uuid());
  const updateOriginal = baseUpdate(`original`);
  const updateReplacement = baseUpdate(`replacement`);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['replacements']}>
        <span className={styles['replacement-segment-wrapper']}>
          <label htmlFor={originalId}>Instead of:</label>
          <input
            id={originalId}
            value={original}
            type="text"
            onChange={(e) =>
              updateOriginal(
                e.target.value,
                replacement?.length > 0 && e.target.value?.length > 0
              )
            }
          />
        </span>

        <span className={styles['replacement-segment-wrapper']}>
          <label htmlFor={replacementId}>I can:</label>
          <input
            id={replacementId}
            value={replacement}
            type="text"
            onChange={(e) =>
              updateReplacement(
                e.target.value,
                original?.length && e.target.value?.length > 0
              )
            }
          />
        </span>
      </div>
      <span className={styles.error}>
        {required && !isCompleted ? `Response required` : ' '}
      </span>
    </div>
  );
};

export default Replacement;
