import Layout, { NextPageButton } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';

const Video = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-one-interactive" />}
    title="Session 1: Getting The Facts"
    subTitle="Video"
  >
    <VideoEmbed videoId="XEo3Zbscjuk" title="Session One Video" />
  </Layout>
);

export default Video;
