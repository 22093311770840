import { useHistory } from 'react-router-dom';

import styles from './Flag.module.css';

const FlagPole = () => (
  <>
    <rect x="21.14" y="4.91" fill="#D0D2D3" width="3.2" height="158.1" />
    <rect x="23.24" y="4.91" fill="#BBBDBF" width="1.2" height="158.1" />
    <circle fill="#BBBDBF" cx="22.74" cy="2.91" r="2.9" />
    <path
      fill="#FFFFFF"
      d="m23.14 0.71c0.2 0.3-0.1 1-0.8 1.5-0.7 0.5-1.4 0.7-1.7 0.3-0.2-0.3 0.1-1 0.8-1.5 0.7-0.5 1.4-0.7 1.7-0.3z"
    />
    <rect x="7.44" y="163.01" fill="#E6E7E8" width="31" height="5.4" />
    <rect x="3.64" y="168.41" fill="#D0D2D3" width="38.2" height="5.4" />
    <rect x="0.04" y="173.81" fill="#BBBDBF" width="45.4" height="5.4" />
  </>
);

const FlagCloth = ({ raised, url }) => {
  const history = useHistory();
  return (
    <path
      onClick={() => {
        if (raised) {
          history.push(url);
        }
      }}
      className={[styles['flag'], styles[raised ? 'raised' : 'lowered']].join(
        ' '
      )}
      d={`m96 50.51c-3.9 0.7-7.9 3-11.9 3-2.8 0-5.4-1.4-8-2.2-6.4-2-10.9 2.7-17.1 2.1-3.4-0.3-6.3-2.6-9.7-2.8-2.8-0.2-5.1 0.8-7.6 1.7-6.5 2.3-10.9-0.7-17.3-1.7 0-14.3 0-28.6 0-43 6.4 1 10.8 4 17.3 1.7 2.6-0.9 4.8-1.9 7.6-1.7 3.4 0.3 6.3 2.6 9.7 2.9 6.2 0.5 10.7-4.1 17.1-2.1 2.6 0.8 5.2 2.2 8 2.2 4 0 7.9-2.4 11.9-3 0 14.3 0 28.6 0 43z`}
    />
  );
};

const FlagText = ({ text, raised }) => (
  <text
    className={[
      styles['flag-text'],
      styles[raised ? 'raised' : 'lowered']
    ].join(' ')}
    x="58"
    y="44"
  >
    {text}
  </text>
);

const Flag = ({ raised, text, x, y, url }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1920 1080"
    className={styles['flag-container']}
  >
    <g transform={`translate(${x} ${y})`}>
      <FlagPole />
      <FlagCloth raised={raised} url={url} />
      <FlagText text={text} raised={raised} />
    </g>
  </svg>
);

export default Flag;
