import styles from './Collapsible.module.css';

const Collapsible = ({ header, collapsed, setCollapsed, children }) => (
  <div
    className={[
      styles['collapsible'],
      styles[collapsed ? 'collapsed' : 'expanded']
    ].join(' ')}
  >
    <h4 className={styles['header']} onClick={() => setCollapsed(!collapsed)}>
      {header} <div className={styles['caret']} />
    </h4>
    <div className={styles['content']}>{children}</div>
  </div>
);

export default Collapsible;
