import { Text } from '@react-pdf/renderer';

import Session from '../../components/Session';
import Section from '../../components/Section';
import SessionSummary from '../../components/SessionSummary';
import MultiselectDisplay from '../../components/MultiselectDisplay';
import LikertPairDisplay from '../../components/LikertPairDisplay';
import RelaxationHub from '../../components/RelaxationHub';
import SayItLoud from '../../components/SayItLoud';

import SESSION_FIVE_QUESTIONS from 'pages/SessionFive/data';

const SessionFive = ({ data }) => {
  const {
    cravingAlternatives,
    rewards,
    motivation,
    confidence,
    goalChampions,
    goalPepTalk,
    goalExercise
  } = data;
  return (
    <Session>
      <SessionSummary
        header="Session 5: Gaining Momentum"
        bullets={[
          'Reflecting on your experiences of becoming tobacco-free',
          'Avoiding slips and risky situations',
          'Rewarding yourself'
        ]}
      />
      <Section header="Explore More">
        {cravingAlternatives?.isCompleted && (
          <>
            <Text style={{ fontWeight: 'bold' }}>
              Seeing It Through: Dealing with Urges and Cravings:
            </Text>
            <Text>
              When you have an urge to use tobacco here are some things you can
              do in the moment:
            </Text>
            <MultiselectDisplay
              question={SESSION_FIVE_QUESTIONS.cravingAlternatives}
              data={cravingAlternatives}
            />
          </>
        )}
        {rewards?.isCompleted && (
          <>
            <Text style={{ fontWeight: 'bold' }}>
              Seeing It Through: My Reward List:
            </Text>
            <Text>
              Quitting is a big deal. Don’t forget to reward yourself. Check off
              some rewards that you will give to yourself for quitting tobacco
              (remember, you are saving money now, too)!
            </Text>
            <MultiselectDisplay
              question={SESSION_FIVE_QUESTIONS.rewards}
              data={rewards}
            />
          </>
        )}
      </Section>
      <Section header="Committed to Quit">
        <LikertPairDisplay motivation={motivation} confidence={confidence} />
      </Section>
      <Section header="My Goals">
        {goalChampions?.isCompleted && (
          <>
            <Text>
              1. The 2 people who are my biggest champions during this part of
              my quit journey are:
            </Text>
            <Text>{goalChampions.value}</Text>
          </>
        )}
        {goalPepTalk?.isCompleted && (
          <>
            <Text>
              2. Here is my “pep talk” phrase (positive self-talk) that I will
              use at least once a day over the next 7 days:
            </Text>
            <Text>{goalPepTalk.value}</Text>
          </>
        )}
        {goalExercise?.isCompleted && (
          <>
            <Text>
              3. The exercise that I will do for at least 15 minutes on 6 days
              this week and 20 minutes on 1 day is:
            </Text>
            <Text>{goalExercise.value}</Text>
          </>
        )}
      </Section>
      <Section header="My Reminders">
        <Text>
          Remember to track my tobacco use with Nic Check if I slip. Check out
          the Relaxation Center and additional resources on the Info Hub.
        </Text>
        <RelaxationHub
          bullets={['Dealing with Urges and Cravings', 'My Rewards List']}
        />
      </Section>
      <SayItLoud phrase="The tough times are temporary." />
    </Session>
  );
};

export default SessionFive;
