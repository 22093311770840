import React, { useState } from 'react';

// For reference: https://kentcdodds.com/blog/how-to-use-react-context-effectively

const JournalContext = React.createContext(undefined);

const JournalProvider = ({ children }) => {
  const [showJournal, setShowJournal] = useState(false);
  return (
    <JournalContext.Provider value={{ showJournal, setShowJournal }}>
      {children}
    </JournalContext.Provider>
  );
};

const useJournal = () => {
  const nicCheck = React.useContext(JournalContext);
  if (nicCheck === undefined) {
    throw new Error(`useJournal must be used within a JournalProvider`);
  }
  return nicCheck;
};

export { JournalProvider, useJournal };
