import { Text, View } from '@react-pdf/renderer';

const NumberedList = ({ items }) => (
  <>
    {items.map((item, i) => (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '21px',
          marginBottom: '5px' // TODO - this ends up giving extra space to the end of Sections
        }}
      >
        <Text>{i + 1}.</Text>
        <View style={{ marginLeft: '4px' }}>{item}</View>
      </View>
    ))}
  </>
);

export default NumberedList;
