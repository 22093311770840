import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import { getAnswerData } from 'utils/data';
import { useAuthenticationData } from 'components/AuthenticationContext';

import { buildAbstractQuestion } from 'components/AbstractQuestion';
import INTERACTIVE_QUESTIONS, { REF_KEY_BASE } from './data';

const AbstractQuestion = buildAbstractQuestion(
  INTERACTIVE_QUESTIONS,
  REF_KEY_BASE
);

const buildIsMultiselectOptionSelected = (data, questionKey) => (optionKey) =>
  !!getAnswerData(data, questionKey)[optionKey]?.selected;

const PreEval = () => {
  const { data } = useAuthenticationData();

  const { isCompleted = false } = data?.[REF_KEY_BASE] || {};

  const isTobaccoProductSelected = buildIsMultiselectOptionSelected(
    data,
    `${REF_KEY_BASE}/tobaccoTypes`
  );

  const isECigOtherSelected = buildIsMultiselectOptionSelected(
    data,
    `${REF_KEY_BASE}/eCigBrands`
  )('other');

  return (
    <Layout
      nextPageButton={
        <NextPageButton
          url="/landing"
          disabled={!data?.[REF_KEY_BASE]?.isCompleted}
        />
      }
      title="Beginning My Journey"
      subTitle="Survey"
      Background={null}
    >
      <Section>
        <p>
          As part of NOT for Me, we will help you track your quit journey. By
          answering these questions, you can better understand your habits and
          attitudes towards tobacco products and your motivation and confidence
          to quit right now. We will also ask you similar questions at the end
          of the program so you can see the strides you make.
        </p>
        <p>
          Not only will this help you, it will help the American Lung
          Association evaluate the program success with all teens who use it.
          For privacy, your name will never be connected to the evaluation
          results.
        </p>
      </Section>
      <Section>
        <AbstractQuestion refKey={'tobaccoTypes'} />
        {isTobaccoProductSelected('other') && (
          <AbstractQuestion refKey={'otherTobaccoType'} />
        )}

        <AbstractQuestion refKey={'flavoredTobacco'} />

        {isTobaccoProductSelected('eCigarettes') && (
          <>
            <AbstractQuestion refKey={'eCigTypes'} />
            <AbstractQuestion refKey={'eCigBrands'} />
          </>
        )}
        {isTobaccoProductSelected('eCigarettes') && isECigOtherSelected && (
          <AbstractQuestion refKey={'eCigOtherBrand'} />
        )}

        {isTobaccoProductSelected('eCigarettes') && (
          <AbstractQuestion refKey={'eCigUsageDays'} />
        )}
        {isTobaccoProductSelected('cigarettes') && (
          <AbstractQuestion refKey={'cigaretteUsageDays'} />
        )}
        {isTobaccoProductSelected('smokeless') && (
          <AbstractQuestion refKey={'smokelessUsageDays'} />
        )}
        {isTobaccoProductSelected('cigars') && (
          <AbstractQuestion refKey={'cigarUsageDays'} />
        )}
        {isTobaccoProductSelected('hookah') && (
          <AbstractQuestion refKey={'hookahUsageDays'} />
        )}

        {(isTobaccoProductSelected('cigarettes') ||
          isTobaccoProductSelected('cigars')) && (
          <AbstractQuestion refKey={'smokePerDay'} />
        )}

        {isTobaccoProductSelected('eCigarettes') && (
          <AbstractQuestion refKey={'eCigPerDay'} />
        )}

        {isTobaccoProductSelected('smokeless') && (
          <AbstractQuestion refKey={'smokelessPerDay'} />
        )}

        {isTobaccoProductSelected('other') && (
          <AbstractQuestion refKey={'otherUsageDays'} />
        )}

        {/* NOTE: the other flavored question would go here */}

        {/* {isTobaccoProductSelected('eCigarettes') && (
          <AbstractQuestion refKey={'nicotinePercentage'} />
        )} */}
        <AbstractQuestion refKey={'anyoneInLifeTobacco'} />
        <AbstractQuestion refKey={'howSoonAfterWaking'} />
        <AbstractQuestion refKey={'reachingForTobacco'} />
        <AbstractQuestion refKey={'outOfMyWay'} />
        <AbstractQuestion refKey={'moreBeforeDisallowed'} />
        <AbstractQuestion refKey={'intolerableCravings'} />
        <AbstractQuestion refKey={'motivation'} />
        <AbstractQuestion refKey={'confidence'} />
      </Section>
      <Section>
        <p>
          Thank you for answering these questions! Your answers will also be
          saved anonymously to help evaluate this program. We will never connect
          your responses back to you.
        </p>
        {!isCompleted && (
          <p className="error">
            Please answer all questions above to continue to next page.
          </p>
        )}
      </Section>
    </Layout>
  );
};

export default PreEval;
