import Layout from 'components/Layout';
import Section from 'components/Section';

const TermsAndConditions = () => (
  <Layout
    title="NOT for Me Terms and Conditions"
    subTitle="About NOT For Me Data Collection and Evaluation"
    Background={null}
  >
    <Section>
      <p>
        NOTforMe.org and the NOT for Me program are managed by the American Lung
        Association. Therefore, use of this site is in accordance with the{' '}
        <a href="https://www.lung.org/policies/terms-of-use">
          American Lung Association’s Website Policies
        </a>
        .
      </p>
    </Section>
    <Section title="What to Expect">
      <p>
        We will ask reflection questions before you start the program and again
        at the end of the program. In each session we ask that you tell us about
        your tobacco use and your readiness to quit to track your progress in
        quitting.
      </p>
      <p>
        Activities and reflection questions asked in each session will help you
        understand your tobacco use and quit journey.
      </p>
      <p>
        All of your responses throughout the program will be saved in your NOT
        For Me Journal for you to look at whenever you want so you can see your
        progress on your quest towards quitting tobacco!
      </p>
    </Section>
    <Section title="What Do We Ask, and Why?">
      <p>We ask questions about:</p>
      <ul>
        <li>what products you use</li>
        <li>how often you use them</li>
        <li>your nicotine dependency</li>
        <li>your readiness to quit tobacco</li>
      </ul>
      <p>
        We ask these questions so that you can see how your habits change over
        time. We also want to see how well this program works to help teens quit
        tobacco.
      </p>
    </Section>
    <Section title="How Do We Use Your Feedback?">
      <p>
        Research & Evaluation Group at Public Health Management Corporation (R&E
        Group) is an independent evaluation company with experience in
        evaluating and supporting programs like NOT For Me. As national
        evaluation partners with the American Lung Association, R&E Group
        evaluates many of their health promotions programs.
      </p>
      <p>
        Information collected from all users of this program will be used to:
      </p>
      <ul>
        <li>examine how effective this program is</li>
        <li>improve the program in the future</li>
      </ul>
      <p>
        Your responses will not be connected to your name or any personal
        information that could be used to identify you when R&E Group is looking
        at feedback.
      </p>
      <p>
        We will evaluate feedback from all users together to measure the success
        of this program overall. Information about who is enrolled in the
        program (race, ethnicity, gender, sexual orientation, etc.) may be used
        so that we can work to make this program available to others interested
        in quitting who may share these characteristics.
      </p>
      <p>
        The American Lung Association and R&E Group may share overall program
        data (outcomes) with health professionals and public health networks to
        promote the program and help more teens quit tobacco!
      </p>
    </Section>
    <Section title="Who Sees This Information?">
      <p>
        The information you enter as part of this program will be accessible by
        R&E Group. Identifiable information you enter like your name, age, zip
        code, and email address in your profile or the names of friends and
        family as part of your Journal entries will be removed by R&E Group for
        your privacy if your information is used to evaluate the program.
      </p>
      <p>
        Remember, when you are online, information you enter is not private.
        While your information will always be used anonymously, it is important
        for you to think about how comfortable you are sharing information
        online.
      </p>
      <p>
        If you are uncomfortable entering information into this web-based
        program, you can use a paper journal or your phone to record your
        responses. This way, your information will be only for you. While R&E
        Group wants to use feedback and information to make this program useful
        for all teens, this journey is for you first.
      </p>
    </Section>
  </Layout>
);

export default TermsAndConditions;
