import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      // const { pathname: currentPathname } = history.location;
      // console.log(action, currentPathname, newPathname);
      // if (action === 'PUSH' && currentPathname !== newPathname) {
      //   window.scrollTo(0, 0);
      // }
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
    return () => unlisten();
  }, [history]);
  return null;
};

export default ScrollToTop;
