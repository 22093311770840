import Layout, { NextPageButton } from 'components/Layout';
import Section from 'components/Section';

import IntroBackground from 'components/Layout/components/IntroBackground';

import NicCheckLink from 'components/NicCheckLink';
import JournalLink from 'components/JournalLink';

const Intro = () => (
  <Layout
    nextPageButton={<NextPageButton url="/session-three-video" />}
    title="Session 3: Getting Ready"
    subTitle="Session Introduction"
    Background={IntroBackground}
    showChildrenBackground={false}
  >
    <Section>
      <p>Welcome back!</p>
      <p>
        Take a look at your goals in your <JournalLink /> from last week. How
        did you do? If you didn’t reach all of your goals, keep trying. Your
        goals are getting you ready to quit. Everyone has a different experience
        in their quit journey. Here, you will learn more about what to expect
        with yours!
      </p>

      <p>
        Did you track your tobacco use with <NicCheckLink />? What did you learn
        from your tracking?
      </p>

      <p>Here’s what we will explore in this session:</p>

      <ul>
        <li>
          Understanding how tobacco affects your body, how you think and feel,
          and your relationships with others
        </li>
        <li>Identifying people who are supportive of your quit journey</li>
        <li>Preparing your space to be tobacco-free</li>
      </ul>
    </Section>
  </Layout>
);

export default Intro;
