import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';

import { REF_KEY_BASE as SESSION_ONE_REF_KEY } from 'pages/SessionOne/data';
import { REF_KEY_BASE as SESSION_TWO_REF_KEY } from 'pages/SessionTwo/data';
import { REF_KEY_BASE as SESSION_THREE_REF_KEY } from 'pages/SessionThree/data';
import { REF_KEY_BASE as SESSION_FOUR_REF_KEY } from 'pages/SessionFour/data';
import { REF_KEY_BASE as SESSION_FIVE_REF_KEY } from 'pages/SessionFive/data';
import { REF_KEY_BASE as SESSION_SIX_REF_KEY } from 'pages/SessionSix/data';
import { REF_KEY_BASE as SESSION_SEVEN_REF_KEY } from 'pages/SessionSeven/data';
import { REF_KEY_BASE as SESSION_EIGHT_REF_KEY } from 'pages/SessionEight/data';

import Intro from './pages/Intro';
import SessionOne from './pages/SessionOne';
import SessionTwo from './pages/SessionTwo';
import SessionThree from './pages/SessionThree';
import SessionFour from './pages/SessionFour';
import SessionFive from './pages/SessionFive';
import SessionSix from './pages/SessionSix';
import SessionSeven from './pages/SessionSeven';
import SessionEight from './pages/SessionEight';

import logoImg from 'assets/images/ala_logo.png';

// Font URLS retrieved using this method: https://github.com/diegomura/react-pdf/issues/680#issuecomment-695144450
Font.register({
  family: 'Cabin',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7E.ttf',
      fontWeight: 400
    },
    {
      src:
        'https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkbqDL7E.ttf',
      fontWeight: 700
    },
    {
      src:
        'https://fonts.gstatic.com/s/cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHx_Klwg.ttf',
      fontWeight: 400,
      fontStyle: 'italic'
    },
    {
      src:
        'https://fonts.gstatic.com/s/cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHIPWlwg.ttf',
      fontWeight: 700,
      fontStyle: 'italic'
    }
  ]
});

Font.register({
  family: 'Source Sans Pro',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf',
      fontWeight: 400
    },
    {
      src:
        'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf', // Actually weight 600
      // 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkA.ttf',
      fontWeight: 700
    },
    {
      src:
        'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7g.ttf',
      fontWeight: 400,
      fontStyle: 'italic'
    },
    {
      src:
        'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdr.ttf', // Actually weight 600
      // 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdr.ttf',
      fontWeight: 700,
      fontStyle: 'italic'
    }
  ]
});

/*
--primary: #0064ff; Trusted Champion Blue 
--primary-darkened: #0057da;

--primary-contrast: #ffffff;  White 

--secondary: #002158;  Midnight

 Primary accent colors 
--accent-a: #d1e33b;  Lime 
--accent-a-darkened: #c8d838;

--accent-b: #bdd3fa;  Sky 
 Secondary accent colors 
--accent-c: #eb8ced;  Orchid 
--accent-d: #b2e1d8;  Sea Foam 
--accent-e: #87414c;  Plum 
*/

// const colors = {
//   primary: '#0064ff',
//   secondary: '#002158'
// };

const JournalPDF = ({ data }) => {
  return (
    <Document>
      <Page
        size="letter"
        style={{
          fontFamily: 'Source Sans Pro',
          fontSize: '14px',
          backgroundColor: 'white',
          padding: '30px'
        }}
      >
        <View style={{ width: '100%' }}>
          <Image
            src={logoImg}
            style={{
              margin: '0 auto',
              width: '60%'
            }}
          />
        </View>
        <Text
          style={{
            fontFamily: 'Cabin',
            fontWeight: 'bold',
            fontSize: '20px',
            marginTop: '15px',
            marginBottom: '15px',
            textAlign: 'center'

            // color: 'navy'
          }}
        >
          JOURNEY JOURNAL
        </Text>

        <Intro />

        <View
          style={{
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            marginBottom: '15px'
          }}
        />

        {data[SESSION_ONE_REF_KEY] && (
          <SessionOne data={data[SESSION_ONE_REF_KEY]} />
        )}

        {data[SESSION_TWO_REF_KEY] && (
          <SessionTwo data={data[SESSION_TWO_REF_KEY]} />
        )}

        {data[SESSION_THREE_REF_KEY] && (
          <SessionThree data={data[SESSION_THREE_REF_KEY]} />
        )}

        {data[SESSION_FOUR_REF_KEY] && (
          <SessionFour data={data[SESSION_FOUR_REF_KEY]} />
        )}

        {/* <QuitPlanRoadMap /> */}

        {data[SESSION_FIVE_REF_KEY] && (
          <SessionFive data={data[SESSION_FIVE_REF_KEY]} />
        )}

        {data[SESSION_SIX_REF_KEY] && (
          <SessionSix data={data[SESSION_SIX_REF_KEY]} />
        )}

        {data[SESSION_SEVEN_REF_KEY] && (
          <SessionSeven data={data[SESSION_SEVEN_REF_KEY]} />
        )}

        {data[SESSION_EIGHT_REF_KEY] && (
          <SessionEight data={data[SESSION_EIGHT_REF_KEY]} />
        )}
      </Page>
    </Document>
  );
};

// TODO: don't display unstarted sessions' content in PDF
// if started a session, for now display all content in that session

// const QuitPlanRoadMap = ({ data }) => {
//   // const {} = data;
//   return (
//     <>
//       <Text style={styles['sessionHeading']}>My Quit Plan Road Map</Text>
//       <Text>???</Text>
//     </>
//   );
// };

export default JournalPDF;
