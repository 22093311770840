import React from 'react';
import Header from 'components/Header';
import { default as DefaultFooter } from 'components/Footer';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';

import { NicCheckProvider } from 'components/NicCheckContext';
import { JournalProvider } from 'components/JournalContext';

import NicCheckModal from 'pages/NicCheckModal';
import JournalModal from 'pages/JournalModal';
import styles from './Layout.module.css';

import DefaultBackground from './components/Background';

const DefaultContentWrapper = ({
  title,
  subTitle,
  children,
  showChildrenBackground
}) => {
  const childrenArray = React.Children.toArray(children);
  const modifiedChildren = childrenArray.map((child, i) => (
    <div key={i} className={styles['section-content']}>
      {child}
    </div>
  ));
  return (
    <>
      {title && <div className={styles['title']}>{title}</div>}
      <div className={styles['content']}>
        {children && (
          <>
            {subTitle && <div className={styles['subtitle']}>{subTitle}</div>}
            <div
              className={
                showChildrenBackground
                  ? [styles['children'], styles['children-background']].join(
                      ' '
                    )
                  : styles['children']
              }
            >
              {modifiedChildren}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const InteractiveContentWrapper = ({
  title,
  subTitle,
  children,
  showChildrenBackground
}) => {
  // console.log('children', children);
  // console.log('children array', React.Children.toArray(children));

  const childrenArray = React.Children.toArray(children);

  const hasCompletedProp = childrenArray.some(
    ({ props }) => props?.completed !== undefined
  );

  if (!hasCompletedProp) {
    return (
      <DefaultContentWrapper
        title={title}
        subTitle={subTitle}
        showChildrenBackground={showChildrenBackground}
      >
        {children}
      </DefaultContentWrapper>
    );
  }

  // const children = Array.isArray(children) ? children : [children];

  let completedSections = [];
  let uncompletedSections = [];

  // let lastCompletedIndex = 0;
  // for (let i = children.length - 1; i >= 0; i--) {
  //   const child = childrenArray[i];
  //   const { completed } = child.props;

  //   if (completed && lastCompletedIndex === 0) {
  //     lastCompletedIndex = i;
  //   }

  //   if (lastCompletedIndex >= i) {
  //     completedSections.unshift(child);
  //   } else {
  //     uncompletedSections.unshift(child);
  //   }
  // }

  let firstUncompletedIndex = children.length - 1;
  for (let i = 0; i < children.length; i++) {
    const child = childrenArray[i];
    const { completed } = child.props;

    if (
      firstUncompletedIndex === children.length - 1 &&
      completed !== undefined &&
      completed === false
    ) {
      firstUncompletedIndex = i;
    }

    if (i < firstUncompletedIndex) {
      completedSections.push(child);
    } else {
      uncompletedSections.push(child);
    }
  }

  // console.log('completedSections', completedSections);

  const modifiedChildren = (
    <>
      {[...completedSections, ...uncompletedSections].map((child, index) => (
        <React.Fragment key={index}>
          {child.props?.completed !== undefined && (
            <div
              style={{ gridRow: `${index + 1} / ${index + 2}` }}
              className={[
                styles['section-pennant'],
                styles[`pennant-color-${(index % 5) + 1}`]
              ].join(' ')}
            />
          )}
          <div className={styles['section-content']}>{child}</div>
        </React.Fragment>
      ))}
    </>
  );

  // dashed line should be completed up to the first uncompleted section with a completed prop

  // console.log('completedSections length', completedSections.length);

  const childrenStyle = {
    gridTemplateRows: `[rows-start] ${
      completedSections.length > 0
        ? `repeat(${completedSections.length}, auto) [completed-rows-end]`
        : ``
    } repeat(${uncompletedSections.length}, auto) [rows-end]`
  };

  return (
    <>
      {title && <div className={styles['title']}>{title}</div>}
      <div className={styles['content']}>
        {children && (
          <>
            {subTitle && <div className={styles['subtitle']}>{subTitle}</div>}
            <div
              style={childrenStyle}
              className={
                showChildrenBackground
                  ? [styles['children'], styles['children-background']].join(
                      ' '
                    )
                  : styles['children']
              }
            >
              <div className={styles['lines-background']}></div>
              {modifiedChildren}

              {completedSections.length > 0 && (
                <div className={styles['lines-background-completed']}></div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const NextPageButton = ({ url, text = 'Continue', disabled = false }) => {
  const history = useHistory();
  return (
    <Button
      disabled={disabled}
      // className={styles['continue-button']}
      onClick={() => history.push(url)}
    >
      {text}
    </Button>
  );
};

const Layout = ({
  Background = DefaultBackground,
  Footer = DefaultFooter,
  ContentWrapper = InteractiveContentWrapper,
  nextPageButton = null,
  showChildrenBackground = true,
  children,
  ...rest
}) => (
  <JournalProvider>
    <NicCheckProvider>
      <NicCheckModal />
      <JournalModal />
      <div className={styles['wrapper']}>
        {Background && <Background />}
        <Header />
        <ContentWrapper
          showChildrenBackground={showChildrenBackground}
          {...rest}
        >
          {children}
        </ContentWrapper>
        {Footer && <Footer>{nextPageButton}</Footer>}
      </div>
    </NicCheckProvider>
  </JournalProvider>
);

export { Layout as default, NextPageButton };
