import { Text, Svg, Circle, View } from '@react-pdf/renderer';

const colors = {
  primary: '#0064ff',
  secondary: '#002158'
};

const Bullet = () => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill={colors.secondary}>
    <Circle cx="8" cy="9" r="4" />
  </Svg>
);

const Bullets = ({ bullets }) => (
  <>
    {bullets.map((bullet, i) => (
      <View
        key={i}
        style={{ display: 'flex', flexDirection: 'row', marginLeft: '15px' }}
      >
        <Bullet />
        <Text style={{ marginLeft: '3px' }}>{bullet}</Text>
      </View>
    ))}
  </>
);

export default Bullets;
