import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import NumberedList from '../NumberedList';

// Expects items of the form [{ question: string, answer: string }, {}, ...]
const MultilineNumberedList = ({ items }) => (
  <NumberedList
    items={items.map((item) =>
      React.isValidElement(item) ? (
        item
      ) : (
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ fontWeight: 'bold' }}>{item.question}</Text>
          <Text>{item.answer}</Text>
        </View>
      )
    )}
  />
);

export default MultilineNumberedList;
