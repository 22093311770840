import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'utils/fire';

import { useNicCheck } from 'components/NicCheckContext';
import { useJournal } from 'components/JournalContext';
import { useIsAuthenticated } from 'components/AuthenticationContext';

import logoImg from 'assets/images/ala_logo.png';
import profileImg from 'assets/icons/header/profile.png';
import infoHubImg from 'assets/icons/header/info.png';
import nicCheckImg from 'assets/icons/header/nic_check.png';
import landingImg from 'assets/icons/header/home.png';
import journalImg from 'assets/icons/header/journal.png';

import styles from './Header.module.css';

const ProfileDropdown = ({ expanded }) => {
  const history = useHistory();
  const signOut = () => {
    firebase.auth().signOut();
  };

  return (
    <div
      className={[
        styles['profile-wrapper'],
        expanded ? styles['expanded'] : styles['collapsed']
      ].join(' ')}
    >
      <div className={styles['profile-dropdown']}>
        <div className={styles['profile-item']}>
          <span
            className={styles['text']}
            onClick={() => history.push('/user-profile')}
          >
            User Profile
          </span>
        </div>
        <div className={styles['profile-item']}>
          <span className={styles['text']} onClick={() => signOut()}>
            Logout
          </span>
        </div>
        <div
          className={styles['profile-item']}
          onClick={() => {
            window.location.href =
              'mailto:NOT@Lung.org?subject=Issue with NOT for Me';
          }}
        >
          <span className={styles['text']}>Help</span>
        </div>
      </div>
    </div>
  );
};

const AuthenticatedHeaderSection = () => {
  const history = useHistory();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const { setShowNicCheck } = useNicCheck();
  const { setShowJournal } = useJournal();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const signOut = () => {
    firebase.auth().signOut();
  };

  return (
    <>
      <div className={styles['header-right']}>
        <ProfileDropdown expanded={showProfileDropdown} />
        <img
          className={styles['header-icon']}
          onClick={() => setShowProfileDropdown(!showProfileDropdown)}
          src={profileImg}
          title="Profile"
          alt="Profile"
        />
        <img
          className={styles['header-icon']}
          onClick={() => history.push('/info-hub')}
          src={infoHubImg}
          title="Info Hub"
          alt="Info Hub"
        />
        <img
          className={styles['header-icon']}
          onClick={() => setShowNicCheck(true)}
          src={nicCheckImg}
          title="Nic Check"
          alt="Nic Check"
        />
        <img
          className={styles['header-icon']}
          onClick={() => setShowJournal(true)}
          src={journalImg}
          title="Journey Journal"
          alt="Journey Journal"
        />
        <img
          className={styles['header-icon']}
          onClick={() => history.push('/landing')}
          src={landingImg}
          title="Home"
          alt="Home"
        />
      </div>

      <header
        onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
        className={styles['header-right-hamburger']}
      >
        <div className={styles['hamburger-wrapper']}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>

      {isMobileNavOpen ? (
        <nav className={styles['mobile-nav']}>
          <div onClick={() => history.push('/landing')}>Home</div>
          <div onClick={() => history.push('/info-hub')}>Info Hub</div>
          <div
            onClick={() => {
              setShowNicCheck(true);
              setIsMobileNavOpen(false);
            }}
          >
            Nic Check
          </div>
          <div
            onClick={() => {
              setShowJournal(true);
              setIsMobileNavOpen(false);
            }}
          >
            Journey Journal
          </div>
          <div onClick={() => history.push('/user-profile')}>User Profile</div>
          <div
            onClick={() => {
              window.location.href =
                'mailto:NOT@Lung.org?subject=Issue with NOT for Me';
            }}
          >
            Help
          </div>
          <div onClick={() => signOut()}>Logout</div>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
};

const Header = () => {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  return (
    <div className={styles['header']}>
      <div className={styles['header-left']}>
        <img
          className={styles['header-logo']}
          onClick={() => history.push('/')}
          src={logoImg}
          alt="American Lung Association logo"
        />
      </div>

      {isAuthenticated && <AuthenticatedHeaderSection />}
    </div>
  );
};

export default Header;
