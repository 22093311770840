import { Text, View } from '@react-pdf/renderer';

import Session from '../../components/Session';
import Section from '../../components/Section';
import SessionSummary from '../../components/SessionSummary';
import MultiselectDisplay from '../../components/MultiselectDisplay';
import LikertPairDisplay from '../../components/LikertPairDisplay';
import MultilineNumberedList from '../../components/MultilineNumberedList';
import RelaxationHub from '../../components/RelaxationHub';
import SayItLoud from '../../components/SayItLoud';
import Replacement from '../../components/Replacement';

import SESSION_THREE_QUESTIONS from 'pages/SessionThree/data';

const SessionThree = ({ data }) => {
  const {
    impactConcerns,
    replacement1,
    replacement2,
    replacement3,
    replacement4,
    simpleItems,
    motivation,
    confidence,
    supportPerson,
    supportTellThem,
    remove,
    physical
  } = data;
  return (
    <Session>
      <SessionSummary
        header="Session 3: Getting Ready"
        bullets={[
          'Understanding how tobacco affects your body, how you think and feel, and your relationships with others',
          'Identifying people who are supportive of your quit journey',
          'Preparing your space to be tobacco-free'
        ]}
      />
      <Section header="Explore More">
        <Text style={{ fontWeight: 'bold' }}>The Body Destroyer</Text>
        {impactConcerns?.isCompleted && (
          <>
            <Text>My biggest concerns about my tobacco use right now are:</Text>
            <MultiselectDisplay
              question={SESSION_THREE_QUESTIONS['impactConcerns']}
              data={impactConcerns}
            />
          </>
        )}
      </Section>
      <Section>
        <Text style={{ fontWeight: 'bold' }}>
          Do Something Else (Instead of Using Tobacco)
        </Text>
        <Text>When I'm tempted to use tobacco...</Text>
        {replacement1 && <Replacement answer={replacement1} />}
        {replacement2 && <Replacement answer={replacement2} />}
        {replacement3 && <Replacement answer={replacement3} />}
        {replacement4 && <Replacement answer={replacement4} />}
      </Section>
      <Section>
        {simpleItems?.isCompleted && (
          <>
            <Text style={{ fontWeight: 'bold' }}>
              Making My Space Tobacco-free
            </Text>
            <Text>This is what I plan to do to prepare my space:</Text>
            <MultiselectDisplay
              question={SESSION_THREE_QUESTIONS['simpleItems']}
              data={simpleItems}
            />
          </>
        )}
      </Section>
      <Section header="Committed to Quit">
        <LikertPairDisplay motivation={motivation} confidence={confidence} />
      </Section>
      <Section header="My Goals">
        {supportPerson?.isCompleted &&
          supportTellThem?.isCompleted &&
          remove?.isCompleted &&
          physical?.isCompleted && (
            <MultilineNumberedList
              items={[
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text style={{ fontWeight: 'bold' }}>
                    This week, I am going to tell this person how he/she/they
                    can support me in quitting:
                  </Text>
                  <Text style={{ marginBottom: '5px' }}>
                    {supportPerson?.value}
                  </Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    I'm going to tell them:
                  </Text>
                  <Text>{supportTellThem?.value}</Text>
                </View>,
                {
                  question: `This is what I'm going to get rid of in my space that might tempt me to use and to help make quitting easier:`,
                  answer: remove?.value
                },
                {
                  question:
                    'The exercise that I will do for at least 10 minutes each day this week (next 7 days) is:',
                  answer: physical?.value
                }
              ]}
            />
          )}
      </Section>
      <Section header="My Reminders">
        <Text style={{ fontWeight: 'bold' }}>
          Remember to track my tobacco use with Nic Check.
        </Text>
        <RelaxationHub
          bullets={[
            'The Body Destroyer',
            'Making My Space Tobacco-free',
            'So Now What?',
            `Rocks That Don't Roll`,
            'For My Support Person',
            'Nic-Check - Understanding My Use'
          ]}
        />
      </Section>
      <SayItLoud phrase="I make time to take care of myself." />
    </Session>
  );
};

export default SessionThree;
